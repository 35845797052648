import React from 'react';
import styles from './table.module.css';
import { useTable } from 'react-table'

const Table = ({
    columns,
    data = [],
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <div className={styles.container}>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr 
                                {...headerGroup.getHeaderGroupProps()}
                                className={styles.tr}
                            >
                                {
                                    headerGroup.headers.map(column => (
                                        <th 
                                            {...column.getHeaderProps()}
                                            nowrap="nowrap"
                                            className={styles.th} 
                                        >
                                            <div className={styles.header}>
                                                {column.render('Header')}
                                            </div>
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={styles.tr}
                                >
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td className={styles.td} {...cell.getCellProps()}>
                                                    <div className={styles['td-info']}>
                                                        {cell.column.Header}
                                                    </div>
                                                    <div className={styles['td-cell']}>
                                                        {cell.render('Cell')}
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
};

export default Table;