import React from 'react';
import { get, isEmpty } from 'lodash';
import { assemblyParams, assemblyListParams, coverageSelectFilters } from '../../utils/params';
import { injectIntl, FormattedMessage } from 'react-intl';
import { LOCALES } from '../../locales'
import Layout from '../../component/Layout/Layout';
import Select from '../../component/Select/Select';
import CheckboxGroup from '../../component/CheckboxGroup/CheckboxGroup';
import MorningstarRatingGroup from '../../component/MorningstarRatingGroup/MorningstarRatingGroup';
import Tabs from './Tabs';
import styles from './landing.module.css';
import closeIcon from '../../assets/close.png';
import fundHouseIcon from '../../assets/select/fund_house.png';
import currencyIcon from '../../assets/select/currency.png';
import riskRatingIcon from '../../assets/select/risk_rating.png';
import distributionClassIcon from '../../assets/select/distribution_class.png';
import fundAssetClassIcon from '../../assets/select/fund_asset_class.png';
import esgFundIcon from '../../assets/select/esg_fund.png';
import fundCategoryIcon from '../../assets/select/fund_category.png';
import customAttributes5Icon from '../../assets/checkbox/customAttributes5.png'
import customAttributes6Icon from '../../assets/checkbox/customAttributes6.png'
import styleBoxIcon from '../../assets/style_box.png';
import morningstarRatingIcon from '../../assets/morningstar_rating.png';
import morningstarSustainabilityRatingIcon from '../../assets/morningstar_sustainability_rating.png';
import styleBoxUncheckedIcon from '../../assets/stylebox_unchecked.gif';
import styleBoxFiIcon from '../../assets/stylebox_fi.gif';
import styleBoxEquityIcon from '../../assets/stylebox_equity.gif';

const { useEffect, useState, useMemo } = React;

const Landing = ({setLocale, intl, isStart, setIsStart}) => {

    const [loading, setLoading] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectValues, setSelectValues] = useState({
        CustomCategoryId3: null,
        _CurrencyId: null,
        CustomCategoryId: null,
        Distribution: null,
        GlobalAssetClassId: null,
        HKSFCESGFund: null,
        GlobalCategoryID: null
    });
    const [baseSelectOptions, setBaseSelectOptions] = useState([]);
    const [checkBoxAOptions, setCheckBoxOptions] = useState([
        {
            key: 'CustomAttributes5:IN:Y',
            icon: customAttributes5Icon,
            checked: false,
            messageId: 'landingCheckBoxCustomAttributes5'
        },
        {
            key: 'CustomAttributes6:IN:Y',
            icon: customAttributes6Icon,
            checked: false,
            messageId: 'landingCheckBoxCustomAttributes6'
        }
    ]);
    const [fixedIncomeStyleOptions, setFixedIncomeStyleOptions] = useState([
        {id: 1, key: 'fixedIncomeStyle1', isActive: false},
        {id: 2, key: 'fixedIncomeStyle2', isActive: false},
        {id: 3, key: 'fixedIncomeStyle3', isActive: false},
        {id: 4, key: 'fixedIncomeStyle4', isActive: false},
        {id: 5, key: 'fixedIncomeStyle5', isActive: false},
        {id: 6, key: 'fixedIncomeStyle6', isActive: false},
        {id: 7, key: 'fixedIncomeStyle7', isActive: false},
        {id: 8, key: 'fixedIncomeStyle8', isActive: false},
        {id: 9, key: 'fixedIncomeStyle9', isActive: false},
    ]);
    const [equityStyleOptions, setEquityOptionsStyle] = useState([
        {id: 1, key: 'equityStyleOptions1', isActive: false},
        {id: 2, key: 'equityStyleOptions2', isActive: false},
        {id: 3, key: 'equityStyleOptions3', isActive: false},
        {id: 4, key: 'equityStyleOptions4', isActive: false},
        {id: 5, key: 'equityStyleOptions5', isActive: false},
        {id: 6, key: 'equityStyleOptions6', isActive: false},
        {id: 7, key: 'equityStyleOptions7', isActive: false},
        {id: 8, key: 'equityStyleOptions8', isActive: false},
        {id: 9, key: 'equityStyleOptions9', isActive: false},
    ]);
    const [morningstarRatingOptions, setMorningstarRatingOptions] = useState([
        {
            id: 'StarRatingM255',
            key: 'StarRatingM255',
            label: 'Morningstar Rating™',
            messageId: 'landingStarRatingM255',
            icon: morningstarRatingIcon,
            isStar: true,
            options: [
                {id: 5, key: 'StarRatingM255: 5', label: 'QQQQQ', checked: false},
                {id: 4, key: 'StarRatingM255: 4', label: 'QQQQ', checked: false},
                {id: 3, key: 'StarRatingM255: 3', label: 'QQQ', checked: false},
                {id: 2, key: 'StarRatingM255: 2', label: 'QQ', checked: false},
                {id: 1, key: 'StarRatingM255: 1', label: 'Q', checked: false},
            ]
        },
        {
            id: 'SustainabilityRank',
            key: 'SustainabilityRank',
            label: 'Morningstar Sustainability Rating',
            messageId: 'landingSustainabilityRank',
            icon: morningstarSustainabilityRatingIcon,
            options: [
                {id: 5, key: 'SustainabilityRank: 5', label: ';;;;;', checked: false},
                {id: 4, key: 'SustainabilityRank: 4', label: ';;;;', checked: false},
                {id: 3, key: 'SustainabilityRank: 3', label: ';;;', checked: false},
                {id: 2, key: 'SustainabilityRank: 2', label: ';;', checked: false},
                {id: 1, key: 'SustainabilityRank: 1', label: ';', checked: false},
            ]
        }
    ]);
    const [listParams, setListParams] = useState({
        page: 1,
        pageSize: 10,
        // sortOrder: 'ReturnM0 desc',
        sortOrder: 'LegalName asc',
        outputType: 'json',
        version: 1,
        languageId: 'en-GB',
        currencyId: 'BAS',
        // universeIds: 'FOALL$$ALL_3686',
        universeIds: "FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198",
        securityDataPoints: 'SecId|Universe|custominstitutionsecurityid|custominstitutionsecurityid2|HoldingTypeId|isin|LegalName|CustomAttributes2|GlobalCategoryName|GlobalAssetClassName|CustomCategoryId|CustomCategoryName|Rating|priceCurrency|CurrencyCode|Currency|closePrice|ClosePriceDate|ReturnM0|ReturnM1|ReturnM3|ReturnM6|ReturnM12|ReturnM36UA|ReturnM60UA|YR_MonthDate|YR_ReturnM12_1|YR_ReturnM12_2|YR_ReturnM12_3|YR_ReturnM12_4|YR_ReturnM12_5|StandardDeviationM12|StandardDeviationM36|StandardDeviationM60|SharpeM12|SharpeM36|SharpeM60|FactsheetDocument|ProspectusDocument|KFSDocument|AnnualReportDocument|SemiAnnualReportDocument|TenforeId|Yield_M12|SustainabilityRank|ClosePrice|CustomRisk|CategoryName|CustomCategoryId2|CustomCategoryId2Name',
        filters: 'All:BTW:0:0'
    });
    const [listData, setListData] = useState([]);

    const selectArrayOptions = useMemo(() => {
        const getOptions = (name) => {
            const options = baseSelectOptions.filter((item) => {
                return item[name]
            }) || []
            return isEmpty(options) ? [] : options[0][name]
        };

        return [
            {
                key: 'Universe',
                label: intl.formatMessage({id: 'landingSelectFundUniverse'}),
                options: [
                    {id: 'FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198', name: intl.formatMessage({id: 'landingSelectAllLabel'})},
                    // {id: 'FOALL$$ALL_1770', name: 'Mutual Fund'},
                    // {id: 'FOALL$$ALL_5890', name: 'Mutual Fund for Wealth Management Connect Customers'},
                    // {id: 'FOALL$$ALL_3198', name: 'Alternative Mutual Fund'},
                    {id: 'FOALL$$ALL_1770', name: intl.formatMessage({id: 'landingSelectAllLabel_1'})},
                    {id: 'FOALL$$ALL_5890', name: intl.formatMessage({id: 'landingSelectAllLabel_2'})},
                    {id: 'FOALL$$ALL_3198', name: intl.formatMessage({id: 'landingSelectAllLabel_3'})},
                ]
            },
            {
                key: 'CustomCategoryId3',
                label: intl.formatMessage({id: 'landingSelectCustomCategoryId3'}),
                icon: fundHouseIcon,
                options: [{id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})}].concat(getOptions('CustomCategoryId3'))
            },
            {
                key: '_CurrencyId',
                label: intl.formatMessage({id: 'landingSelectCurrencyId'}),
                icon: currencyIcon,
                options: [{id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})}].concat(getOptions('_CurrencyId'))
            },
            {
                // key: 'CustomCategoryId',
                key: 'CustomRisk',
                label: intl.formatMessage({id: 'landingSelectCustomCategoryId'}),
                icon: riskRatingIcon,
                // options: [{id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})}].concat(getOptions('CustomCategoryId'))
                options: [{id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})}].concat(getOptions('CustomRisk').map(item => {
                    return {
                        id: item,
                        name: item
                    }
                }))
            },
            {
                key: 'Distribution',
                label: intl.formatMessage({id: 'landingSelectDistribution'}),
                icon: distributionClassIcon,
                options: [
                    {id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})},
                    {id: 'acc', name: intl.formatMessage({id: 'landingSelectAccLabel'})},
                    {id: 'inc', name: intl.formatMessage({id: 'landingSelectIncLabel'})}
                ]
            },
            {
                key: 'GlobalAssetClassId',
                label: intl.formatMessage({id: 'landingSelectGlobalAssetClassId'}),
                icon: fundAssetClassIcon,
                options: [{id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})}].concat(getOptions('GlobalAssetClassId'))
            },
            {
                key: 'HKSFCESGFund',
                label: intl.formatMessage({id: 'landingSelectHKSFCESGFund'}),
                icon: esgFundIcon,
                options: [
                    {id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})},
                    {id: "True", name: intl.formatMessage({id: 'landingSelectYesLabel'})},
                    {id: "NULL", name: intl.formatMessage({id: 'landingSelectNoLabel'})}
                ]
            },
            {
                // key: 'GlobalCategoryID',
                key: 'CustomCategoryId2',
                label: intl.formatMessage({id: 'landingSelectGlobalCategoryID'}),
                icon: fundCategoryIcon,
                // options: [{id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})}].concat(getOptions('GlobalCategoryID'))
                options: [{id: 'all', name: intl.formatMessage({id: 'landingSelectAllLabel'})}].concat(getOptions('CustomCategoryId2'))
            },
        ]
    }, [baseSelectOptions, intl]);

    const onSelectChange = (item, key) => {
        const newValues = Object.assign({}, selectValues, {
            [key]: item.id
        });
        const getFilters = (values) => {
            let str = '';
            Object.keys(values).map(name => {
                return {
                    key: name,
                    value: values[name]
                }
            }).filter(item => {
                return !(isEmpty(item.value)) && item.value !== 'all'
            }).map(({key, value}) => {
                str = `${str}${key}:IN:${value}|`
            })
            return str
        };
        const filters = getFilters(newValues);
        const baseListParams = Object.assign({}, listParams, {
            filters: `${filters}${coverageSelectFilters(listParams.filters, Object.keys(newValues))}`,
        });
        const newListParams = key === 'Universe' ? {
            ...listParams,
            universeIds: item.id
        } : baseListParams

        onRequestListData(newListParams);
        setListParams(newListParams);
        setSelectValues(newValues);
    };

    const onCheckBoxChange = (e, selectIndex) => {
        const newValues = checkBoxAOptions.map((item, index) => {
            return {
                ...item,
                checked: selectIndex === index ? e.target.checked : item.checked
            }
        });

        let filters = '';
        newValues.map(item => {
            if (item.checked){
                filters = `${filters}${item.key}|`
            }
        });

        const newListParams = Object.assign({}, listParams, {
            filters: `${filters}${coverageSelectFilters(listParams.filters, newValues.map(item => item.key))}`,
        });
        onRequestListData(newListParams);
        setListParams(newListParams);
        setCheckBoxOptions(newValues);
    };

    const onMorningstarRatingGroupChange = (e, index, optionsIndex) => {
        let newOptions = [].concat(morningstarRatingOptions);
        const currentChecked = get(newOptions, `[${index}][options][${optionsIndex}[checked]]`);
        newOptions[index]['options'][optionsIndex].checked = !currentChecked;
        
        const { key } = newOptions[index];
        let filters = '';
        newOptions[index].options.filter(item => item.checked).map((item, index, arr) => {
            if (index === 0) {
                filters = `${key}:IN:${item.id}`;
            }else {
                filters = `${filters}:${item.id}`;
            }
            if (index === arr.length - 1) {
                filters = `${filters}|`;
            }
        });
        const newListParams = Object.assign({}, listParams, {
            filters: `${filters}${coverageSelectFilters(listParams.filters, [key])}`,
        });
        onRequestListData(newListParams);
        setListParams(newListParams);
        setMorningstarRatingOptions(newOptions);        
    }

    const onRest = () => {
        const newCheckBoxAOptions = checkBoxAOptions.map(item => {
            return {
                ...item,
                checked: false
            }
        });

        const newFixedIncomeStyleOptions = fixedIncomeStyleOptions.map(item => {
            return {
                ...item,
                isActive: false
            }
        });

        const newEquityStyleOptions = equityStyleOptions.map(item => {
            return {
                ...item,
                isActive: false
            }
        });

        const newMorningstarRatingOptions = morningstarRatingOptions.map(item => {
            return {
                ...item,
                options: item.options.map(item => {
                    return {
                        ...item,
                        checked: false
                    }
                })
            }
        });

        const listParams = {
            page: 1,
            pageSize: 10,
            // sortOrder: 'ReturnM0 desc',
            sortOrder: 'LegalName asc',
            outputType: 'json',
            version: 1,
            languageId: 'en-GB',
            currencyId: 'BAS',
            // universeIds: 'FOALL$$ALL_3686',
            universeIds: "FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198",
            securityDataPoints: 'SecId|Universe|custominstitutionsecurityid|custominstitutionsecurityid2|HoldingTypeId|isin|LegalName|CustomAttributes2|GlobalCategoryName|GlobalAssetClassName|CustomCategoryId|CustomCategoryName|Rating|priceCurrency|CurrencyCode|Currency|closePrice|ClosePriceDate|ReturnM0|ReturnM1|ReturnM3|ReturnM6|ReturnM12|ReturnM36UA|ReturnM60UA|YR_MonthDate|YR_ReturnM12_1|YR_ReturnM12_2|YR_ReturnM12_3|YR_ReturnM12_4|YR_ReturnM12_5|StandardDeviationM12|StandardDeviationM36|StandardDeviationM60|SharpeM12|SharpeM36|SharpeM60|FactsheetDocument|ProspectusDocument|KFSDocument|AnnualReportDocument|SemiAnnualReportDocument|TenforeId|Yield_M12|SustainabilityRank|ClosePrice|CustomRisk|CategoryName|CustomCategoryId2|CustomCategoryId2Name',
            filters: 'All:BTW:0:0'
        };

        setListParams(listParams);
        setCheckBoxOptions(newCheckBoxAOptions);
        setFixedIncomeStyleOptions(newFixedIncomeStyleOptions);
        setEquityOptionsStyle(newEquityStyleOptions);
        setMorningstarRatingOptions(newMorningstarRatingOptions);
        setInputValue('');
        setSelectValues({
            CustomCategoryId3: null, _CurrencyId: null, CustomCategoryId: null, Distribution: null,
            GlobalAssetClassId: null, HKSFCESGFund: null, GlobalCategoryID: null
        });
        onRequestListData(listParams);
    };

    const onUpdateShowMore = () => {
        setIsShowMore(!isShowMore);
    };

    const onClickStyle = (index, fixedIncomeStyle = false) => {
        let newData = [].concat(fixedIncomeStyle ? fixedIncomeStyleOptions : equityStyleOptions);
        newData[index] = {
            ...newData[index],
            isActive: !(newData[index].isActive)
        }

        const key = fixedIncomeStyle ? 'BondStyleBox' : 'EquityStyleBox';
        let filters = '';
        newData.filter(item => {
            return item.isActive
        }).map((item, index, arr) => {
            if (index === 0) {
                filters = `${filters}${key}:IN:${item.id}`
            }else {
                filters = `${filters}:${item.id}`
            }
            if (index === arr.length - 1) {
                filters = `${filters}|`
            }
        });
        const newListParams = Object.assign({}, listParams, {
            filters: `${filters}${coverageSelectFilters(listParams.filters, [key])}`,
        });
        onRequestListData(newListParams);
        setListParams(newListParams);

        fixedIncomeStyle ? setFixedIncomeStyleOptions(newData) : setEquityOptionsStyle(newData);
    };

    const onRequestSelectOptions = async (locale) => {
        setLoading(true);
        try {
            const baseParams = [
                {name: 'languageId', value: locale},
                {name: 'currencyId', value: 'BAS'},
                // {name: 'universeIds', value: 'FOALL$$ALL_3686'},
                // {name: 'universeIds', value: 'FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198'},
                {name: 'universeIds', value: 'FOALL$$ALL_1770'},
                {name: 'outputType', value: 'json'},
                {name: 'filterDataPoints', value: 'GlobalAssetClassId|GlobalCategoryID|CustomCategoryId|CustomCategoryId2|CustomCategoryId3|_CurrencyId|CustomRisk'}
            ];
            const params = encodeURI(assemblyParams(baseParams));
            const response = await fetch(`api2/v1/agent/security/screener${params}`, {// /v1/agent
                method: 'GET',
            });
            const res = await response.json();
            if (res.filters) {
                setBaseSelectOptions(res.filters[0]);
                onRequestListData({
                    ...listParams,
                    languageId: intl.locale
                });
                return;
            }
            setLoading(false);
        }catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onRequestListData = async (baseParams) => {
        setLoading(true)
        try {
            const params = encodeURI(assemblyListParams(baseParams));
            const response = await fetch(`api2/v1/agent/security/screener${params}`, {// /v1/agent
                method: 'GET',
            });
            const res = await response.json();
            if (res.rows) {
                setListData(res);
            }
            setLoading(false);
        }catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    useEffect(() => {
        if (!inputValue && isEmpty(listParams.term)) return;
        
        const newListParams = Object.assign({}, listParams, {
            term: inputValue
        });
        onRequestListData(newListParams);
        setListParams(newListParams);
    }, [inputValue]);

    useEffect(() => {
        setListParams({
            ...listParams,
            languageId: intl.locale
        })
        onRequestSelectOptions(intl.locale);
    }, [intl]);

    return (
        <Layout
            loading={loading}
            activePageKey="information"
            activePageChildrenKey="fundSearch"
            setLocale={setLocale}
            isShowTabs={true}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <span className={styles['header-title']}>
                        <FormattedMessage id='landingInputTitle'/>
                    </span>
                    <div className={styles['input-container']}>
                        <input 
                            className={styles.input}
                            placeholder={intl.formatMessage({id: 'landingInputPlaceholder'})}
                            onChange={(e) => {setInputValue(e.target.value);}}
                            value={inputValue}
                            readOnly={loading}
                        />
                        <img 
                            className={styles['input-container-close']}
                            src={closeIcon} 
                            alt='close icon'
                            onClick={() => setInputValue('')}
                        />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.top}>
                        {
                            selectArrayOptions.map(({key, label, icon, options}) => {
                                const selectKey = selectValues[key] ? selectValues[key] : options[0]['id'];
                                return (
                                    <div 
                                        key={key} 
                                        className={`${styles['top-item']} ${(key === 'CustomCategoryId2' || key === 'Universe') && styles['top-item-category']}`}
                                    >
                                        <div className={styles['top-item-tips']}>
                                            {/* <img 
                                                src={icon} 
                                                alt={label} 
                                                // style={{filter: 'hue-rotate(242deg)'}}
                                            /> */}
                                            <span>{label}</span>
                                        </div>
                                        <div className={styles['top-item-select']}>
                                            <Select 
                                                options={options}
                                                onSelectChange={(item) => onSelectChange(item, key)}
                                                selectKey={selectKey}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className={`${styles['top-item']} ${styles['top-item-category']}`}>
                            <CheckboxGroup
                                options={checkBoxAOptions}
                                onChange={onCheckBoxChange}
                            />
                        </div> */}
                    </div>
                    <div 
                        className={styles.more}
                        style={{position: !isShowMore && 'fixed'}}
                    >
                        <div className={`${styles['more-item']} ${styles['more-item-left']}`}>
                            <MorningstarRatingGroup 
                                options={morningstarRatingOptions}
                                onChange={onMorningstarRatingGroupChange}
                            />
                            <div className={styles['left-single-group']}></div>
                        </div>
                    
                        <div className={styles['more-item']}>
                            <div className={styles['more-item-right']}>
                                <div className={styles['right-item']}>
                                    <span className={styles['right-item-title']}>
                                        <FormattedMessage id='landingFixedIncomeTitle'/>
                                    </span>
                                    <div className={styles['right-item-body']}>
                                        <div className={styles['right-item-body-group']}>
                                            {
                                                fixedIncomeStyleOptions.map((item, index) => {
                                                    const { key, isActive } = item;
                                                    return (
                                                        <div 
                                                            key={key}
                                                            className={styles['group-item']}
                                                            onClick={() => onClickStyle(index, true)}
                                                            style={{backgroundImage: `url(${isActive ? styleBoxFiIcon : styleBoxUncheckedIcon})`,}}
                                                            // style={{background: isActive ? 'rgb(240, 83, 36)' : 'rgb(242, 220, 208)'}}
                                                        />
                                                    )
                                                })
                                            }
                                            <div className={styles['group-right-info']}>
                                                <div className={styles['group-right-info-left']}>
                                                    <span><FormattedMessage id='landingFixedIncomeHigh'/></span>
                                                    <span><FormattedMessage id='landingFixedIncomeMed'/></span>
                                                    <span><FormattedMessage id='landingFixedIncomeLow'/></span>
                                                </div>
                                                <div className={styles['group-right-info-right']}>
                                                    <span><FormattedMessage id='landingFixedIncomeRight'/></span>
                                                </div>
                                            </div>
                                            <div className={styles['group-bottom-info']}>
                                                <span><FormattedMessage id='landingFixedIncomeLow2'/></span>
                                                <span><FormattedMessage id='landingFixedIncomeMed2'/></span>
                                                <span><FormattedMessage id='landingFixedIncomeHigh2'/></span>
                                            </div>
                                        </div>
                                    </div>
                                    <span className={styles['right-item-bold']}>
                                        <FormattedMessage id='landingFixedIncomeBottom'/>
                                    </span>
                                </div>
                                <div className={styles['right-item']}>
                                    <span className={styles['right-item-title']}>
                                        <FormattedMessage id='landingEquityStyleTitle'/>
                                    </span>
                                    <div className={styles['right-item-body']}>
                                        <div className={styles['right-item-body-group']}>
                                            {
                                                equityStyleOptions.map((item, index) => {
                                                    const { key, isActive } = item;
                                                    return (
                                                        <div 
                                                            key={key}
                                                            className={styles['group-item']}
                                                            onClick={() => onClickStyle(index, false)}
                                                            style={{backgroundImage: `url(${isActive ? styleBoxEquityIcon : styleBoxUncheckedIcon})`,}}
                                                            // style={{background: isActive ? 'rgb(38, 50, 130)' : 'rgb(188, 191, 204)'}}
                                                        />
                                                    )
                                                })
                                            }
                                            <div className={styles['group-right-info']}>
                                                <div className={styles['group-right-info-left']}>
                                                    <span><FormattedMessage id='landingEquityStyleLarge'/></span>
                                                    <span><FormattedMessage id='landingEquityStyleMid'/></span>
                                                    <span><FormattedMessage id='landingEquityStyleSmall'/></span>
                                                </div>
                                                <div className={styles['group-right-info-right']}>
                                                    <span><FormattedMessage id='landingEquityStyleRight'/></span>
                                                </div>
                                            </div>
                                            <div className={styles['group-bottom-info']}>
                                                <span><FormattedMessage id='landingEquityStyleValue'/></span>
                                                <span><FormattedMessage id='landingEquityStyleBlend'/></span>
                                                <span><FormattedMessage id='landingEquityStyleGrowth'/></span>
                                            </div>
                                        </div>
                                    </div>
                                    <span className={styles['right-item-bold']}><FormattedMessage id='landingEquityStyleBottom'/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.action}>
                        <button 
                            className={`${styles['action-button']} ${intl.locale === LOCALES.HONGKONG && styles['action-button-ch']}`}
                            onClick={onRest} 
                        >
                            <FormattedMessage id='landingButtonReset'/>
                        </button>
                        <button 
                            className={`${styles['action-button']} ${intl.locale === LOCALES.HONGKONG && styles['action-button-ch']}`}
                            onClick={onUpdateShowMore}
                        >
                            <FormattedMessage id={isShowMore ? 'landingButtonShowLess' : 'landingButtonShowMore'}/>
                        </button>
                    </div>
                </div>
            </div>
            <Tabs 
                params={listParams}
                setParams={setListParams}
                data={listData}
                onRequestListData={onRequestListData}
                isStart={isStart}
                setIsStart={setIsStart}
            />
        </Layout>
    )
};

export default injectIntl(Landing);