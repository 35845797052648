import React from 'react';
import { isEmpty, isNumber, round } from 'lodash';
import { returnFloat } from '../../../utils/details';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import styles from './chart.module.css';

const { useMemo, useState, useRef, useEffect } = React;

const Chart = ({
    baseData
}) => {
    
    const elementRef = useRef(null);
    const chartRef = useRef(null);

    const [activeTabKey, setActiveTabKey] = useState({key: '1y', baseMonth: 12});
    const [isInitChart, setIsInitChart] = useState(false);

    const getSeries = (baseData = [], baseMonth) => {
        const currentDate = dayjs();
        const endDate = currentDate.subtract(baseMonth, 'month');
        const currentData = baseData.map(item => {
            return {
                ...item,
                data: item.data.filter(values => {
                    const time = dayjs(values[0])
                    return time.isAfter(endDate);
                })
            }
        });

        const data = baseMonth === 'max' ? baseData : currentData;
        return data.map(({data, color, name}, index) => {
            return {
                name: name,
                data: data,
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                itemStyle: {color},
                triggerLineEvent: true,
                areaStyle: index === 0 ? {
                    origin: 'start',
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#476691'
                        },
                        {
                            offset: 1,
                            color: 'rgba(71, 102, 145, 0.2)'
                        }
                    ])
                } : null
            }
        })
    };

    const tabsOptions = useMemo(() => {
        return [
            {
                label: '3M',
                key: '3m',
                baseMonth: 3
            },
            {
                label: '6M',
                key: '6m',
                baseMonth: 6
            },
            {
                label: '1Y',
                key: '1y',
                baseMonth: 12
            },
            {
                label: '3Y',
                key: '3y',
                baseMonth: 36
            },
            {
                label: '5Y',
                key: '5y',
                baseMonth: 60
            },
            {
                label: '10Y',
                key: '10y',
                baseMonth: 120
            },
            {
                label: 'Max',
                key: 'max',
                baseMonth: 'max'
            },
        ]
    }, [baseData]); 

    const chartOptions = useMemo(() => {
        return {
            grid: {
                left: 0,
                right: 0,
                containLabel: true
            },
            xAxis: {
                type: 'time',
                boundaryGap: ['0.5%', '0.5%'],
                triggerEvent: true
            },
            yAxis: {
                type: 'value',
                scale: true,
                axisLabel: {
                    formatter: '{value}%'
                }
            },
            tooltip: {
                trigger: 'axis',
                confine: true,
                valueFormatter: (value) => {
                    return isNumber(value) ? `${returnFloat(round(value, 2))}%` : ''
                }
            },
            dataZoom: [
                {
                    type: 'slider',
                    start: 0,
                    end: 100,
                    left: '15%',
                    right: '15%'
                }
            ],
            series: getSeries(baseData, activeTabKey.baseMonth)
        }
    }, [baseData, activeTabKey]);

    useEffect(() => {
        const element = elementRef.current;

        if (element && !(isEmpty(chartOptions))) {

            if (isInitChart) {
                chartRef.current.setOption(chartOptions, true);
            }else {
                const instance = echarts.init(element);
                
                chartRef.current = instance;
                instance.setOption(chartOptions);
                setIsInitChart(true);    
            }
        }
    }, [chartOptions]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (!(isEmpty(chartRef.current))) {
                chartRef.current.resize();
            }
        })
    }, []);

    return (
        <div className={styles.container}>
            {
                baseData.length > 0 && (
                    <div className={styles.action}>
                        {
                            tabsOptions.map(({label, key, baseMonth}, index) => (
                                <button
                                    className={`
                                        ${activeTabKey.key === key ? styles['action-button-active'] : styles['action-button']} 
                                        ${index === tabsOptions.length - 1 ? styles['action-button-last'] : ''}
                                    `}
                                    key={key}
                                    onClick={() => setActiveTabKey({key, baseMonth})}
                                >
                                    {label}
                                </button>
                            ))
                        }
                    </div>
                )
            }
            <div className={styles.chart} ref={elementRef}/>
        </div>
    )
};

export default Chart;
