import React from 'react';
import { get, round, isNumber, max } from 'lodash';
import { returnFloat } from '../../utils/details';
import { getRateRiskValue, getCreditQualityValue } from '../../utils/xray';
import { StepTabs } from './StockRegions/StockRegions';
import styles from './info.module.css';

const { useMemo, useState } = React;

const Info = ({
    data,
    isShowHoldings
}) => {

    const getBoxValue = (key) => {
        const box = get(data, `breakdowns.${key}`) || [];
        
        const filter = box.filter(({salePosition}) => {
            return salePosition === 'N';
        }) || [];

        const values = get(filter, '0.values') || {};
        return Array.from(Array(9), (e, index) => {
            const key = `${index + 1}`;
            const value = isNumber(values[key]) ?  values[key] : 0;
            return value
        });
    };

    const styleBoxValue = useMemo(() => {
        return getBoxValue('styleBox');
    }, [data, getBoxValue]);

    const bondStyleBoxValue = useMemo(() => {
        return getBoxValue('bondStyleBox');
    }, [data]);

    const styleBoxOptions = useMemo(() => {
        return {
            title: 'Stock Style',
            subtitle: 'Large Blend stock',
            value: returnFloat(round(max(styleBoxValue) || 0, 2)),
            weightRanges: [
                {color: 'rgba(41, 63, 111, 1)', label: '50+', key: 'stockStyle-weightRange-1'},
                {color: 'rgba(41, 63, 111, 0.8)', label: '25 - 49', key: 'stockStyle-weightRange-2'},
                {color: 'rgba(41, 63, 111, 0.4)', label: '10 - 24', key: 'stockStyle-weightRange-3'},
                {color: 'rgba(41, 63, 111, 0.2)', label: '0 - 9', key: 'stockStyle-weightRange-4'},
            ],
            tableTitle: 'Style',
            tableTopLabels: ['Value', 'Blend', 'Growth'],
            tableLeftTitle: 'Size',
            tableLeftLabels: ['Large', 'Mid', 'Small'],
            itemValue: styleBoxValue,
            color: '#293F6F'
        }
    }, [styleBoxValue]);

    const bondStyleBoxOptions = useMemo(() => {
        return {
            title: 'Fixed Income Style',
            // subtitle: '',
            value: returnFloat(round(max(bondStyleBoxValue) || 0, 2)),
            weightRanges: [
                {color: 'rgba(233, 102, 16, 1)', label: '50+', key: 'bondStyle-weightRange-1'},
                {color: 'rgba(233, 102, 16, 0.8)', label: '25 - 49', key: 'bondStyle-weightRange-2'},
                {color: 'rgba(233, 102, 16, 0.4)', label: '10 - 24', key: 'bondStyle-weightRange-3'},
                {color: 'rgba(233, 102, 16, 0.2)', label: '0 - 9', key: 'bondStyle-weightRange-4'},
            ],
            tableTitle: 'Interest Rate Sensitivity',
            tableTopLabels: ['Low', 'Med', 'High'],
            tableLeftTitle: 'Credit Quality',
            tableLeftLabels: ['High', 'Mid', 'Low'],
            itemValue: bondStyleBoxValue,
            color: '#E96610'
        }
    }, [bondStyleBoxValue]);

    const bondRateRiskOptions = useMemo(() => {
        return [
            {
                isHeader: true,
                values: ['', 'Portfolio', 'Benchmark'],
                key: 'bondRateRiskHeader'
            },
            {
                values: [
                    'Avg Eff Maturity',
                    getRateRiskValue(data, 'effectiveMaturity'),
                    '-'
                ],
                key: 'effectiveMaturity'
            },
            {
                values: [
                    'Avg Eff Duration',
                    getRateRiskValue(data, 'effectiveDuration'),
                    '-'
                ],
                key: 'effectiveDuration'
            },
            {
                values: [
                    'Avg Credit Quality',
                    getRateRiskValue(data, 'averageCreditQuality', true) || '-',
                    '-'
                ],
                key: 'averageCreditQuality'
            },
        ];
    }, [data]);

    const bondCreditQualityOptions = useMemo(() => {
        return [
            [
                {
                    isHeader: true,
                    label: '',
                    value: '% of Bond',
                    key: 'CreditQualityLeftHeader'
                },
                {
                    label: 'AAA',
                    value: getCreditQualityValue(data, 2),
                    key: 'CreditQuality-AAA'
                },
                {
                    label: 'AA',
                    value: getCreditQualityValue(data, 3),
                    key: 'CreditQuality-AA'
                },
                {
                    label: 'A',
                    value: getCreditQualityValue(data, 4),
                    key: 'CreditQuality-A'
                },
                {
                    label: 'BBB',
                    value: getCreditQualityValue(data, 5),
                    key: 'CreditQuality-BBB'
                },
            ],
            [
                {
                    isHeader: true,
                    label: '',
                    value: '% of Bond',
                    key: 'CreditQualityRightHeader'
                },
                {
                    label: 'BB',
                    value: getCreditQualityValue(data, 6),
                    key: 'CreditQuality-BB'
                },
                {
                    label: 'B',
                    value: getCreditQualityValue(data, 7),
                    key: 'CreditQuality-B'
                },
                {
                    label: 'Below B',
                    value: getCreditQualityValue(data, 8),
                    key: 'CreditQuality-BelowB'
                },
                {
                    label: 'NR',
                    value: getCreditQualityValue(data, 9),
                    key: 'CreditQuality-NR'
                },
            ]
        ]
    }, [data]);

    const bondStyleTabs = useMemo(() => {
        return [
            {
                index: 0,
                key: 'bondStyleBox-0',
                Element: () => {
                    return (<BoxContainer {...bondStyleBoxOptions}/>)
                }
            },
            {
                index: 1,
                key: 'bondStyleBox-1',
                Element: () => {
                    return (<InterestRateRisk options={bondRateRiskOptions}/>)
                }
            },
            {
                index: 2,
                key: 'bondStyleBox-2',
                Element: () => {
                    return (<CreditQuality options={bondCreditQualityOptions}/>)
                }
            }
        ]
    }, [bondStyleBoxOptions, bondRateRiskOptions, bondCreditQualityOptions]);

    return (
        <div className={`${styles.container} ${isShowHoldings && styles['container-full']}`}>
            <div className={`${styles['item']} ${isShowHoldings && styles['item-full']}`}>
                <BoxContainer {...styleBoxOptions} isFirst={!isShowHoldings}/>
            </div>
            <div className={`${styles['item']} ${isShowHoldings && styles['item-full']}`}>
                <div className={styles['item-right']}>
                    <StepTabs 
                        tabs={bondStyleTabs} 
                        topStart={-34}
                        isLeftAction={false}
                    />
                </div>
            </div>
        </div>
    )
};

const CreditQuality = ({
    options
}) => {

    return (
        <div className={styles['credit-quality']}>
            <span className={styles['box-title']}>Fixed Income Style</span>
            <div className={styles['box-info']}>
                <span className={styles['box-subtitle']} style={{lineHeight: '26px'}}>
                    Credit Quality Breakdown
                </span>
            </div>
            <div className={styles['credit-quality-container']}>
                {
                    options.map((item, index) => (
                        <div className={styles['credit-quality-box']} key={`credit-quality-box-${index}`}>
                            {
                                item.map(({key, label, value, isHeader}) => (
                                    <div 
                                        className={`${styles['credit-quality-box-item']} ${isHeader && styles['credit-quality-box-header'] }`}
                                        key={key}
                                    >
                                        <span>{label}</span>
                                        <span>{value}</span>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const InterestRateRisk =({
    options
}) => {

    return (
        <div className={styles['interest-risk']}>
            <span className={styles['box-title']}>Fixed Income Style</span>
            <div className={styles['box-info']}>
                <span className={styles['box-subtitle']} style={{lineHeight: '26px'}}>
                    Interest Rate Risk
                </span>
            </div>
            <div className={styles['box-table']}>
                {
                    options.map(({key, values, isHeader}, optionsIndex) => (
                        <div 
                            className={`${styles['interest-risk-item']} ${isHeader && styles['interest-risk-item-header']}`}
                            key={key}
                        >
                            {
                                values.map((value, index) => (
                                    <span
                                        key={`${key}-${index}-${optionsIndex}`}
                                        className={index === values.length - 1 ? styles['interest-risk-item-last'] : ''}
                                    >
                                        {value}
                                    </span>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

const BoxContainer = ({
    title,
    subtitle,
    value,
    color,
    tableTitle,
    tableTopLabels,
    itemValue,
    weightRanges,
    tableLeftLabels,
    tableLeftTitle,
    isFirst
}) => {
    const getItemStyle = (value) => {
        let color = '#333333';
        let background = '#ffffff';
        
        if (value >= 50) {
            background = get(weightRanges, '0.color') || background;
            color = '#ffffff';
        }else if (value >= 25) {
            background = get(weightRanges, '1.color') || background;
            color = '#ffffff';
        }else if (value >= 10) {
            background = get(weightRanges, '2.color') || background;
        }else {
            background = get(weightRanges, '3.color') || background;
        }

        return {
            color,
            background
        }
    };

    return (
        <div className={styles['box-container']}>
            <span className={styles['box-title']}>{title}</span>
            <div className={styles['box-info']}>
                {
                    subtitle && (
                        <span className={styles['box-subtitle']}>
                            {subtitle}
                        </span>
                    )
                }
                <span className={styles['box-value']} style={{color}}>
                    {value}%
                </span>
            </div>
            <div className={styles['box-table']}>
                <span className={styles['box-table-title']}>
                    {tableTitle}
                </span>
                <div className={`${styles['box-table-info']} ${isFirst && styles['box-table-info-first']}`}>
                    <div className={styles['table-chart-container']}>
                        {
                            tableTopLabels.map((label, index) => (
                                <span 
                                    className={styles['table-label']}
                                    key={`${title}-${label}-top-${index}`}
                                    style={{color}}
                                >
                                    {label}
                                </span>

                            ))
                        }
                        {
                            itemValue.map((value, index) => {
                                const newValue = round(value);
                                return (
                                    (
                                        <span 
                                            className={styles['table-item']}
                                            key={`${title}-item-${index}`}
                                            style={getItemStyle(newValue)}
                                        >
                                            <span>{newValue}</span>
                                        </span>
                                    )
                                )
                            })
                        }
                        <div className={styles['table-right-left']}>
                            {
                                tableLeftLabels.map((label, index) => (
                                    <span key={`table-right-left-${index}`} style={{color}}>{label}</span>
                                ))
                            }
                        </div>
                        <span className={styles['table-right-title']}>{tableLeftTitle}</span>

                    </div>
                    <div className={styles['table-weight-container']}>
                        <span className={styles['table-weight-title']}>
                            Weight,%
                        </span>
                        {
                            weightRanges.map(({color, key, label}) => (
                                <div key={key} className={styles['table-weight-item']}>
                                    <div 
                                        className={styles['table-weight-box']} 
                                        style={{background: color}}
                                    />
                                    <span>{label}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

const StepsTabs = ({
    tabs = []
}) => {

    const [step, setStep] = useState(0);

    const actionOptions = useMemo(() => {
        return tabs.map(({key}) => {
            return `${key}-action`
        })
    }, [tabs]);

    return (
        <div className={styles['steps-container']}>
            {
                tabs.map(({props, Element, index, key})=> {
                    return (
                        <div 
                            className={styles['steps-item']} 
                            style={{display: step !== index && 'none'}}
                            key={key}
                        >
                            <Element/>
                        </div>
                    )
                })
            }
            <div className={styles['steps-action']}>
                {
                    actionOptions.map((item, index) => (
                        <div 
                            className={styles['steps-action-circle']} 
                            key={item} 
                            style={{background: step === index && '#000000'}}
                            onClick={() => setStep(index)}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default Info;