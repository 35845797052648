import React from 'react';
import * as echarts from 'echarts';
import { isEmpty, round, get } from 'lodash';
import { getGraphData, getCurrentDate, getDate } from './utils';
import { returnFloat } from '../../../utils/details';
import styles from './performance-graph.module.css'

const { useMemo, useState, useRef, useEffect } = React;

const PerformanceGraph = ({
    baseData,
    baseBenchmarkData,
    isShowHoldings
}) => {

    const elementRef = useRef(null);
    const chartRef = useRef(null);

    const [activeTabKey, setActiveTabKey] = useState('graph_ty');
    const [isInitChart, setIsInitChart] = useState(false);

    const tabsOptions = useMemo(() => {
        return [
            { 
                label: 'TY', 
                key: 'graph_ty', 
                value: getGraphData(baseData, getDate(getCurrentDate(), 12), getCurrentDate()), 
                benchmarkValue: isEmpty(baseBenchmarkData) ? null : getGraphData(baseBenchmarkData, getDate(getCurrentDate(), 12), getCurrentDate())
            },
            { 
                label: '2Y', 
                key: 'graph_2Y', 
                value: getGraphData(baseData, getDate(getCurrentDate(), 24), getCurrentDate()), 
                benchmarkValue: isEmpty(baseBenchmarkData) ? null : getGraphData(baseBenchmarkData, getDate(getCurrentDate(), 24), getCurrentDate())
            },
            { 
                label: '3Y', 
                key: 'graph_3Y', 
                value: getGraphData(baseData, getDate(getCurrentDate(), 36), getCurrentDate()), 
                benchmarkValue: isEmpty(baseBenchmarkData) ? null : getGraphData(baseBenchmarkData, getDate(getCurrentDate(), 36), getCurrentDate())
            },
            { 
                label: '5Y', 
                key: 'graph_5Y', 
                value: getGraphData(baseData, getDate(getCurrentDate(), 60), getCurrentDate()), 
                benchmarkValue: isEmpty(baseBenchmarkData) ? null : getGraphData(baseBenchmarkData, getDate(getCurrentDate(), 60), getCurrentDate())
            },
            { 
                label: '10Y',
                key: 'graph_10Y', 
                value: getGraphData(baseData, getDate(getCurrentDate(), 120),getCurrentDate()), 
                benchmarkValue: isEmpty(baseBenchmarkData) ? null : getGraphData(baseBenchmarkData, getDate(getCurrentDate(), 120), getCurrentDate())
            },
        ]
    }, [baseData]);
    
    const activeData = useMemo(() => {
       return tabsOptions.filter(item => item.key === activeTabKey)[0];
    }, [tabsOptions, activeTabKey]);

    const chartOptions = useMemo(() => {
        if (isEmpty(activeData.value)) return {};

        const series = [
            {
                name: 'Portfolio',
                data: activeData.value.series,
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                itemStyle: {color: '#056dae'},
            }
        ];
        console.log(series);

        if (activeData.benchmarkValue !== null) {
            series.push({
                name: 'Benchmark',
                data: activeData.benchmarkValue.series,
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                itemStyle: {color: '#deae1a'},
            })
        }

        return {
            legend: {
                data: ['Portfolio', 'Benchmark']
            },
            tooltip: {
                trigger: 'axis',
                confine: true,
                formatter: (value) => {
                    const item = value.map((item) => {
                        return `
                            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px; color: #333333">
                                <div style="display:flex; justify-content: space-between; align-items: center;">
                                    <span style="border-radius:10px;width:10px;height:10px; background: ${item.color};margin-right: 4px;"></span>
                                    <span style="font-size: 14px; line-height: 16px;">
                                        ${item.seriesName}
                                    </span>
                                </div>
                                <span style="font-weight: 700; font-size: 14px; line-height: 16px;margin-left: 16px;">
                                    ${returnFloat(round(item.value[1], 2))}
                                <span>
                            </div>
                        ` 
                    }).reduce((next, current, index) => {
                        if (index === 0) {
                            return next;
                        }
                        return `${next}${current}`
                    });
                    // <span style="font-weight: 700; margin-left: 16px; font-size: 14px; line-height: 10px;">Total Growth<span>
                    return `
                        <div
                            style="display: flex;flex-direction: column;color: #333333"
                        >
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="font-size: 14px; line-height: 16px;">${value[0].data[0]}</span>
                            </div>
                            ${item}
                        </div>
                    `
                }
            },
            xAxis: {
                type: 'time',
                boundaryGap: ['0.5%', '0.5%'],
                splitNumber: 12,
            },
            yAxis: {
                type: 'value',
                scale: true,
            },
            series
        }
    }, [activeData]);

    const convertsChartOptions = (ref, options) => {
        const width = get(ref, 'current.offsetWidth');

        if (width < 700) {
            return {
                ...options,
                xAxis: {
                    ...options.xAxis,
                    splitNumber: 5
                }
            }
        }

        return options;
    };

    useEffect(() => {
        const element = elementRef.current;

        if (element && !(isEmpty(chartOptions))) {
            const options = convertsChartOptions(elementRef, chartOptions)

            if (isInitChart) {
                chartRef.current.setOption(options, true);
            }else {
                const instance = echarts.init(element);
                
                chartRef.current = instance;
                instance.setOption(options);
                setIsInitChart(true);
            }
        }
    }, [chartOptions]);

    useEffect(() => {
        if (!(isEmpty(chartRef.current))) {
            setTimeout(() => {
                chartRef.current.resize();
            }, 200)
        }
    }, [isShowHoldings]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (!(isEmpty(chartRef.current))) {
                chartRef.current.resize();
            }
        })
    }, []);

    return (
        <div className={`${styles.container} ${isShowHoldings && styles['container-full']}`}>
            <div className={styles.header}>
                <span className={styles['header-title']}>Performance Graph</span>
                <div className={styles['header-action']}>
                    {
                        tabsOptions.map(({label, key, value}) => (
                            <button
                                className={activeTabKey === key ? styles['header-action-button-active'] : styles['header-action-button']}
                                key={key}
                                // style={{background: activeTabKey === key && '#056dae'}}
                                onClick={() => setActiveTabKey(key)}
                            >
                                {label}
                            </button>
                        ))
                    }
                </div>
            </div>
            <div className={styles.chart} ref={elementRef}/>
            <div className={styles.tips}>
                {
                    activeData.value.target && (
                        <div className={styles['tips-item']}>
                            <span>Portfolio</span>
                            <span className={styles['tips-item-value']}>{activeData.value.target}</span>
                        </div>
                    )
                }
                {
                    activeData.benchmarkValue && (
                        <div className={styles['tips-item']}>
                            <span>Benchmark</span>
                            <span className={styles['tips-item-value']} style={{color: '#deae1a'}}>{activeData.benchmarkValue.target}</span>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default PerformanceGraph;