import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import styles from './index.module.css';
import "react-tooltip/dist/react-tooltip.css";

const Tooltip = ({title, color, value}) => {
    return (
        <div className={styles['tooltip']}>
            <span className={styles['tooltip-title']} style={{color}}>
                {title}
            </span>
            <div className={styles['tooltip-info']}>
                <div className={styles['tooltip-info-item']}>
                    <span>Weight: </span>
                    <span>{value}</span>
                </div>
            </div>
        </div>
    )
}

export const AmericaIcon = ({
    northAmericaColor = '',
    latinAmericaColor = '',
    northAmericaValue,
    latinAmericaValue,
}) => {

    const getData = (index) => {
        return {
            title: index === 0 ? 'Americas' : 'Latin America',
            color: index === 0 ? northAmericaColor : latinAmericaColor,
            value: index === 0 ? northAmericaValue : latinAmericaValue
        }
    };

    return (
        <>
        <svg role="chart" className="mbc-canvas" width="980px" height="480px" viewBox="0 0 980 480"> 
            <g className="mbc-layers" transform="translate(0,0)" id="sdwan" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> 
                <g className="mbc-axes"/>
                <g className="mbc-back-group"/>
                <g className="mbc-chart-group no-drag" transform="translate(2.106952737337032, 4) scale(0.544607655592901)">
                    <g className="map-back-layer">
                        <rect x="0" y="0" width="229.52303133512797" height="293.7894801089638" className="map-overlay" transform="translate(-3.8687534332275297, -7.344737002724095)"/>
                    </g>
                    <g className="map-layer">
                        <g className="region region-america" opacity="1">
                            <path data-tooltip-id="region-america-icon-0" className="north-america map-shape" d="M121.8 46.4L134 34.2 107.2 7.4l7.4-7.4H62.9L51.2 12.3H32.8 13.4L0.1 25.6 0 40.9 17 58l-6.3 6.3v6.2h4L27 58.1l5.8 5.8 12.5 12.3 0 2.9 0 34.8 32.8 0.1 9.4 9.4v-9.4h12.5l3.8 3.8 0 5.9 3.9-3.9v-6.6l11.9-11.9V88.8h10.2L147 71.6 121.8 46.4zM95.1 26.8v-4.7h19.2l-9.8 9.8v26.9L84 37.9 95.1 26.8zM106 92.6l0-13.6H89.7l8.9-8.9 15 15L106 92.6z" style={{
                                fill: northAmericaColor,
                                outline: 'none'
                            }}/>
                        </g>
                        <g className="region region-america" opacity="1">
                            <polygon data-tooltip-id="region-america-icon-1" className="latin-america map-shape" points="129.6 142.9 117.4 142.9 112.8 147.4 99.6 147.4 99.6 132.6 92.9 139.3 87.5 139.3 87.5 123.3 78.2 113.9 45.4 113.8 85.3 153.7 109.9 153.7 95.3 168.3 95.3 192.2 107.9 204.8 107.9 231.9 102.5 237.3 102.5 269.2 112.4 279.1 119.7 279.1 119.7 261.5 122.2 261.5 122.2 251.8 147.2 226.8 147.2 221.8 166.7 202.4 166.7 180" style={{
                                fill: latinAmericaColor,
                                outline: 'none'
                            }}/>
                            <polygon data-tooltip-id="region-america-icon-1" className="latin-america map-shape" points="105.1 131 112.3 131 112.3 127.4 105.1 127.4" style={{
                                fill: latinAmericaColor,
                                outline: 'none'
                            }}/>
                        </g>
                    </g>
                </g>
                <g className="mbc-front-group"/>
            </g>
        </svg>
        {
            [0, 1].map(item => (
                <ReactTooltip
                    key={`chart-options-path-tooltip-america-${item}`}
                    id={`region-america-icon-${item}`}
                    place="top"
                    variant="info"
                    style={{
                        background: '#fff', 
                        color: '#000000',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 1px 2px 10px',
                        opacity: 1,
                        zIndex: '9999'
                    }}
                    content={() => {
                        return (
                            <Tooltip {...getData(item)}/>
                        )
                    }}
                />
            ))
        }
        </>
    )
}

export const EuropeIcon = ({
    unitedKingdomColor,
    developedColor,
    emergingColor,
    africaColor,
    unitedKingdomValue,
    developedValue,
    emergingValue,
    africaValue
}) => {

    const getData = (index) => {
        let title = '';
        let color = '';
        let value = '';

        if (index === 0) {
            title = 'United Kingdom';
            color = unitedKingdomColor;
            value = unitedKingdomValue;
        }else if (index === 1) {
            title = 'Europe Developed';
            color = developedColor;
            value = developedValue;
        }else if (index === 2) {
            title = 'europe-Emerging';
            color = emergingColor;
            value = emergingValue;
        }else if (index === 3) {
            title = 'europe-Africa';
            color = africaColor;
            value = africaValue;
        }

        return {title, color, value}
    }

    return (
        <>
        <svg role="chart" className="mbc-canvas" width="980px" height="480px" viewBox="0 0 980 480"> 
            <g className="mbc-layers" transform="translate(0,0)" id="sdwan" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> 
                <g className="mbc-axes"/>  
                <g className="mbc-back-group"/>  
                <g className="mbc-chart-group no-drag" transform="translate(-3.534240350100198, 4) scale(0.2205455561633684)"> 
                <g className="map-back-layer"> 
                    <rect x="0" y="0" width="566.7763258281507" height="725.4736970600329" className="map-overlay" transform="translate(16.0249900817871, -18.136842426500824)"/> 
                </g>  
                <g className="map-layer"> 
                    <g className="region region-united-kingdom" opacity="1"> 
                        <polygon data-tooltip-id="region-europe-icon-0" className="united-kingdom map-shape" points="157.4 131.9 144.1 145.3 157.4 158.3" style={{
                            fill: unitedKingdomColor,
                            outline: 'none'
                        }}/>  
                        <polygon data-tooltip-id="region-europe-icon-0" className="united-kingdom map-shape" points="189.2 99.9 160.2 129.1 189.5 158.1" style={{
                            fill: unitedKingdomColor,
                            outline: 'none'
                        }}/>
                    </g>  
                    <g className="region region-europe-developed" opacity="1"> 
                        <polygon data-tooltip-id="region-europe-icon-1" className="europe-developed map-shape" points="242.5,136.5 262.4,116.6 262.4,91.6 279.1,74.9 279.1,47.9 303.3,23.8 282.5,23.8 206.2,100.4" style={{
                            fill: developedColor,
                            outline: 'none'
                        }}/>  
                        <polygon data-tooltip-id="region-europe-icon-1" className="europe-developed map-shape" points="98.4,86 125.1,59.3 72,59.3" style={{
                            fill: developedColor,
                            outline: 'none'
                        }}/>  
                        <path data-tooltip-id="region-europe-icon-1" className="europe-developed map-shape" d="M329.6,217.6h-52.2v-81.2h-34.9l-99.9,100.2l42,42.6l34.9-35.2l27,27.2l34.3-34.3l18.4,18.4h63.3L329.6,217.6z" style={{
                            fill: developedColor,
                            outline: 'none'
                        }}/>  
                        <polygon data-tooltip-id="region-europe-icon-1" className="europe-developed map-shape" points="279.1,102.4 311.5,102.4 311.5,23.8 303.3,23.8 279.1,47.9" style={{
                            fill: developedColor,
                            outline: 'none'
                        }}/> 
                    </g>  
                    <g className="region region-europe-emerging" opacity="1"> 
                        <polygon data-tooltip-id="region-europe-icon-2" className="europe-emerging map-shape" points="533.7 164.3 475.5 164.3 475.5 178.5 445.7 178.5 445.7 202.6 427.5 220.8 427.5 255.1 362.6 255.1 329.6 217.6 277.4 217.6 277.4 136.5 311.5 102.4 311.5 23.8 375.6 23.8 407.7 55.6 463 0.6 505.9 43.4 533.7 15.9" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/> 
                    </g>  
                    <g className="region region-africa-middle-east" opacity="1"> 
                        <polygon data-tooltip-id="region-europe-icon-3" className="africa-middle-east map-shape" points="520.3 268.4 533.7 255.1 483.5 255.1 483.5 234.4 453.7 234.4 453.7 281.2 427.5 255.1 299.6 255.1 326.2 282.1 326 282.1 326 305.3 326 305.3 402.9 382.2 403.1 415.1 534.8 282.9" style={{
                            fill: africaColor,
                            outline: 'none'
                        }}/>  
                        <polygon data-tooltip-id="region-europe-icon-3" className="africa-middle-east map-shape" points="326 338.5 326.2 282.1 184.6 282.1 128.2 338.5 128.2 428.2 158.5 458.6 202.8 458.6 254.4 512.8 254.4 689.8 338.7 605.6 338.7 577.2 373.3 542.6 373.3 469.3 415.3 427.3" style={{
                            fill: africaColor,
                            outline: 'none'
                        }}/>  
                        <polygon data-tooltip-id="region-europe-icon-3" className="africa-middle-east map-shape" points="397.8 551.1 397.8 597.9 444.6 551.1" style={{
                            fill: africaColor,
                            outline: 'none'
                        }}/> 
                    </g> 
                </g> 
                </g>  
                <g className="mbc-front-group"/> 
            </g> 
        </svg>
        {
            [0, 1, 2, 3].map(item => (
                <ReactTooltip
                    key={`chart-options-path-tooltip-america-${item}`}
                    id={`region-europe-icon-${item}`}
                    place="top"
                    variant="info"
                    style={{
                        background: '#fff', 
                        color: '#000000',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 1px 2px 10px',
                        opacity: 1,
                        zIndex: '9999'
                    }}
                    content={() => {
                        return (
                            <Tooltip {...getData(item)}/>
                        )
                    }}
                />
            ))
        }
        </>
    )
}

export const AsiaIcon = ({
    japanColor,
    australasiaColor,
    developedColor,
    emergingColor,
    japanValue,
    australasiaValue,
    developedValue,
    emergingValue
}) => {

    const getData = (index) => {
        let title = '';
        let color = '';
        let value = '';

        if (index === 0) {
            title = 'Asia Emerging';
            color = emergingColor;
            value = emergingValue;
        }else if (index === 1) {
            title = 'Asia Developed';
            color = developedColor;
            value = developedValue;
        }else if (index === 2) {
            title = 'Australasia';
            color = australasiaColor;
            value = australasiaValue;
        }else if (index === 3) {
            title = 'Japan';
            color = japanColor;
            value = japanValue;
        }

        return {title, color, value}
    }

    return (
        <>
        <svg role="chart" className="mbc-canvas" width="980px" height="480px" viewBox="0 0 980 480"> 
            <g className="mbc-layers" transform="translate(0,0)" id="sdwan" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> 
                <g className="mbc-axes"/>
                <g className="mbc-back-group"/>
                <g className="mbc-chart-group no-drag" transform="translate(-9.616790496089699, 4) scale(0.20762191649896797)">
                <g className="map-back-layer">
                    <rect x="0" y="0" width="602.0559009752775" height="770.6315532483552" className="map-overlay" transform="translate(46.31876373291016, -19.26578883120888)"/>
                </g>
                <g className="map-layer">
                    <g className="region region-asia-emerging" opacity="1">
                    <polygon data-tooltip-id="region-asia-icon-0" className="asia-emerging map-shape" points="354.1 280.5 326.2 308.9 354.1 336.9" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/>
                    <polygon data-tooltip-id="region-asia-icon-0" className="asia-emerging map-shape" points="249.1 474 230 474 249.1 492.7" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/>
                    <polygon data-tooltip-id="region-asia-icon-0" className="asia-emerging map-shape" points="335.6 393.1 354.3 393.1 354.3 374.4" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/>
                    <polygon data-tooltip-id="region-asia-icon-0" className="asia-emerging map-shape" points="319.7 410.4 319.7 393.1 302.4 393.1" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/>
                    <polygon data-tooltip-id="region-asia-icon-0" className="asia-emerging map-shape" points="242.8 393.1 319.7 393.1 319.7 302.3 326.2 308.9 354.1 280.5 354.1 235.6 324.8 206.1 260.4 271.1 189.5 271.7 118.8 200.7 58.1 200.7 58.1 214.9 28.3 214.9 28.3 239 36.2 231.1 55.8 251 36.2 270.8 66 270.8 66 291.5 116.3 291.5 103.2 304.9 117.4 319.3 67.7 369.6 88.2 369.6 88.4 506.1 175.3 419.2 230 474 276.9 427.5" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/>
                    <polygon data-tooltip-id="region-asia-icon-0" className="asia-emerging map-shape" points="321.1 502.9 378.7 502.9 378.7 445.1" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/>
                    <polygon data-tooltip-id="region-asia-icon-0" className="asia-emerging map-shape" points="432.1 544.4 473.2 502.7 390.1 502.9" style={{
                            fill: emergingColor,
                            outline: 'none'
                        }}/>
                    </g>
                    <g className="region region-asia-developed" opacity="1">
                    <polygon data-tooltip-id="region-asia-icon-1" className="asia-developed map-shape" points="116.3 200.7 118.8 200.7 189.5 271.7 260.4 271.1 324.8 206.1 354.1 235.6 354.1 280.5 431.5 203.3 396.9 168.7 421 144.5 483.5 207 578.5 111.9 578.5 84.1 457.9 84.1 423.9 50.3 353.2 50.3 318.9 16 152.9 16 116.3 52.3 116.3 200.7" style={{
                            fill: developedColor,
                            outline: 'none'
                        }}/>
                    </g>
                    <g className="region region-australasia" opacity="1">
                    <polygon data-tooltip-id="region-asia-icon-2" className="australasia map-shape" points="335.9 562.3 264.9 633.2 264.9 697 376.8 697 421.3 741.6 468.1 694.8 468.1 612.5 417.9 562.3" style={{
                            fill: australasiaColor,
                            outline: 'none'
                        }}/>
                    <polygon data-tooltip-id="region-asia-icon-2" className="australasia map-shape" points="482 725.1 505 748.1 537.9 715.2 537.9 669.5" style={{
                            fill: australasiaColor,
                            outline: 'none'
                        }}/>
                    </g>
                    <g className="region region-japan" opacity="1">
                    <polygon data-tooltip-id="region-asia-icon-3" className="japan map-shape" points="394.1 337.5 440 337.5 483.5 294.1 483.5 248.4" style={{
                            fill: japanColor,
                            outline: 'none'
                        }}/>
                    </g>
                </g>
                </g>
                <g className="mbc-front-group"/>
            </g>
        </svg>
        {
            [0, 1, 2, 3].map(item => (
                <ReactTooltip
                    key={`chart-options-path-tooltip-america-${item}`}
                    id={`region-asia-icon-${item}`}
                    place="top"
                    variant="info"
                    style={{
                        background: '#fff', 
                        color: '#000000',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 1px 2px 10px',
                        opacity: 1,
                        zIndex: '9999'
                    }}
                    content={() => {
                        return (
                            <Tooltip {...getData(item)}/>
                        )
                    }}
                />
            ))
        }
        </>
    )
}


export const AssetIcon = () => {
    return (
        <svg role="chart" class="mbc-canvas" style={{fontSize: 9,  width: "100%", height:"100%"}}>
            <g class="mbc-layers" transform="translate(2,0)">
                <g class="mbc-axes">
                    <g class="mbc-axis mbc-axis-x mbc-axis-bottom" transform="translate(0, 99)" y="4" x="0" style={{
                        textAnchor: 'middle'
                    }}>
                        <g class="tick" transform="translate(0,0)" style={{
                            opacity: 1
                        }}>
                            <line stroke="#ddd" y2="-93" x2="0" y1="-6"></line>
                            <text dy=".71em" y="1" x="0" style={{
                                textAnchor: 'start'
                            }}>–100</text>
                        </g>
                        <g class="tick" transform="translate(26.25,0)" style={{
                            opacity: 1
                        }}>
                            <line stroke="#ddd" y2="-93" x2="0" y1="-6"></line>
                            <text dy=".71em" y="1" x="0" style={{
                                textAnchor: 'middle'
                            }}>–50</text>
                        </g>
                        <g class="tick" transform="translate(52.5,0)" style={{
                            opacity: 1
                        }}>
                            <line stroke="#ddd" y2="-93" x2="0" y1="-6"></line>
                            <text dy=".71em" y="1" x="0" style={{
                                textAnchor: 'middle'
                            }}>0</text>
                        </g>
                        <g class="tick" transform="translate(78.75,0)" style={{
                            opacity: 1
                        }}>
                            <line stroke="#ddd" y2="-93" x2="0" y1="-6"></line>
                            <text dy=".71em" y="1" x="0" style={{
                                textAnchor: 'middle'
                            }}>50</text>
                        </g>
                        <g class="tick" transform="translate(105,0)" style={{
                            opacity: 1
                        }}>
                            <line stroke="#ddd" y2="-93" x2="0" y1="-6"></line>
                            <text dy=".71em" y="1" x="0" style={{
                                textAnchor: 'end'
                            }}>100</text>
                        </g>
                        <path class="domain" d=""></path>
                    </g>
                    <g class="mbc-axis mbc-axis-y mbc-axis-left" transform="translate(0, 0)">
                        <path class="domain" d="M0,0H0V99H0"></path>
                    </g>
                </g>
                <g class="mbc-back-group"></g>
                <g class="mbc-chart-group">
                    <g class="bar-chart-layer">
                        <g class="sal-bars-group">
                            <rect y="6" height="12.600000000000001" x="52.5" width="51.489758249999994" class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#006098"></rect>
                            <path class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#006098"></path>
                            <rect y="6" height="12.600000000000001" x="52.5" width="50.153565" class="h-bar-netAllocation" fill="#006098" fillOpacity="1" stroke="#006098"></rect>
                            <path class="h-bar-netAllocation" fill="#006098" fillOpacity="1" stroke="#006098"></path>
                            <rect y="6" height="12.600000000000001" x="51.163812" width="1.336188" class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#006098"></rect>
                            <path class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#006098"></path>
                        </g>
                        <g class="sal-bars-group">
                            <rect y="24.6" height="12.600000000000001" x="52.5" width="0" class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#EF7621"></rect>
                            <path class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#EF7621"></path>
                            <rect y="24.6" height="12.600000000000001" x="52.5" width="0" class="h-bar-netAllocation" fill="#EF7621" fillOpacity="1" stroke="#EF7621"></rect>
                            <path class="h-bar-netAllocation" fill="#EF7621" fillOpacity="1" stroke="#EF7621"></path>
                            <rect y="24.6" height="12.600000000000001" x="52.5" width="0" class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#EF7621"></rect>
                            <path class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#EF7621"></path>
                        </g>
                        <g class="sal-bars-group">
                            <rect y="43.2" height="12.600000000000001" x="52.5" width="24.051137249999996" class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#648C19"></rect>
                            <path class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#648C19"></path>
                            <rect y="43.2" height="12.600000000000001" x="52.5" width="2.3464297499999986" class="h-bar-netAllocation" fill="#648C19" fillOpacity="1" stroke="#648C19"></rect>
                            <path class="h-bar-netAllocation" fill="#648C19" fillOpacity="1" stroke="#648C19"></path>
                            <rect y="43.2" height="12.600000000000001" x="30.795292500000002" width="21.704707499999998" class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#648C19"></rect>
                            <path class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#648C19"></path>
                        </g>
                        <g class="sal-bars-group">
                            <rect y="61.800000000000004" height="12.600000000000001" x="52.5" width="0" class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#DCAA00"></rect>
                            <path class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#DCAA00"></path>
                            <rect y="61.800000000000004" height="12.600000000000001" x="52.5" width="0" class="h-bar-netAllocation" fill="#DCAA00" fillOpacity="1" stroke="#DCAA00"></rect>
                            <path class="h-bar-netAllocation" fill="#DCAA00" fillOpacity="1" stroke="#DCAA00"></path>
                            <rect y="61.800000000000004" height="12.600000000000001" x="52.5" width="0" class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#DCAA00"></rect>
                            <path class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#DCAA00"></path>
                        </g>
                        <g class="sal-bars-group">
                            <rect y="80.4" height="12.600000000000001" x="52.5" width="0" class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#AC0032"></rect>
                            <path class="h-bar-longAllocation" fill="#fff" fillOpacity="0" stroke="#AC0032"></path>
                            <rect y="80.4" height="12.600000000000001" x="52.5" width="0" class="h-bar-netAllocation" fill="#AC0032" fillOpacity="1" stroke="#AC0032"></rect>
                            <path class="h-bar-netAllocation" fill="#AC0032" fillOpacity="1" stroke="#AC0032"></path>
                            <rect y="80.4" height="12.600000000000001" x="52.5" width="0" class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#AC0032"></rect>
                            <path class="h-bar-shortAllocation" fill="#fff" fillOpacity="0" stroke="#AC0032"></path>
                        </g>
                    </g>
                    <g class="benchmark-layer"></g>
                </g>
                <g class="mbc-front-group">
                    <g class="tooltip-canvas-layer">
                        <rect opacity="0" class="tooltip-canvas-bars" transform="translate(0,0 )" y="6" height="12.600000000000001" x="0" width="100%"></rect>
                        <rect opacity="0" class="tooltip-canvas-bars" transform="translate(0,0 )" y="24.6" height="12.600000000000001" x="0" width="100%"></rect>
                        <rect opacity="0" class="tooltip-canvas-bars" transform="translate(0,0 )" y="43.2" height="12.600000000000001" x="0" width="100%"></rect>
                        <rect opacity="0" class="tooltip-canvas-bars" transform="translate(0,0 )" y="61.800000000000004" height="12.600000000000001" x="0" width="100%"></rect>
                        <rect opacity="0" class="tooltip-canvas-bars" transform="translate(0,0 )" y="80.4" height="12.600000000000001" x="0" width="100%"></rect>
                    </g>
                </g>
            </g>
        </svg>
    )
}