import React from 'react';
import { isEmpty, round, isNumber } from 'lodash';
import * as echarts from 'echarts';
import { getAssetChartData, getAssetListChartData } from '../../../utils/xray';
import { returnFloat } from '../../../utils/details';
import { StepTabs } from '../StockRegions/StockRegions';
import { Decimal } from 'decimal.js';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import styles from './index.module.css'

const { useMemo, useRef, useEffect } = React;

const AssetAllocation = ({
    data,
    isShowHoldings
}) => {

    const tabsOptions = useMemo(() => {
        return [
            {
                index: 0,
                key: 'AssetAllocation-tabs-0',
                Element: () => {
                    return (
                        <PieChart 
                            data={data} 
                            isShowHoldings={isShowHoldings}
                        />
                    )
                }
            },
            {
                index: 1,
                key: 'AssetAllocation-tabs-1',
                Element: () => {
                    return (
                        <ListChart data={data}/>
                    )
                }
            }
        ]
    }, [data, isShowHoldings])

    return (
        <div className={styles.container}>
            <span className={styles.title}>
                Asset Allocation
            </span>
            <StepTabs tabs={tabsOptions} topStart={0}/>
        </div>
    )
};

const PieChart = ({
    data,
    isShowHoldings
}) => {

    const elementRef = useRef(null);
    const chartRef = useRef(null);

    const pieListData = useMemo(() => {
        const values = getAssetChartData(data);
        console.log(values)
        return [
            {
                isHeader: true,
                label: '',
                value: 'Net, %',
                key: 'assetAllocation-net'
            },
            {
                key: 'assetAllocation-stocks',
                label: 'Stocks',
                value: values['1'],
                color: '#1f55a5'
            },
            {
                key: 'assetAllocation-bonds',
                label: 'Bonds',
                value: values['2'],
                color: '#ef7622'
            },
            {
                key: 'assetAllocation-cash',
                label: 'Cash',
                value: values['3'],
                color: '#518428'

            },
            {
                key: 'assetAllocation-other',
                label: 'Other',
                value: values['4'],
                color: '#f5c400'
            },
            {
                key: 'assetAllocation-notClass',
                label: 'Not class',
                value: values['99'],
                color: '#e5e5e5'
            }
        ]
    }, [data]);

    const pieChartOptions = useMemo(() => {
        const data = pieListData.filter(item => !item.isHeader).map(({value, label, color}) => {
            return {
                value,
                name: label,
                itemStyle: {
                    color
                }
            }
        });
        
        return {
            tooltip: {
                trigger: 'item',
                confine: true
            },
            series: [
                {
                    name: 'Asset Allocation',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    label: {show: false},
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data
                }
            ]
        }
    }, [pieListData]);

    useEffect(() => {
        const element = elementRef.current;

        if (element && !(isEmpty(pieChartOptions))) {
            const instance = echarts.init(element);
            chartRef.current = instance;
            instance.setOption(pieChartOptions);
        }
    }, [pieChartOptions]);

    useEffect(() => {
        if (!(isEmpty(chartRef.current))) {
            setTimeout(() => {
                chartRef.current.resize();
            }, 400)
        }
    }, [isShowHoldings]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (!(isEmpty(chartRef.current))) {
                chartRef.current.resize();
            }
        })
    }, []);

    return (
        <div className={styles.info}>
            <div className={styles['info-chart']} ref={elementRef}/>
            <div className={styles['info-list']}>
                {
                    pieListData.map(({key, label, value, color, isHeader}) => (
                        <div
                            key={key}
                            className={`${styles['info-list-item']} ${isHeader && styles['info-list-item-header']}`}
                        >
                            <div className={styles['info-list-item-left']}>
                                <div 
                                    className={styles['info-list-item-left-box']}
                                    style={{
                                        background: color,
                                        display: isHeader && 'none'
                                    }}
                                />
                                <span>{label}</span>
                            </div>
                            <span>{value}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

const ListChart = ({data}) => {

    const chartData = useMemo(() => {
        const longValues = getAssetListChartData(data, 'L');
        const shortValues = getAssetListChartData(data, 'S');
        const netValues = getAssetListChartData(data, 'N');

        return [
            {
                isHeader: true,
                label: '',
                value: ['Long%', 'Short%'],
                key: 'assetAllocation-list-net'
            },
            {
                key: 'assetAllocation-list-stocks',
                label: 'Stocks',
                value: [longValues['1'], shortValues['1']],
                netValue: netValues['1'],
                color: '#1f55a5'
            },
            {
                key: 'assetAllocation-list-bonds',
                label: 'Bonds',
                value: [longValues['2'], shortValues['2']],
                netValue: netValues['2'],
                color: '#ef7622'
            },
            {
                key: 'assetAllocation-list-cash',
                label: 'Cash',
                value: [longValues['3'], shortValues['3']],
                netValue: netValues['3'],
                color: '#518428'
            
            },
            {
                key: 'assetAllocation-list-other',
                label: 'Other',
                value: [longValues['4'], shortValues['4']],
                netValue: netValues['4'],
                color: '#f5c400'
            },
            {
                key: 'assetAllocation-list-notClass',
                label: 'Not class',
                value: [longValues['99'], shortValues['99']],
                netValue: netValues['99'],
                color: '#e5e5e5'
            }
        ]
    }, [data]);

    const chartRectOptions = useMemo(() => {
        const baseData = chartData.filter(item => !item.isHeader);
        return baseData.map(({value, netValue, color, label}, index) => {
            if (!(isNumber(netValue))) {
                return {width: 0}
            }

            const longValue = value[0];
            const shortValue = value[1];

            
            const x = `${new Decimal(100).minus(new Decimal(shortValue)).div(2).toNumber()}%`;
            const y = `${index * 18}%`;
            const width = `${new Decimal(longValue).div(2).plus(new Decimal(shortValue).div(2))}%`;
            const height = '15%';
            return {
                default: {
                    x,
                    y,
                    width,
                    height,
                    strokeWidth: 1,
                    stroke: color,
                    fill: '#fff'
                },
                net: {
                    x: '50%',
                    y,
                    height,
                    width: `${new Decimal(netValue).div(2)}%`,
                    fill: color,
                },
                longValue,
                shortValue,
                netValue,
                label,
                color
            }
        });
    }, [chartData]);

    return (
        <div className={styles.info}>
            {
                [0, 1, 2, 3, 4].map(item => (
                    <ReactTooltip
                        key={`chart-options-rect-tooltip-${item}`}
                        anchorId={`rect-tooltip-${item}`}
                        place="top"
                        variant="info"
                        style={{
                            background: '#fff', 
                            color: '#000000',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 1px 2px 10px',
                            opacity: 1,
                            zIndex: '9999'
                        }}
                        content={() => {
                            return (
                                <Tooltip data={chartRectOptions[item]}/>
                            )
                        }}
                    />
                ))
            }
            <div className={styles['list-chart']}>
                <svg style={{fontSize: 10,  width: "100%", height:"100%"}}>
                    <g>
                        <line x1="0%" y1="0" x2="0%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                        <line x1="25%" y1="0" x2="25%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                        <line x1="50%" y1="0" x2="50%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                        <line x1="75%" y1="0" x2="75%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                        <line x1="100%" y1="0" x2="100%" y2="90%" stroke="#ddd" strokeWidth="1"/>
                    </g>
                    <g>
                        <text y="95%" x="0%">-100</text>
                        <text y="95%" x="25%" textAnchor="middle">-50</text>
                        <text y="95%" x="50%" textAnchor="middle">0</text>
                        <text y="95%" x="75%" textAnchor="middle">50</text>
                        <text y="95%" x="100%" textAnchor="end">100</text>
                    </g>
                    <g>
                        {
                            chartRectOptions.map((item, index) => (
                                <g key={`chart-options-rect-${index}`}>
                                    <rect {...item.default}/>
                                    <rect {...item.net}/>
                                </g>
                            ))
                        }
                    </g>
                    <g>
                        <rect x="0" y="0" width="100%" height="18%" opacity="0" id="rect-tooltip-0" style={{outline: 'none'}}/>
                        <rect x="0" y="18%" width="100%" height="18%" opacity="0" id="rect-tooltip-1" style={{outline: 'none'}}/>
                        <rect x="0" y="36%" width="100%" height="18%" opacity="0" id="rect-tooltip-2" style={{outline: 'none'}}/>
                        <rect x="0" y="54%" width="100%" height="18%" opacity="0" id="rect-tooltip-3" style={{outline: 'none'}}/>
                        <rect x="0" y="72%" width="100%" height="18%" opacity="0" id="rect-tooltip-4" style={{outline: 'none'}}/>
                    </g>
                </svg>
            </div>
            <div className={styles['list-info']}>
                {
                    chartData.map(({label, value, color, isHeader, key}, index) => (
                        <div
                            key={key}
                            className={`${styles['list-info-item']} ${isHeader && styles['list-info-item-header']}`}
                            style={{paddingRight: 0}}
                        >
                            <div className={styles['info-list-item-left']}>
                                <div 
                                    className={styles['info-list-item-left-box']}
                                    style={{
                                        background: color,
                                        display: isHeader && 'none'
                                    }}
                                />
                                <span>{label}</span>
                            </div>
                            {
                                value.map((item, valueIndex) => (
                                    <span key={`list-info-value-${index-valueIndex}`}>{isNumber(item) ? returnFloat(round(item, 2)) : item}</span>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

const Tooltip = ({data = {}}) => {

    const getValue = (value) => {
        return isNumber(value) ? returnFloat(round(value, 2)) : '0.00';
    };

    return (
        <div className={styles['tooltip']}>
            <span className={styles['tooltip-title']} style={{color: data.color}}>
                {data.label}
            </span>
            <div className={styles['tooltip-info']}>
                <div className={styles['tooltip-info-item']}>
                    <span>Long: </span>
                    <span>{getValue(data.longValue)}</span>
                </div>
                <div className={styles['tooltip-info-item']}>
                    <span>Net: </span>
                    <span>{getValue(data.netValue)}</span>
                </div>
                <div className={styles['tooltip-info-item']}>
                    <span>Short: </span>
                    <span>{getValue(data.shortValue)}</span>
                </div>
            </div>
        </div>
    )
}

export default AssetAllocation;