import { isEmpty, round } from 'lodash';
import { Decimal } from 'decimal.js';

export const getCurrentDate = () => {
    const  now =  new Date();
    return now.getFullYear() +'-'+ ( (now.getMonth()+1)>10 ? (now.getMonth()+1):'0'+(now.getMonth()+1) ) +'-'+now.getDate();
};

export const getDate = (startDate, months) => {
    const d = new Date(startDate);
    d.setMonth(d.getMonth() - months, 0);

    const str = d.getFullYear() +'-'+ ( (d.getMonth()+1)>10 ? (d.getMonth()+1):'0'+(d.getMonth()+1) ) +'-'+d.getDate();
    return str;
}
export const getGraphData = (historicalPerformanceSeries, startDate, endDate) => {
    if (isEmpty(historicalPerformanceSeries)) return {}

	const returns = historicalPerformanceSeries.filter(item => item.frequency == 'Monthly')[0].returns.filter(item => {
        return item[0] > startDate && item[0] < endDate
	});
	if (isEmpty(returns)) return {}

	let initAmount;
	const minStartDate = returns[0][0];
	const series = [{
		date: getDate(minStartDate,0),
		value: 10
	}];
	for(let i = 0; i< returns.length; i++){ 
		initAmount = new Decimal(series[i].value).mul(new Decimal(100).add(new Decimal(returns[i][1]))).div(new Decimal(100))
	 	series.push({
	 	 	date: returns[i][0],
	 	 	value: initAmount.toNumber()
         });
         
	}
	const target = (new Decimal (series[series.length - 1].value).sub(new Decimal(10))).div(new Decimal(10)).mul(100);
	const currentTarget = target.toFixed(2, Decimal.ROUND_DOWN);
	return {
        series: series.map(({date, value}) => [date, value]),
		target: target.greaterThanOrEqualTo(0) ? `+${currentTarget.toString()}` : currentTarget.toString()
	}
}