export const assemblyParams = (data = []) => {
    return data.reduce((previousValue, currentValue, currentIndex) => {
        
        if (currentIndex === 1) {
            return `?${previousValue.name}=${previousValue.value}&${currentValue.name}=${currentValue.value}`
        }
        return `${previousValue}&${currentValue.name}=${currentValue.value}`
    });
};

export const assemblyListParams = (data = {}) => {
    return assemblyParams(Object.keys(data).map(item => {
        return {
            name: item,
            value: data[item]
        }
    }))
};

export const coverageSelectFilters = (filters, keys = []) => {
    let text = ''

    filters.split('|').filter(item => {
        let status = true;

        for (let i = 0; i < keys.length; i++) {
            if (item.includes(keys[i])) {
                status = false
            }
        }
        return status
    }).map((item, index) => {
        if (index === 0) {
            text = item;
            return
        }
        text = `${text}|${item}`
    });
    return text; 
};
