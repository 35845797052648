import { isBoolean, isNumber, isEmpty, round, get } from 'lodash';
import { returnFloat } from './details'
import { Decimal } from 'decimal.js';

export const getParams = (params) => {
    return JSON.stringify(JSON.stringify(params));
};

export const validateHoldingsOptions = (options) => {
    if (isEmpty (options)) return false;
        
    let isShowError = false;

    const arr = options.filter(({isSelect, weight}) => {
        const selectStatus = isBoolean(isSelect) ? isSelect : true;
        const status =  selectStatus && isNumber(weight);

        if (status && ( weight > 100 || weight <= 0)) {
            isShowError = true
        }
        return status;
    });
    

    const weight = arr.length === 0 ? 0 : arr.length === 1 ? arr[0].weight : arr.reduce((next, current, index) => {
        if(index === 1) {
            return new Decimal(next.weight).plus(new Decimal(current.weight))
        }
        return next.plus(new Decimal(current.weight))
    }).toNumber();

    return {
        weight,
        isError: weight !== 100 ? true : isShowError
    }; 
}

export const filterOptions = (currentOptions, nextOptions) => {
    if (isEmpty(nextOptions)) {
        return currentOptions;
    }

    return nextOptions.map(nextItem => {
        for (let i = 0; i < currentOptions.length; i++) {
            const currentItem = currentOptions[i];
            const currentId = currentItem.securityId;
            const nextItemId = nextItem.securityId || nextItem.secId;
            if (currentId === nextItemId) {
                return currentItem
            }
        }
        return nextItem;
    });
};

export const getStockSectorsItemValue = (data = []) => {
    let total = '-';

    data.forEach(({value}) => {
        if (!(isNumber(value))) return;

        if (total === '-') {
            total = new Decimal(value)
        } else {
            total = total.plus(new Decimal(value));
        }
    });
    return total === '-' ? total : returnFloat(round(total.toNumber(), 2));
};


export const getRateRiskValue = (data, key, isCode = false) => {
    const value = get(data, `bondStatistics.${key}.${isCode ? 'code' : 'value'}`);
    
    return isCode ? value : isNumber(value) ? returnFloat(round(value, 2)) : '-';
};

export const getCreditQualityValue = (data, key) => {
    const creditQuality = (get(data, 'breakdowns.creditQuality') || []).filter(item => item.salePosition === 'N');
    
    if (isEmpty(creditQuality)) return '-';

    const values = get(creditQuality, '0.values') || {};
    const value = get(values, key);

    return isNumber(value) ? returnFloat(round(value, 2)) : '-';
};


export const getAssetChartData = (data) => {
    const values = data.filter((item) => item.salePosition === 'N');

    if (isEmpty(values)) return {}

    let obj = {};
    const arr = Object.keys(values[0].values || {});
    
    arr.map((key) => {
        const value = (values[0].values || {})[key];
        obj[key] = isNumber(value) ? returnFloat(round(value, 2)) : '-';
    })
    return obj;
}

export const getAssetListChartData = (data, salePosition = 'S') => {
    const values = data.filter((item) => item.salePosition === salePosition);

    if (isEmpty(values)) return {}

    let obj = {};
    const arr = Object.keys(values[0].values || {});

    arr.map((key) => {
        const value = (values[0].values || {})[key];
        obj[key] = isNumber(value) ? value : 0;
    })
    return obj;
}

export const getPDFLink = (languageId, benchmark, array) => {
    const securityTokenList = array.map(item => (item.sedId || item.securityId) + "]"+item.holdingType+"]1]"+item.universeId).join("|");
    const securityWeigths = array.map(item => item.weight).join("|");
    return 'https://gllt.morningstar.com/wcunxicr3v/xraypdf/default.aspx?SecurityTokenList='+securityTokenList+'&Values='+securityWeigths+'&LanguageId='+languageId+'&CurrencyId=BAS&BenchmarkIndexId='+benchmark;
};

export const getPortfolioLink = (languageId, benchmark, array) => {
    var currentHoldings = array.map(item => (item.sedId || item.securityId)+"|MSID|"+ item.weight * 100).join(",");
    return 'https://gllt.morningstar.com/wcunxicr3v/custom/simplepage2/default.aspx?current-currencyID=BAS&languageId='+languageId+'&current-holdings='+currentHoldings+'&current-benchmark='+benchmark;
}