import React from 'react';
import { get } from 'lodash';
import Table from './Table';
import Chart from './Chart/Chart';
import styles from './chartContainer.module.css';
import closeIcon from '../../assets/xray/close2.svg';

const { useState, useMemo, useEffect } = React;

const COLORS = [
    "#2952A3",
    "#8D6F47",
    "#4E5D6C",
    "#865A5A",
    "#5A6986",
    "#0D7813",
    "#5229A3",
    "#528800",
    "#88880E",
    "#6E6E41",
    "#BE6D00",
    "#B1365F",
    "#705770",
    "#7A367A",
    "#A32929",
    "#B1440E",
    "#29527A",
    "#4A716C",
    "#28754E",
    "#1B887A",
    "#AB8B00"
];

const ChartContainer = ({
    dataIds,
    setDataIds,
    tableData,
    setTableData,
    chartData,
    setChartData,
    tableLoading,
    chartLoading
}) => {

    const [isShowTable, setIsShowTable] = useState(true)
    const [tabsOptions, setTabsOptions] = useState([
        {
            key: 'snapshot',
            label: 'Snapshot',
            isActive: true,
            fields: [
                {key: 'key', label: 'Key'},
                {key: 'delete', label: 'Delete'},
                // {key: 'null', label: 'Return / Price'},
                {key: 'LegalName', label: 'Name'},
                {key: 'ISIN', label: 'ISIN'},
                {key: 'ReturnM0', label: 'YTD Return(%)'},
                {key: 'ClosePrice', label: 'Last Close'},
                {key: 'ClosePriceDate', label: 'Last Price Date'},
                {key: 'InceptionDate', label: 'Inception Date'},
            ],
        },
        {
            key: 'shortTermPerformance',
            label: 'Short Term Performance',
            isActive: false,
            fields: [
                {key: 'key', label: 'Key'},
                {key: 'delete', label: 'Delete'},
                // {key: 'null', label: 'Return / Price'},
                {key: 'LegalName', label: 'Name'},
                {key: 'ReturnD1', label: '1 Day Return(%)'},
                {key: 'ReturnM1', label: '1 Month Return(%)'},
                {key: 'ReturnM3', label: '3 Month Return(%)'},
                {key: 'ReturnM6', label: '6 Month Return(%)'},
            ],
        },
        {
            key: 'longTermPerformance',
            label: 'Long Term Performance',
            isActive: false,
            fields: [
                {key: 'key', label: 'Key'},
                {key: 'delete', label: 'Delete'},
                // {key: 'null', label: 'Return / Price'},
                {key: 'LegalName', label: 'Name'},
                {key: 'ReturnM12', label: '1 Yr Return(%)'},
                {key: 'ReturnM36', label: '3 Yr Annualized Return(%)'},
                {key: 'ReturnM60', label: '5 Yr Annualized Return(%)'},
            ],
        }
    ]);

    const tableColumns = useMemo(() => {
        const activeTab = tabsOptions.filter((item) => item.isActive)[0];
        return activeTab.fields.map(({key, label}) => {
            if (key === 'key') {
                return {
                    Header: label,
                    accessor: key,
                    Cell: ({row}) => (
                        <div className={styles['cell-key']} style={{backgroundColor: COLORS[row.index]}}></div>
                    )
                }
            }
            if (key === 'delete') {
                return {
                    Header: label,
                    accessor: key,
                    Cell: ({row}) => {
                        return  row.index !== 0 && (
                            <div className={styles['cell-delete']}>
                                <img
                                    src={closeIcon} 
                                    alt="close"
                                    onClick={() => onDeleteItem(row.original)}
                                />
                            </div>
                        )
                    }
                }
            }
            return {
                Header: label,
                accessor: key
            }
        });
    }, [tabsOptions, dataIds, chartData, tableData]);

    const chartOptions = useMemo(() => {
        return chartData.map((item, index) => {
            return {
                ...item,
                color: COLORS[index],
                name: get(tableData, `[${index}].LegalName`)
            }
        })
    }, [chartData, tableData]);

    const onUpdateTab = (key) => {
        setTabsOptions(tabsOptions.map(item => {
            return {
                ...item,
                isActive: item.key === key
            }
        }))
    };

    const onDeleteItem = (e) => {
        setDataIds(dataIds.filter(item => {
            return !(e.SecId === item)
        }));
        setChartData(chartData.filter(item => {
            return !(e.SecId === item.SecId)
        }));
        setTableData(tableData.filter(item => {
            return !(e.SecId === item.SecId)
        }));
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles['header-info']}>
                    <span>
                        Growth Chart
                    </span>
                    {/* <span className={styles['header-info-date']}>
                        2024-01-24
                    </span> */}
                </div>
                <button 
                    className={styles['header-button']}
                    onClick={() => { setIsShowTable(!isShowTable) }}
                >
                    { isShowTable ? 'Simple View' : 'Detailed View' }
                </button>
            </div>
            <div className={styles.body}>
                <div className={styles['body-info']}>
                    {
                        isShowTable ? (
                            <Tabs 
                                options={tabsOptions}
                                onUpdateTab={onUpdateTab}
                            />
                        ) : (
                            <List
                                data={tableData}
                                colors={COLORS}
                                onDelete={onDeleteItem}
                            />
                        )
                    }
                    
                    {
                        isShowTable && (
                            <Table
                                columns={tableColumns}
                                data={tableData}
                            />
                        )
                    }
                    {
                        tableLoading && (
                            <div className={styles['body-info-loading']}/>
                        )
                    }
                </div>
                <div className={styles['body-info']}>
                    <Chart
                        baseData={chartOptions}
                        loading={chartLoading}
                    />  
                    {
                        tableLoading && (
                            <div className={styles['body-info-loading']}/>
                        )
                    }
                </div>
                      
            </div>
        </div>
    )
};

const Tabs = ({options, onUpdateTab}) => {
    return (
        <div>
            <div className={styles.tabs}>
                {
                    options.map(({key, isActive, label}, index) => (
                        <button
                            key={key}
                            className={`${styles['tabs-item']} ${isActive ? styles['tabs-item-active'] : ''}`}
                            onClick={() => onUpdateTab(key)}
                            tabIndex="0"
                            role="button"
                        >
                            {label}
                        </button>
                    ))
                }
            </div>
        </div>
    )
};

const List = ({data, colors, onDelete}) => {
    return (
        <div className={styles.list}>
            {
                data.map((item, index) => (
                    <div 
                        className={styles['list-item']}
                        key={item.LegalName}
                    >
                        <div 
                            className={styles['list-item-key']} 
                            style={{backgroundColor: colors[index]}}
                        />
                        <div>
                            {item.LegalName} 
                            {/* <span className={styles['list-item-number']}> 12.54%</span> */}
                        </div>
                        {
                            index !== 0 && (
                                <img 
                                    className={styles['list-item-close']}
                                    src={closeIcon} 
                                    alt="close"
                                    onClick={() => onDelete(item)}
                                />
                            )
                        }
                        
                    </div>
                ))
            }
        </div>
    )
}

export default ChartContainer;