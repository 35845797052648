import { get, divide, isNumber, isEmpty, round } from 'lodash';
import countryData from './country-code-dicts.json';
import industrialIcon from '../assets/details/industrial.svg';
import technologyIcon from '../assets/details/technology.svg';
import cyclicalIcon from '../assets/details/cyclical.svg';
import utilitiesIcon from '../assets/details/utilities.svg';
import defensiveIcon from '../assets/details/defensive.svg';
import materialsIcon from '../assets/details/materials.svg';
import financialIcon from '../assets/details/financial.svg';
import realIcon from '../assets/details/real.svg';
import healthcareIcon from '../assets/details/healthcare.svg';
import communicationIcon from '../assets/details/communication.svg';
import energyIcon from '../assets/details/energy.svg';
import nonIcon from '../assets/details/non.svg';
import dayjs from 'dayjs';

export const dividendPayingFrequencyEnum = {
    '1': 'Annually',
    '2': 'Semi-annually',
    '3': 'Quarterly',
    '4': 'Bi-monthly',
    '5': 'Monthly',
    '6': 'Semi-monthly',
    '7': 'Weekly',
    '8': 'Daily'
}

export const sectorEnum = {
    '101': 'Basic Materials',
    '102': 'Consumer Cyclical',
    '103': 'Financial Services',
    '104': 'Real Estate',
    '205': 'Consumer Defensive',
    '206': 'Healthcare',
    '207': 'Utilities',
    '308': 'Communication Services',
    '309': 'Energy',
    '310': 'Industrials',
    '311': 'Technology',
    '99': 'Non-Categorised'
};
export const sectorIcons = {
    '101': materialsIcon,
    '102': cyclicalIcon,
    '103': financialIcon,
    '104': realIcon,
    '205': defensiveIcon,
    '206': healthcareIcon,
    '207': utilitiesIcon,
    '308': communicationIcon,
    '309': energyIcon,
    '310': industrialIcon,
    '311': technologyIcon,
    '99': nonIcon
};

export const getCountry = (code) => {
    for( let i = 0; i < countryData.length; i++) {
        const item = countryData[i];
        if (code === item.code) {
            return item.name
        }
    }
};

export const returnFloat = (data) => {
    let value = data;
    const xsd = value.toString().split('.');

    if(xsd.length === 1){
        value = value.toString() + '.00';
        return value;
    }
    if(xsd.length > 1){
        if(xsd[1].length<2){
            value = value.toString()+ '0';
        }
        return value;
    }
};

export const getStrDate = (dateStr, format = 'dd/mm/yyyy') => {
    if (isEmpty(dateStr)) {
        return ''
    }
    const date = new Date(dateStr);
    const year = date.getFullYear(date);
    const month = date.getMonth(date) + 1;
    const day = date.getDate(date);
    const str = format
        .replace(/dd/, ('0' + day).slice(-2))
        .replace(/yyyy/, year)
        .replace(/mm/, ('0' + month).slice(-2))
    
    return str
};

export const getFundSizeValue = (securities = {}) => {
    const dayEndValue = get(securities, 'FundNetAssetValues[0].DayEndValue');
    const currencyId = get(securities, 'FundNetAssetValues[0].CurrencyId')
    return `${isNumber(dayEndValue) ? divide(dayEndValue, 1000000).toFixed(2) : ''} ${currencyId}`;
};

export const getFundManager = (securities) => {
    try {
        const { GivenName, FamilyName } = securities.ManagerList.filter(s =>s.DisplayPreference === 1)[0];
        
        return `${GivenName} ${FamilyName}`;
    }catch(err) {
        // console.log(err);
        return '-'
    }
};

export const getCurrentDate = () => {
    return getStrDate(dayjs().subtract(1, 'day'));
};

export const getPreMonthLastDate = () => {
    const date = new Date();
    let year = date.getFullYear(date);
    let month = date.getMonth(date);

    if (month == 0) {
        month = 12
        year = year - 1
    }
    if (month < 10) {
        month = month
    }
    const newDate = new Date(year, month, 0);

    return getStrDate(`${year}/${month}/${newDate.getDate()}`);
};

export const getGrowItemValues = (data, key) => {
    let status = false;
    const values = [
        get(data, `${key}.YR_ReturnM12_4`), get(data, `${key}.YR_ReturnM12_3`),
        get(data, `${key}.YR_ReturnM12_2`), get(data, `${key}.YR_ReturnM12_1`),
        get(data, `${key}.ReturnM0_ME`) || '-'
    ];

    for (let i = 0; i < values.length; i++) {
        const item = values[i];
        if (isNumber(item)) {
            status = true;
            break;
        }
    }
    const currentValues = values.map(item => {
        if (isNumber(item)) {
            status = true;
            return returnFloat(round(item, 2))
        }
        return false
    })

    return status ? currentValues : false;
};

export const getTrailingItemsValues = (data, key) => {
    let name = '';
    switch (key) {
        case 'ytd':
            name = 'ReturnM0';
            break;
        case '1Year':
            name = 'ReturnM12';
            break;
        case '3Year':
            name = 'ReturnM36UA';
            break;
        case '5Year':
            name = 'ReturnM60UA';
            break;
    }
    const fund = get(data, `detail.${name}`);
    const category =  get(data, `category.${name}`);
    const benchmark = get(data, `benchmark.${name}`);
    return [
        isNumber(fund) ? returnFloat(fund) : '-',
        isNumber(category) ? returnFloat(category) : '-',
        isNumber(benchmark) ? returnFloat(benchmark) : '-'
    ]
};

export const getAssetAllocationValue = (data, index) => {
    const asset = (get(data, 'securities.Portfolios[0].AssetAllocations') || []).filter(({SalePosition}, index, arr) => {
        for (let i = 0; i < 3; i++) {
            const item = arr[i];
            if (SalePosition === item.SalePosition && index === i) {
                return true
            }
        }
        return false
    });
    return isEmpty(asset) ? ['-', '-', '-'] : asset.map((item) => {
        const value = get(item, `BreakdownValues[${index}].Value`)
        return isNumber(value) ? returnFloat(round(value, 2)) : '-'
    });
};

export const getTopStockRegionsValue = (data) => {
    const typeEnum = {
        '1': 'United States (North America)',
        '2': 'Canada (North America)',
        '3': 'Latin America',
        '4': 'United Kingdom',
        '5': 'Eurozone',
        '6': 'Europe - ex Euro',
        '7': 'Europe - Emerging',
        '8': 'Africa',
        '9': 'Middle East',
        '10': 'Japan',
        '11': 'Australasia',
        '12': 'Asia - Developed',
        '13': 'Asia - Emerging'
    };
    const regionalExposure = (get(data, 'securities.Portfolios[0].RegionalExposure') || []).filter(({SalePosition}) => {
        return SalePosition === 'N'
    })[0] || {};
    if (isEmpty(regionalExposure.BreakdownValues)) return [];

    const breakdownValues = regionalExposure.BreakdownValues.slice(0, 13).sort((a, b) => {
        if (a.Value < b.Value) {
            return 1
        }else if (a.Value > b.Value) {
            return -1
        }else {
            return 0
        }
    }).slice(0, 5);

    if (isEmpty(breakdownValues)) return [];

    return [{
        key: 'topStockRegionsHeader',
        label: 'Region',
        value: '%',
        isHeader: true,
    }].concat(breakdownValues.map(({Type, Value}) => {
        return {
            key: `${Type}-${typeEnum[Type]}`,
            label: typeEnum[Type],
            value: returnFloat(round(Value, 2))
        }
    }))
};

export const getStockSectorValue = (data) => {
    const globalStockSectorBreakdown = (get(data, 'securities.Portfolios[0].GlobalStockSectorBreakdown') || []).filter(({SalePosition}) => {
        return SalePosition === 'N'
    })[0] || {};
    if (isEmpty(globalStockSectorBreakdown.BreakdownValues)) return [];

    const breakdownValues = globalStockSectorBreakdown.BreakdownValues.sort((a, b) => {
        if (a.Value < b.Value) {
            return 1
        }else if (a.Value > b.Value) {
            return -1
        }else {
            return 0
        }
    }).slice(0, 5).filter(({Value}) => Value >= 0)

    return [{
        key: 'stockSector',
        label: 'Stock Sector',
        value: '%',
        isHeader: true,
    }].concat(breakdownValues.map(({Type, Value}) => {
        return {
            key: `${Type}-${sectorEnum[Type]}`,
            label: sectorEnum[Type],
            value: returnFloat(round(Value > 100 ? 100 : Value, 2)),
            icon: sectorIcons[Type],
        }
    }))
};

export const getFixedIncomeSectorValue = (data) => {
    const typeEnum = {
        '1010': 'Government',
        '1020': 'Government Related',
        '3020': 'Convertible',
        '3030': 'Corporate Bond',
        '3040': '-',
        '4040': '-',
        '5010': 'Cash & Equivalents',
        '6010': '-',
        '6020': 'Future/Forward',
        '6030': '-'
    };
    const globalBondSectorBreakdownLevel2 = (get(data, 'securities.Portfolios[0].GlobalBondSectorBreakdownLevel2') || []).filter(({SalePosition}) => {
        return SalePosition === 'N'
    })[0] || {};
    if (isEmpty(globalBondSectorBreakdownLevel2.BreakdownValues)) return [];

    const breakdownValues = globalBondSectorBreakdownLevel2.BreakdownValues.sort((a, b) => {
        if (a.Value < b.Value) {
            return 1
        }else if (a.Value > b.Value) {
            return -1
        }else {
            return 0
        }
    }).slice(0, 5);
    
    return [{
        key: 'fixedIncomeSector	',
        label: 'Fixed Income Sector',
        value: '%',
        isHeader: true,
    }].concat(breakdownValues.map(({Type, Value}) => {
        return {
            key: `${Type}-${typeEnum[Type]}`,
            label: typeEnum[Type],
            value: returnFloat(round(Value, 2))
        }
    }))
};

export const getTopHoldingsValue = (data, fundAssetClassId) => {
    const portfolioHoldings = (get(data, 'securities.Portfolios[0].PortfolioHoldings') || []).sort((a, b) => {
        if (a.Weighting < b.Weighting) {
            return 1
        }else if (a.Weighting > b.Weighting) {
            return -1
        }else {
            return 0
        }
    }).slice(0, 5);

    return [{
        isHeader: true,
        label: 'Name',
        value: '%',
        desc: (fundAssetClassId === '$BCG$ALLOC' || fundAssetClassId === '$BCG$EQUTY') ? 'Sector' : 'Country',
        isShowCountry: false,
        fundAssetClassId
    }].concat(portfolioHoldings.map((item) => {
        const { ShareChange, NumberOfShare, GlobalSectorId, CountryId, SecurityName, Weighting } = item;
        const sector = GlobalSectorId ? sectorEnum[GlobalSectorId] : 'Not Classified';
        const status = ShareChange ? ShareChange === NumberOfShare ? 'new' : ShareChange <  0 ? 'decrease' : 'increase' : false;

        return {
            ...item,
            desc: sector,
            counter: getCountry(CountryId),
            isShowCountry: !(fundAssetClassId === '$BCG$ALLOC' || fundAssetClassId === '$BCG$EQUTY'),
            label: SecurityName,
            value: returnFloat(round(Weighting, 2)),
            fundAssetClassId,
            status
        }
    }))
} 