import React from 'react';
import Layout from '../../component/Layout/Layout';
import { isEmpty, get, isBoolean, round, isNumber, fromPairs } from 'lodash';
import { getGuid } from '../../utils/utils';
import { returnFloat } from '../../utils/details';
import { getParams, filterOptions, validateHoldingsOptions, getPDFLink, getPortfolioLink } from '../../utils/xray';
import { useSearchParams } from 'react-router-dom';
import Holdings from './Holdings';
import AssetAllocation from './AssetAllocation/AssetAllocation';
import StockRegions from './StockRegions/StockRegions';
import StockSectors, { Indicator } from './StockSectors';
import PerformanceGraph from './PerformanceGraph/PerformanceGraph';
import Info from './Info';
import PortfolioHoldings from './PortfolioHoldings/PortfolioHoldings';
import backIcon from '../../assets/xray/back.svg';
import comparingIcon from '../../assets/xray/comparing.svg';
import pdfIcon from '../../assets/xray/pdf.svg'
import styles from './portfolio-x-ray.module.css';

const { useState, useMemo, useEffect } = React;

const convertHoldings = (holdings = []) => {
    const length = holdings.length;
    const base = 100 / length ;
	const result = [];
	for(var i = 0; i< length - 1; i++){
		result.push(Math.floor(base));
	}
    result.push(100 - result.reduce((preVal, currVal) => preVal + currVal, 0));
    
    return holdings.map((item, index) => {
        return {
            ...item,
            weight: result[index]
        }
    });
}

const PortfolioXray = ({setLocale}) => {

    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [isShowHoldings, setIsShowHoldings] = useState(true);
    const [holdingsOptions, setHoldingsOptions] = useState([]);
    const [benchmarkData, setBenchmarkData] = useState([{id: null, name: 'Select Benchmark'}]);
    const [selectBenchmarkId, setSelectBenchmarkId] = useState(null);
    const [isUpdateBenchmarkId, setIsUpdateBenchmarkId] = useState(false)

    const stockSectorsValue = useMemo(() => {
        const globalStockSector = get(data, 'breakdowns.globalStockSector');
        
        if (isEmpty(globalStockSector)) return [];

        const filter = globalStockSector.filter(({salePosition}) => {
            return salePosition === 'N';
        }) || [];

        return get(filter, '0.values') || {}
    }, [data]);

    const onRequest = async (params, benchmarkId, isAddNullHoldings = false, nextHoldings) => {
        setLoading(true);
        try {
            const response = await fetch('api2/v1/agent/compare', { //xray/json?  
                method: 'POST',
                headers: {
                    // 'Accept': 'application/json',
                    'Accept': 'application/text',
                    'Content-Type': 'application/text',
                },
                body: getParams({
                    type: 3,
                    currencyId: 'BAS',
                    benchmarkId,
                    ...params,
                })
            });
            const res = await response.json();

            if (get(res, 'status.statusCode') === 200) {
                setData(res);
                const holdings = isAddNullHoldings ? convertHoldings(res.holdings || []) : res.holdings || [];
                const options = isAddNullHoldings ? [].concat(holdings).concat([{
                    isSelect: false, 
                    guid: getGuid()
                }]) : holdings;
                setHoldingsOptions(filterOptions(options, nextHoldings ? nextHoldings : holdingsOptions));
            }
            setLoading(false)
        }catch(err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onRequestBenchmark = async() => {
        try {
            const response = await fetch(`api2/v1/agent/security/screener?pageSize=999&outputType=json&universeIds=CAASI%24%24ALL_4113&securityDataPoints=SecId%7CName&languageId=en-GB`)
            const res = await response.json();
            const rows = (get(res, 'rows') || []).map(item => {
                return {
                    name: item.Name,
                    id: item.SecId
                }
            });
            setBenchmarkData([].concat(benchmarkData).concat(rows))
        }catch (err) {
            console.log(err);
        }
    };

    const onHoldingsChange = (options, nextHoldings) => {
        const holdings = options.filter(({isSelect}) => {
            return isBoolean(isSelect) ? isSelect : true
        }).map(({securityId, secId, name, weight, securityType}) => ({
            identifier: securityId || secId,
            securityType: securityType || null,
            holdingType: 'weight', // 后续支持金额等其它参数
            identifierType: 'MSID',
            name,
            weight
        }));
        onRequest({
            type: 2,
            currencyId: 'BAS',
            holdings
        }, selectBenchmarkId, false, nextHoldings);
    };

    const onSelectBenchmarkChange = ({id}) => {
        setSelectBenchmarkId(id);
    };

    const onClickPortfolio = () => {
        window.open(getPortfolioLink('en-GB', selectBenchmarkId, data.holdings), '_blank')
    };

    const onClickPdf = () => {
        window.open(getPDFLink('en-GB', selectBenchmarkId, data.holdings), '_blank')
    };

    useEffect(() => {
        const ids = searchParams.get('ids');
        // const holdings = ids.split(',');
        // console.log(holdings);
        onRequest({
            // holdings: ids.split(',').map(item => {
            //     return {
            //         identifier: item,
            //         identifierType: 'MSID',
            //         amount: 1000
            //     }
            // })
            holdings: isEmpty(ids) ? [] : ids.split(',').map(item => {
                return {
                    identifier: item,
                    identifierType: 'MSID',
                    amount: 1000
                }
            })
        }, selectBenchmarkId, true);
    }, [searchParams]);

    useEffect(() => {
        if (isUpdateBenchmarkId && !(validateHoldingsOptions(holdingsOptions).isError)) {

            const holdings = holdingsOptions.filter(({isSelect}) => {
                return isBoolean(isSelect) ? isSelect : true
            }).map(({securityId, secId, name, weight, securityType}) => ({
                identifier: securityId || secId,
                securityType: securityType || null,
                holdingType: 'weight', // 后续支持金额等其它参数
                identifierType: 'MSID',
                name,
                weight
            }));
            onRequest({
                type: 2,
                currencyId: 'BAS',
                holdings
            }, selectBenchmarkId, false);

            return
        }

        setIsUpdateBenchmarkId(true)
    }, [selectBenchmarkId]);

    useEffect(() => {
        onRequestBenchmark()
    }, []);

    return (
        <Layout
            loading={loading}
            activePageKey="allocation"
            activePageChildrenKey="portfolioXray"
            setLocale={setLocale}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Portfolio X-Ray</h1>
                    <div className={styles.action}>
                        <div className={`${styles['action-item']} ${styles['action-item-holdings']}`}>
                            <div 
                                className={styles['action-item-hide']}
                                onClick={() => setIsShowHoldings(!isShowHoldings)}
                                style={{
                                    transform: !isShowHoldings && 'rotate(180deg)'
                                }}
                                role="button"
                                tabIndex="1"
                            >
                                <img src={backIcon} alt='hide'/>
                            </div>
                            <span>{isShowHoldings ? 'Hide' : 'View'} Holdings & Benchmark</span>
                        </div>
                        <div className={`${styles['action-item']} ${styles['action-item-button']}`}>
                            <div
                                className={styles['action-item-comparison']}
                                onClick={onClickPortfolio}
                            >
                                <img src={comparingIcon} alt='comparison'/>
                                <span>Portfolio comparison</span>
                            </div>
                            <div
                                className={styles['action-item-pdf']}
                                onClick={onClickPdf}
                            >
                                <img src={pdfIcon} alt='pdf'/>
                                <span>PDF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.body}>
                    <Holdings 
                        options={holdingsOptions}
                        isShow={isShowHoldings}
                        setOptions={setHoldingsOptions}
                        onChangeRequest={onHoldingsChange}
                        benchmarkData={benchmarkData}
                        onSelectBenchmarkChange={onSelectBenchmarkChange}
                        selectBenchmarkId={selectBenchmarkId}
                    />
                    <div className={styles['body-info']}>
                        <div className={styles['body-info-chart']}>
                            <AssetAllocation
                                data={get(data, 'breakdowns.assetAllocation') || []}
                                isShowHoldings={isShowHoldings}
                            />
                            <StockRegions 
                                data={get(data, 'breakdowns.regionalExposure') || []}
                            />
                        </div>
                        <StockSectors 
                            value={stockSectorsValue}
                            isShowHoldings={isShowHoldings}
                        />
                        <PerformanceGraph 
                            baseData={get(data, 'historicalPerformanceSeries') || []}
                            baseBenchmarkData={get(data, 'benchmark[0].historicalPerformanceSeries') || []}
                            isShowHoldings={isShowHoldings}
                        />
                        <Info 
                            data={data}
                            isShowHoldings={isShowHoldings}
                        />
                        <div className={styles['body-info-list']}>
                            <StockFeesList 
                                stockStatsData={data.equityStatistics}
                                feesData={data.fees}
                            />
                            <Trailing data={data.trailingPerformance}/>
                        </div>
                        <PortfolioHoldings 
                            data={data}
                            isShowHoldings={isShowHoldings}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
};

const StockFeesList = ({
    stockStatsData = {},
    feesData = {}
}) => {

    const getValue = (key, data = stockStatsData, isReturnNumber = true) => {

        if (isReturnNumber) {
            return returnFloat(round(get(data, `${key}.value`) || 0, 2))
        }

        const value = get(data, `${key}.value`);
        return isNumber(value) ? returnFloat(round(value, 2)) : '-';
    };

    const prospectiveOptions = useMemo(() => {
        return {
            label: 'Price/Prospective Earnings',
            value: getValue('prospectiveEarningsYield')
        }
    }, [stockStatsData, getValue]);

    const stockStatsOptions = useMemo(() => {
        return [
            {
                key: 'prospectiveBookValueYield',
                label: 'Price/Book Ratio',
                value: getValue('prospectiveBookValueYield')
            },
            {
                key: 'prospectiveRevenueYield',
                label: 'Price/Sales Ratio',
                value: getValue('prospectiveRevenueYield')
            },
            {
                key: '',
                label: 'Price/Cash Flow Ratio',
                value: getValue('prospectiveCashFlowYield')
            },
        ]
    }, [stockStatsData, getValue]);

    const feesOptions = useMemo(() => {
        return [
            {
                key: 'ongoingCharge',
                label: 'Portfolio Weighted Ongoing Charge',
                value: getValue('ongoingCharge', feesData, false)
            },
            {
                key: 'managementFee',
                label: 'Portfolio Weighted Management Fee',
                value: getValue('managementFee', feesData, false)
            }
            
        ]
    }, [feesData, getValue]);

    return (
        <div className={styles['list-container']}>
            <div className={styles['list-top']}>
                <div className={styles['top-header']}>
                    <div className={styles['top-header-info']}>
                        <span className={styles['top-header-info-title']}>Stock Stats</span>
                        <span className={styles['top-header-info-subtitle']}>{prospectiveOptions.label}</span>
                        <span className={styles['top-header-info-value']}>{prospectiveOptions.value}%</span>
                    </div>
                    <div className={styles['top-header-indicator']}>
                        <Indicator value={prospectiveOptions.value} color="#056dae"/>
                    </div>
                </div>
                <div className={styles['list-top-tips']}>
                    <span>Portfolio,  %</span>
                </div>
                <div className={styles['top-body']}>
                    {
                        stockStatsOptions.map(({key, label, value}) => (
                            <div className={styles['list-item']} key={key}>
                                <span>{label}</span>
                                <span>{value}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles['list-bottom']}>
                <span className={styles['top-header-info-title']} style={{marginBottom: 14}}>Fees & Expenses</span>
                {
                    feesOptions.map(({key, label, value}) => (
                        <div 
                            className={styles['list-item']} 
                            key={key}
                        >
                            <span>{label}</span>
                            <span style={{marginLeft: 6}}>{value}{value !== '-' && '%'}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

const Trailing = ({
    data = {}
}) => {

    const options = useMemo(() => {
        const values = (get(data, '0.returns') || []).map(({timePeriod, value}) => {
            return [timePeriod, value]
        });
        const valuesObj = fromPairs(values) || {};

        return [
            {
                key: 'M0-trailing',
                label: 'YTD',
                value: isNumber(valuesObj['M0']) ? returnFloat(round(valuesObj['M0'], 2)) : '-' 
            },
            {
                key: 'M1-trailing',
                label: '1 Month',
                value: isNumber(valuesObj['M1']) ? returnFloat(round(valuesObj['M1'], 2)) : '-' 
            },
            {
                key: 'M3-trailing',
                label: '3 Month',
                value: isNumber(valuesObj['M3']) ? returnFloat(round(valuesObj['M3'], 2)) : '-' 
            },
            {
                key: 'M6-trailing',
                label: '6 Month',
                value: isNumber(valuesObj['M6']) ? returnFloat(round(valuesObj['M6'], 2)) : '-' 
            },
            {
                key: 'M12-trailing',
                label: '1 Year',
                value: isNumber(valuesObj['M12']) ? returnFloat(round(valuesObj['M12'], 2)) : '-' 
            },
            {
                key: 'M36-trailing',
                label: '3 Years Annualised',
                value: isNumber(valuesObj['M36']) ? returnFloat(round(valuesObj['M36'], 2)) : '-' 
            },
            {
                key: 'M60-trailing',
                label: '5 Years Annualised',
                value: isNumber(valuesObj['M60']) ? returnFloat(round(valuesObj['M60'], 2)) : '-' 
            },
            {
                key: 'M120-trailing',
                label: '10 Years Annualised',
                value: isNumber(valuesObj['M120']) ? returnFloat(round(valuesObj['M120'], 2)) : '-' 
            },
        ]
    }, [data]);

    return (
        <div className={styles['trailing']}>
            <span className={styles['top-header-info-title']}>
                Trailing Returns
            </span>
            <div className={styles['list-top-tips']} style={{marginTop: 10}}>
                <span>Portfolio,  %</span>
            </div>
            {
                options.map(({key, label, value}) => (
                    <div 
                        className={styles['list-item']} 
                        key={key}
                    >
                        <span>{label}</span>
                        <span>{value}</span>
                    </div>
                ))
            }
        </div>
    )
};

export default PortfolioXray;