import React from 'react';
import Portfolio from './Overview/Portfolio';
import Chart from './Overview/Chart';
import { get, isEmpty, round, isNumber } from 'lodash';
import { 
    getStrDate, getFundSizeValue, getFundManager,
    getCurrentDate, getPreMonthLastDate, getGrowItemValues,
    getTrailingItemsValues, returnFloat, dividendPayingFrequencyEnum
} from '../../utils/details';
import styles from './overview.module.css';
import investmentIcon from '../../assets/details/investment.png';
import keyIcon from '../../assets/details/key.png';
import growIcon from '../../assets/details/grow.png';
import portfolioIcon from '../../assets/details/portfolio.svg';
import starIcon from '../../assets/details/star.svg';
import ratingIcon from '../../assets/details/rating.svg';
import activeRatingIcon from '../../assets/details/rating-active.svg';
// import trailingIcon from '../../assets/details/trailing.svg';

const { useMemo } = React;
const OverView = ({
    data
}) => {

    const investment = useMemo(() => {
        return get(data, 'securities.InvestmentStrategy') || ''
    }, [data]);

    const keyState = useMemo(() => {
        if (isEmpty(data)) return [] ;

        const dividend = get(data, 'detail.DividendFrequency');
        const state = [
            {
                key: 'fundCompany',
                label: 'Fund Company',
                value: get(data, 'detail.ProviderCompanyName') || '-'
            },
            {
                key: 'currency',
                label: 'Currency',
                value: get(data, 'detail.PriceCurrency') || '-'
            },
            {
                key: 'inceptionDate',
                label: 'Inception Date',
                value: getStrDate(get(data, 'detail.InceptionDate')) || '-'
            },
            {
                key: 'fundSize',
                label: `Fund Size (mil) ${getStrDate(get(data, 'securities.FundNetAssetValues[0].DayEndDate'))}`,
                value: getFundSizeValue(get(data, 'securities'))
            },
            {
               key: 'morningstarCategory',
               label: 'Morningstar® Category',
               value: get(data, 'detail.CategoryName') || '-'
            },
            {
                key: 'pricingFrequency',
                label: 'Pricing Frequency',
                value: get(data, 'detail.PricingFrequencyName')
            },
            {
                key: 'dividend',
                label: 'Dividend Paying Frequency',
                value: dividend ? dividendPayingFrequencyEnum[dividend] : '-'
            },
            {
                key: 'ISIN',
                label: 'ISIN',
                value: get(data, 'detail.ISIN') ||'-'
            },
            {
                key: 'morningstarRating',
                label: 'Morningstar Rating™',
                value: isNumber(get(data, 'detail.Rating')) ? get(data, 'detail.Rating') : 'Not Rated'
            },
            {
                key: 'morningstarSustainabilityRating',
                label: 'Morningstar Sustainability Rating™',
                value: get(data, 'detail.SustainabilityRank')
            },
            {
                key: 'fundManager',
                label: 'Fund Manager',
                value: getFundManager(get(data, 'securities'))
            }
        ]

        const yield_M12 = get(data, 'detail.Yield_M12');
        const id = get(data, 'securities.CategoryBroadAssetClass.Id');

        if ((id === '$BCG$ALLOC' || id === '$BCG$FXINC') && yield_M12) {
            state.splice(7, 0, {
                key: 'yield_M12',
                label: '12 Month Yield',
                value: isNumber(yield_M12) ? `${returnFloat(round(yield_M12, 2))}%` : '-'
            })
        }

        return state;
    }, [data]);

    const currentCurrency = useMemo(() => {
        return get(data, 'detail.PriceCurrency') || '';
    }, [data]);

    const currentDate = useMemo(() => {
        return getCurrentDate()
    }, [getCurrentDate]);

    const preMonthLastDate = useMemo(() => {
        return getPreMonthLastDate();
    }, [getPreMonthLastDate]);

    const portfolioDate = useMemo(() => {
        return getStrDate(get(data, 'securities.Portfolios[0].Date'));
    }, [data]);

    const growthData = useMemo(() => {
        if (isEmpty(data)) return [];

        const year = new Date().getFullYear();
        return [
            {
                isDate: true,
                key: 'growthDataKey',
                label: '',
                values: Array.from(Array(5), (e, index) => year - 5 + index + 1)
            },
            {
                key: 'fund',
                label: 'Fund',
                values: getGrowItemValues(data, 'detail')
            },
            {
                key: 'category',
                label: 'Category',
                values: getGrowItemValues(data, 'category')
            },
            {
                key: 'benchmark',
                label: 'Benchmark',
                values: getGrowItemValues(data, 'benchmark')
            },
        ]
    }, [data]);

    const trailingData = useMemo(() => {
        if (isEmpty(data)) return [];

        let headerValues = ['Fund', 'Category', 'Benchmark'];
        let ytdValues = getTrailingItemsValues(data, 'ytd');
        let oneYear = getTrailingItemsValues(data, '1Year');
        let threeYear = getTrailingItemsValues(data, '3Year');
        let fiveYear = getTrailingItemsValues(data, '5Year');

        headerValues.forEach((name, index) => {
            if (ytdValues[index] === '-' && oneYear[index] === '-' && threeYear[index] === '-' && fiveYear[index] === '-') {
                headerValues[index] = null;
                ytdValues[index] = null;
                oneYear[index] = null;
                threeYear[index] = null;
                fiveYear[index] = null;
            }
        });

        return [
            {
                isHeader: true,
                key: 'header',
                label: '',
                values: headerValues.filter(item => item !== null) 
            },
            {
                key: 'ytd',
                label: 'YTD',
                values: ytdValues.filter(item => item !== null) 
            },
            {
                key: '1Year',
                label: '1 Year',
                values: oneYear.filter(item => item !== null) 
            },
            {
                key: '3Year',
                label: '3 Year',
                values: threeYear.filter(item => item !== null) 
            },
            {
                key: '5Year',
                label: '5 Year',
                values: fiveYear.filter(item => item !== null) 
            },
        ];
    }, [data]);

    const fundAssetClassInfo = useMemo(() => {
        return {
            id: get(data, 'securities.CategoryBroadAssetClass.Id'),
            name: get(data, 'securities.CategoryBroadAssetClass.Name')
        };
    }, [data]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles['header-item']}>
                    <div>
                        <div className={styles['title-group']}>
                            <h2 className={styles['title']}>
                                <img src={investmentIcon}/>
                                <span>Investment Objective</span>
                            </h2>
                        </div>
                        <span className={styles['header-item-investment']}>
                            {investment}
                        </span>
                    </div>
                    <div>
                        <div className={styles['title-group']} style={{marginTop: '24px'}}>
                            <h2 className={styles['title']}>
                                <img src={keyIcon}/>
                                <span>Key Stats</span>
                            </h2>
                        </div>
                        <div className={styles['key']}> 
                            {
                                keyState.map(({key, label, value}) => (
                                    <div 
                                        className={styles['key-item']}
                                        key={`keyState-${key}`}
                                    >
                                        <span className={styles['key-item-group']}>{label}</span>
                                        {
                                            key === 'morningstarRating' || key === 'morningstarSustainabilityRating' ? (
                                                <div className={styles['key-item-group']}>
                                                    {
                                                        key === 'morningstarRating' && !(isNumber(value)) ? (
                                                            <span>{value}</span>
                                                        ) : (
                                                            <StarBox 
                                                                value={isNumber(value) ? value : 0 }
                                                                id={key}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <span className={styles['key-item-group']}>{value}</span>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className={styles['header-item']}>
                    <div style={{flex: '1', display: 'flex', flexDirection: 'column'}}>
                        <div className={styles['title-group']}>
                            <h2 className={styles['title']}>
                                <img src={growIcon}/>
                                <span>Growth Chart ({currentCurrency})</span>
                            </h2>
                            <span>{preMonthLastDate}</span>
                        </div>
                        <div className={styles['header-chart']}>
                            {
                                !(isEmpty(data)) && (
                                    <Chart data={data}/>
                                )
                            }
                        </div>
                        <div className={styles['key']}>
                            <span className={styles['key-tips']}>Annualized Growth</span>
                            {
                                growthData.map(({key,label, values, isDate}) => (
                                    values && (
                                        <div
                                            className={`${styles['key-item']} ${isDate && styles['key-item-date']}`}
                                            key={`growthData-${key}`}
                                        >
                                            <span className={styles['key-item-group']} style={{minWidth: '75px'}}>
                                                {label}
                                            </span>
                                            {
                                                values.map((item, index) => (
                                                    <div key={`growthData-${key}-${index}`} className={styles['key-item-group']}>
                                                        {item}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <div className={styles['title-group']} style={{marginTop: '24px'}}>
                            <h2 className={styles['title']}>
                                <img src={growIcon}/>
                                <span>Trailing Returns % ({currentCurrency})</span>
                            </h2>
                            <span>{currentDate}</span>
                        </div>
                        <div className={styles['key']}>
                            {
                                trailingData.map(({key,label, values,isHeader}) => (
                                    <div
                                        className={`${styles['key-item']} ${isHeader && styles['key-item-date']}`}
                                        key={`trailingData-${key}`}
                                    >
                                        <span className={styles['key-item-group']}>
                                            {label}
                                        </span>
                                        {
                                            values.map((item, index) => (
                                                <div 
                                                    key={`trailingData-${key}-${index}`} 
                                                    className={`${styles['key-item-group']} ${styles['key-item-group-trailing']}`}
                                                >
                                                    {item}
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                (fundAssetClassInfo.id && fundAssetClassInfo.id !== '$BCG$ALTER') && (
                    <div className={styles.footer}>
                        <div className={styles['title-group']}>
                            <h2 className={styles['title']}>
                                <img src={portfolioIcon}/>
                                <span>Portfolio Profile</span>
                            </h2>
                            <span>{portfolioDate}</span>
                        </div>
                        <Portfolio
                            fundAssetClassId={fundAssetClassInfo.id}
                            fundAssetClassName={fundAssetClassInfo.name}
                            data={data}
                        />
                    </div>
                )
            }
        </div>
    )
}

export const StarBox = ({value, id, isShowNull = false}) => {

    const options = useMemo(() => {
        if (id === 'morningstarRating') {
            return Array.from(Array(value), (e, index) => index + 1)
        }
        
        return Array.from(Array(5), (e, index) => index + 1)
    }, [value, id]);

    return (
        <div className={styles['star-box']}>
            {
                isShowNull && !(isNumber(value)) ? (
                    <span>-</span>
                ) : options.map(item => {
                    if (id === 'morningstarRating') {
                        return (
                            <img 
                                src={starIcon}
                                key={`${item}-${id}-starBox`}
                            />
                        )
                    }
                    return (
                        <img 
                            src={item > value ? ratingIcon : activeRatingIcon}
                            key={`${item}-${id}-starBox`}
                        />
                    )
                })
            }
            {/* {
                options.map(item => {
                    if (id === 'morningstarRating') {
                        return (
                            <img 
                                src={starIcon}
                                key={`${item}-${id}-starBox`}
                            />
                        )
                    }
                    return (
                        <img 
                            src={item > value ? ratingIcon : activeRatingIcon}
                            key={`${item}-${id}-starBox`}
                        />
                    )
                })
            } */}
        </div>
    )
}

export default OverView;