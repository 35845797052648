import React from 'react';
import { AmericaIcon, AsiaIcon, EuropeIcon } from '../../../component/Regions/Regions';
import { get, isEmpty } from 'lodash';
import { Decimal } from 'decimal.js';
import anime from 'animejs';
import styles from './index.module.css';

const { useMemo, useState, useRef } = React;
const StockRegions = ({
    data: baseData,
}) => {

    const getValueColor = (value) => {
        if (value.greaterThanOrEqualTo(50)) {
            return '#05589d'
        }else if (value.greaterThanOrEqualTo(25)) {
            return '#6D96E5'
        }else if (value.greaterThanOrEqualTo(10)) {
            return '#B6CBF2'
        }else {
            return '#DAE6FD'
        }
    };

    const data = useMemo(() => {
        const baseObj = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 99: 0}
        const arr = baseData.filter(item => item.salePosition === 'N');

        if (isEmpty(arr)) return baseObj
        
        return get(arr, '0.values') || baseObj

    }, [baseData]);

    const americasOptions = useMemo(() => {
        const northAmericaValue = new Decimal(data['1']).plus(new Decimal(data['2']));
        const northAmericaColor =  getValueColor(northAmericaValue);

        const latinAmericaValue = new Decimal(data['3']);
        const latinAmericaColor = getValueColor(latinAmericaValue);
        return {
            label: 'Americas',
            key: 'americas',
            value: northAmericaValue.plus(latinAmericaValue),
            colorProps: {
                northAmericaColor,
                northAmericaValue: northAmericaValue.toFixed(2, Decimal.ROUND_DOWN),
                latinAmericaColor,
                latinAmericaValue: latinAmericaValue.toFixed(2, Decimal.ROUND)
            },
            data: [
                {
                    key: 'americas-north-america',
                    label: 'North America',
                    value: northAmericaValue,
                    color: getValueColor(northAmericaValue)
                },
                {
                    key: 'latin-america',
                    label: 'Latin America',
                    value: latinAmericaValue,
                    color: latinAmericaColor
                }
            ]
        }
    }, [data, getValueColor]);

    const europeOptions = useMemo(() => {
        const unitedKingdomValue = new Decimal(data['4']);
        const unitedKingdomColor = getValueColor(unitedKingdomValue);

        const developedValue = new Decimal(data['5']).plus(new Decimal(data['6']));
        const developedColor = getValueColor(developedValue);

        const emergingValue = new Decimal(data['7']);
        const emergingColor = getValueColor(emergingValue);

        const africaValue = new Decimal(data['8']).plus(new Decimal(data['9']));
        const africaColor = getValueColor(africaValue)
        return {
            label: 'Greater Europe',
            key: 'europe',
            value: unitedKingdomValue.plus(developedValue).plus(emergingValue).plus(africaValue),
            colorProps: {
                unitedKingdomColor,
                developedColor,
                emergingColor,
                africaColor,
                unitedKingdomValue: unitedKingdomValue.toFixed(2, Decimal.ROUND_DOWN),
                developedValue: developedValue.toFixed(2, Decimal.ROUND_DOWN),
                emergingValue: emergingValue.toFixed(2, Decimal.ROUND_DOWN),
                africaValue: africaValue.toFixed(2, Decimal.ROUND_DOWN),
            },
            data: [
                {
                    key: 'europe-United-Kingdom',
                    label: 'United Kingdom',
                    value: unitedKingdomValue,
                    color: unitedKingdomColor
                },
                {
                    key: 'europe-Developed',
                    label: 'Europe Developed',
                    value: developedValue,
                    color: developedColor
                },
                {
                    key: 'europe-Emerging',
                    label: 'Europe Emerging',
                    value: emergingValue,
                    color: emergingColor
                },
                {
                    key: 'europe-Africa',
                    label: 'Africa/Middle East',
                    value: africaValue,
                    color: africaColor
                }
            ]
        }
    }, [data, getValueColor]);

    const asiaOptions = useMemo(() => {
        const japanValue = new Decimal(data['10']);
        const japanColor = getValueColor(japanValue);

        const australasiaValue = new Decimal(data['11']); 
        const australasiaColor = getValueColor(australasiaValue);

        const developedValue = new Decimal(data['12']);
        const developedColor = getValueColor(developedValue);

        const emergingValue = new Decimal(data['13']);
        const emergingColor = getValueColor(emergingValue);
        return {
            label: 'Greater Asia',
            key: 'asia',
            value: japanValue.plus(australasiaValue).plus(emergingValue).plus(developedValue),
            colorProps: {
                japanColor,
                australasiaColor,
                developedColor,
                emergingColor,
                japanValue: japanValue.toFixed(2, Decimal.ROUND_DOWN),
                australasiaValue: australasiaValue.toFixed(2, Decimal.ROUND_DOWN),
                developedValue: developedValue.toFixed(2, Decimal.ROUND_DOWN),
                emergingValue: emergingValue.toFixed(2, Decimal.ROUND_DOWN),
            },
            data: [
                {
                    key: 'asia-Japan',
                    label: 'Japan',
                    value: japanValue,
                    color: japanColor
                },
                {
                    key: 'asia-Australasia',
                    label: 'Australasia',
                    value: australasiaValue,
                    color: australasiaColor
                },
                {
                    key: 'asia-Developed',
                    label: 'Asia Developed',
                    value: developedValue,
                    color: developedColor
                },
                {
                    key: 'asia-Emerging',
                    label: 'Asia Emerging',
                    value: emergingValue,
                    color: emergingColor
                },
            ]
        } 
    }, [data, getValueColor]);

    const tabsOptions = useMemo(() => {
        return [
            {
                index: 0,
                key: 'StockRegions-tabs-0',
                Element: () => {
                    return (<RegionsBox options={americasOptions}/>)
                }
            },
            {
                index: 1,
                key: 'StockRegions-tabs-1',
                Element: () => {
                    return (<RegionsBox options={europeOptions}/>)
                }
            },
            {
                index: 2,
                key: 'StockRegions-tabs-2',
                Element: () => {
                    return (<RegionsBox options={asiaOptions}/>)
                }
            }
        ]
    }, [americasOptions, europeOptions, asiaOptions]);

    return (
        <div className={styles.container}>
            <span className={styles.title}>Stock Region</span>
            <StepTabs tabs={tabsOptions}/>
        </div>
    )
};

const RegionsBox = ({options}) => {

    return (
        <div className={styles['regions-box']}>
            <div className={styles['regions-box-img']}>
                {
                    options.key === 'americas' ? (
                        <AmericaIcon {...options.colorProps}/>
                    ) : options.key === 'europe' ? (
                        <EuropeIcon {...options.colorProps}/>
                    ) : (
                        <AsiaIcon  {...options.colorProps}/>
                    )
                }
            </div>
            <div className={styles['regions-box-info']}>
                <div className={styles['regions-box-info-header']}>
                    <span/>
                    <span>Weight,%</span>
                </div>
                <div className={styles['regions-box-info-top']}>
                    <span>{options.label}</span>
                    <span>{options.value.toFixed(2, Decimal.ROUND_DOWN)}</span>
                </div>
                {
                    options.data.map(({key, value, label, color}) => (
                        <div 
                            className={styles['regions-box-info-item']}
                            key={key}
                        >
                            <div className={styles['regions-box-info-item-left']}>
                                <div className={styles['regions-box-info-item-box']} style={{background: color}}/>
                                <span>{label}</span>
                            </div>
                            <span>{value.toFixed(2, Decimal.ROUND_DOWN)}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export const StepTabs = ({
    tabs,
    topStart = 34,
    isLeftAction = true
}) => {
    let start = 0; 
    let moveX = 0;
    let moveDistance = 0;
    let isMoved = false;
    
    const elementRef = useRef(null);

    const [step, setStep] = useState(0);
    const [lastMove, setLastMove] = useState(0)
    const [currentIsMoved, setCurrentIsMoved] = useState(false)

    const actionOptions = useMemo(() => {
        return tabs.map(({key}) => {
            return `${key}-action`
        })
    }, [tabs]);

    const onStartAnimation = (index) => {
        anime({
            targets: elementRef.current,
            keyframes: [
                {
                    translateX: `-${index * 100}%`,
                    duration: 300,
                    easing: 'linear',
                }
            ],
            complete: () => {
                const currentWidth = elementRef.current.offsetWidth || 0;

                setStep(index);
                setLastMove(-(index * currentWidth))
                setCurrentIsMoved(true)
            }
        })
    };

    const onTouchStart = (e) => {
        start = e.changedTouches[0].pageX;
    };

    const onTouchMove = (e) => {
        moveDistance = e.changedTouches[0].pageX - start;
        moveX = (isMoved || currentIsMoved) ? (lastMove + moveDistance) : moveDistance;
        elementRef.current.style = `transform: translateX(${moveX}px);`;
        isMoved = true;
    }

    const onTouchEnd = (e) => {
        const currentWidth = elementRef.current.offsetWidth || 0;

        if(moveX > 0) {
            moveX = 0;
        }

        if(moveX < -(currentWidth * (actionOptions.length - 1))) {
            moveX = -(currentWidth * ((actionOptions.length - 1)));
        }

        if(moveDistance > 0) {
            if(moveX > -(currentWidth - 100)) {
                moveX = 0;
            }else {
                moveX = -(currentWidth);
            }
        }else if(moveDistance < 0) {
            if(moveX < -(currentWidth + 100)) {
                moveX = -(currentWidth * (actionOptions.length - 1));
            }else {
                moveX = -(currentWidth);
            }
        }

        setLastMove(moveX);
        setCurrentIsMoved(true);
        anime({
            targets: elementRef.current,
            keyframes: [
                {
                    translateX: `${moveX}px`,
                    duration: 200,
                    easing: 'linear',
                }
            ],
            complete: () => {  
                actionOptions.map((item, index) => {
                    if (index * currentWidth === Math.abs(moveX)) {
                        setStep(index);
                    }
                })              
                moveDistance = 0;
            }
        })

    };

    return (
        <>
        <div 
            className={styles['tabs-container']}
            ref={elementRef}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
        >
            {
                tabs.map(({Element, index, key}) => (
                    <div 
                        className={styles['tabs-item']}
                        key={key}
                        style={{
                            left: `${index * 100}%`, 
                            top: topStart
                        }}
                    >
                        <Element/>
                    </div>
                ))
            }
        </div>
        <div 
            className={styles['tabs-action']}
            style={{
                width: !isLeftAction && 'auto',
                right: !isLeftAction && 0,
                left: !isLeftAction && 'auto'
            }}
        >
            {
                actionOptions.map((item, index) => (
                    <div 
                        className={styles['tabs-action-circle']} 
                        key={item} 
                        style={{background: step === index && '#000000'}}
                        onClick={() => onStartAnimation(index)}
                    />
                ))
            }
        </div>
        </>
    )
}

export default StockRegions;