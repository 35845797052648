import React from 'react';
import { isEmpty } from 'lodash';
import arrowIcon from '../../assets/select/arrow.png';
import doneIcon from '../../assets/select/done.png';
import styles from './select.module.css';

const { useState, useEffect, useMemo } = React;
const Select = ({
    options = [],
    disabled,
    initKey,
    onChange
}) => {

    const [selectItem, setSelectItem] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    
    const selectLabel = useMemo(() => {
        return selectItem.label
    }, [selectItem]);

    const onClickPanel = () => {
        if (disabled) return;

        setIsOpen(!isOpen);
    };

    const onSelect = (item) => {
        onChange(item);
        setSelectItem(item);
    }

    useEffect(() => {
        if (!initKey || !isEmpty(selectItem)) return

        for (let i = 0; i < options.length; i++){
            const { key } = options[i];

            if (key === initKey) {
                setSelectItem(options[i]);
                return
            }
        }

    }, [initKey, options]);

    return (
        <div 
            className={`${styles.container} ${disabled ? styles['container-disabled'] : ''}`}
            onClick={onClickPanel}
        >
            <span className={styles.label}>{selectLabel}</span>
            <img 
                className={styles['select-img']} 
                src={arrowIcon}
                style={{transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)'}}
                alt={selectLabel}
            />
            <div className={styles.list} style={{display: isOpen ? 'block' : 'none'}}>
            {
                options.map((item) => {
                    const {key, label} = item;
                    const isActive = key === selectItem.key;
                    return (
                        <div 
                            key={key}
                            className={styles['list-item']}
                            onClick={() => onSelect(item)}
                            style={{color: isActive && '#999999'}}
                        >
                            <span>{label}</span>
                            {
                                isActive && (<img className={styles['list-item-img']} src={doneIcon} alt={label}/>)
                            }
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
};

export default Select;