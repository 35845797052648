import React from 'react';
import { isEmpty, uniq } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import Layout from '../../component/Layout/Layout';
import Holdings from './Holdings';
import Info from './Info';
import styles from './fund-comparison.module.css';

const { useState, useMemo, useEffect } = React;

const FundComparison = ({
    setLocale
}) => {
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [ids, setIds] = useState([]);
    const [data, setData] = useState([]);

    const onRequest = async (params) => {
        setLoading(true);
        try {
            const response = await fetch(encodeURI(`api2/v1/agent/security/detail?ids=${params}`), {
                method: 'GET',
            });
            const res = await response.json();
            setData(res);
            setLoading(false);
        } catch(err) {
            setLoading(false);
            console.log(err)
        }
    };

    useEffect(() => {
        if (ids.length === 0) return;

        onRequest(ids.join('|'));
    }, [ids]);

    useEffect(() => {
        const paramsIds = searchParams.get('ids');
        if (isEmpty(paramsIds)) return
        
        setIds(uniq(paramsIds.split(',').concat(ids)))
        // onRequest(ids.replaceAll(',', '|'));
    }, [searchParams]);

    return (
        <Layout
            loading={loading}
            setLocale={setLocale}
            isShowTabs={true}
            // activePageKey="information"
            activePageKey="allocation"
            activePageChildrenKey="fundComparison"
        >
            <div className={styles.container}>
                <Holdings 
                    setIds={setIds}
                    ids={ids}
                    data={data}
                />
                <Info
                    data={data}
                    setData={setData}
                />
            </div>
        </Layout>
    )
}

export default FundComparison