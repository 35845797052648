import React from 'react';
import { useAutoFocus } from '../../hooks/useAutoFocus';
import { debounce, get, isEmpty } from 'lodash';
import plusIcon from '../../assets/fundComparison/plus.svg';
import downloadIcon from '../../assets/fundComparison/download.png';
import searchIcon from '../../assets/layout_action_1_1.svg';
import closeIcon from '../../assets/xray/close.svg';
import styles from './holdings.module.css';

const { useState, useMemo } = React;

const Holdings = ({
    setIds,
    ids,
    data = [],
}) => {

    const [isShowModal, setIsShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const fetchSelectData = async(value) => {
        setLoading(true);
        setInputValue(value);
        try {
            // const response = await fetch(`api2/v1/agent/security/screener?page=1&pageSize=100&sortOrder=name%20asc&outputType=json&version=1&languageId=en-GB&currencyId=GBP&universeIds=FOALL%24%24ALL_3686&securityDataPoints=holdingTypeId%2CsecId%2Cname%2CongoingCharge%2CmaxFrontEndLoad%2CperformanceFee%2CtransactionFee&filters=&term=${value}&subUniverseId=`)
            const response = await fetch(`api2/v1/agent/security/screener?page=1&pageSize=100&sortOrder=name%20asc&outputType=json&version=1&languageId=en-GB&currencyId=GBP&universeIds=FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198&securityDataPoints=holdingTypeId%2CsecId%2Cname%2CongoingCharge%2CmaxFrontEndLoad%2CperformanceFee%2CtransactionFee&filters=&term=${value}&subUniverseId=`)
            const res = await response.json();
            const rows = get(res, 'rows') || [];
            setListData(rows);
            setLoading(false);
        }catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const debouncedEventFetch = useMemo(() => {
        return debounce(fetchSelectData, 500)
    }, []); 

    const addDisabled = useMemo(() => {
        return data.length >= 5
    }, [data]);

    const onAdd = () => {
        setIsShowModal(true);
    };

    const onGenerate = () => {
        
    };

    const onSelectItem = (item) => {
        setIds([].concat(data.map(item => item.Id)).concat([item.secId]));
        setListData([]);
        setInputValue('');
        setIsShowModal(false);
    };

    const onChange = (e) => {
        const { value } = e.target;

        if (value) {
            debouncedEventFetch(value);
        }else {
            setInputValue('');
        }
    };

    const onClose = () => {
        setIsShowModal(false);
        setInputValue('');
        setLoading(false)
    }

    return (
        <div className={styles.container}>
            <button 
                className={styles['plus-button']} onClick={onAdd}
                disabled={addDisabled}
            >
                <img src={plusIcon}/>
                <span>Add Holdings</span>
            </button>
            {/* <button className={styles['plus-button']}>
                <img src={downloadIcon}/>
                <span>Generate Report</span>
            </button> */}
            {
                isShowModal && (
                    <div className={styles['modal']}>
                        <div className={styles['modal-container']} >
                            <span className={styles['modal-title']}>Add Holdings</span>
                            <div className={styles['input-container']}>
                                <img src={searchIcon} className={styles['input-left-icon']}/>
                                <input
                                    className={styles['input']}
                                    placeholder="Enter name, ISIN"
                                    autoFocus={true}
                                    required={true}
                                    readOnly={loading}
                                    onChange={onChange}
                                />
                                <div className={styles['input-loading']} style={{display: loading && 'block'}}/>
                                {
                                    inputValue &&(
                                        <SelectList
                                            data={listData}
                                            onSelect={onSelectItem}
                                            loading={loading}
                                        />
                                    )
                                }
                            </div>
                            <img 
                                src={closeIcon} 
                                className={styles['modal-icon']}
                                onClick={onClose}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
};

const SelectList = ({
    data,
    onSelect,
    loading
}) => {

    const elementRef = useAutoFocus();

    const isEmptyData = useMemo(() => {
        return isEmpty(data);
    }, [isEmpty, data]);

    return (
        <div
            className={styles['select-list']}
            role="button"
            tabIndex="-1"
            ref={elementRef}
            style={{height: loading ? '200px' : 'auto'}}
        >
            {
                !loading ? (
                    <>
                    <span className={`${styles['select-list-title']} ${isEmptyData && styles['select-list-title-empty']}`}>
                        {isEmptyData ? 'No Results Found' : 'Open End Funds'}
                    </span>
                    {
                        data.map((item) => (
                            <div
                                className={styles['select-list-item']}
                                key={`${item.secId}-select`}
                                onClick={() => onSelect(item)}
                            >
                                {item.name}
                            </div>
                        ))
                    }
                    </>
                ) : (
                    <div className={styles['select-list-loader']}/>
                )
            }

        </div>
    )
}

export default Holdings;