import React from 'react';
import { debounce, get, isEmpty, findIndex } from 'lodash';
import anime from 'animejs';
import Layout from '../../component/Layout/Layout';
import Chart from './ChartContainer';
import Select from './Select';
import comparingIcon from '../../assets/xray/comparing.svg';
import styles from './interactiveChart.module.css';

const { useState, useMemo, useCallback, useEffect, useRef } = React;

const InteractiveChart = ({setLocale}) => {

    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [tableData, setTableData] = useState([]);
    const [chartEndDate, setChartEndDate] = useState('');
    const [chartData, setChartData] = useState([]);
    const [ids, setIds] = useState([])
    const [collapseStatus, setCollapseStatus] = useState(false);

    const fetchSelectData = async(value) => {
        setLoading(true);
        setInputValue(value);
        try {
            // const response = await fetch(`api2/v1/agent/security/screener?page=1&pageSize=10&sortOrder=LegalName%20asc&outputType=json&version=1&languageId=en-GB&currencyId=BAS&securityDataPoints=InceptionDate|SecId|Universe|LegalName&universeIds=FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198&term=${value}`);
            const response = await fetch(encodeURI(`api2/v1/agent/security/screener?page=1&pageSize=10&sortOrder=LegalName%20asc&outputType=json&version=1&languageId=en-GB&currencyId=BAS&securityDataPoints=InceptionDate|SecId|Universe|LegalName&universeIds=FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198&term=${value}`));

            const res = await response.json();
            const rows = get(res, 'rows') || [];
            setListData(rows);
            setLoading(false);
        }catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const fetchTableData = async(value, isAdd = false) => {
        setTableLoading(true);
        try {
            // const response = await fetch(`api2/v1/agent/security/screener?page=1&pageSize=10&sortOrder=LegalName asc&outputType=json&version=1&languageId=en-GB&currencyId=BAS&securityDataPoints=Currency|ISIN|ClosePriceDate|InceptionDate|ClosePrice|ReturnM0|SecId|Universe|LegalName|ReturnD1|ReturnM1|ReturnM3|ReturnM6|ReturnM12|ReturnM36|ReturnM60&universeIds=CAGBR$$ALL|FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198&filters=SecId:EQ:${value}`)
            const response = await fetch(encodeURI(`api2/v1/agent/security/screener?page=1&pageSize=10&sortOrder=LegalName asc&outputType=json&version=1&languageId=en-GB&currencyId=BAS&securityDataPoints=Currency|ISIN|ClosePriceDate|InceptionDate|ClosePrice|ReturnM0|SecId|Universe|LegalName|ReturnD1|ReturnM1|ReturnM3|ReturnM6|ReturnM12|ReturnM36|ReturnM60&universeIds=CAGBR$$ALL|FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198&filters=SecId:EQ:${value}`))

            const res = await response.json();
            const data = res.rows || []
            if (isEmpty(tableData) || isAdd) {
                setTableData([].concat(tableData).concat(data));
            }else {
                const newData = [].concat(tableData);
                newData[0] = data[0];
                setTableData(newData);
            }
            setTableLoading(false);
        }catch (err) {
            console.log(err);
            setTableLoading(false);
        }
    };

    const fetchChartData = async(value, isAdd = false) => {
        setChartLoading(true);

        const { InceptionDate, Universe, SecId } = value;
        try {
            const endDate = chartEndDate ? chartEndDate : InceptionDate;
            // const response = await fetch(`api3/rest.svc/timeseries_cumulativereturn/6s0xwtu5t6?currencyId=BAS&idtype=Morningstar&frequency=daily&startDate=${endDate}&performanceType=&outputType=COMPACTJSON&id=${SecId}]2]1]${Universe}&decPlaces=8&applyTrackRecordExtension=true`);
            // const response = await fetch(encodeURI(`api3/rest.svc/timeseries_cumulativereturn/6s0xwtu5t6?currencyId=BAS&idtype=Morningstar&frequency=daily&startDate=${endDate}&performanceType=&outputType=COMPACTJSON&id=${SecId}]2]1]${Universe}&decPlaces=8&applyTrackRecordExtension=true`));
            const response = await fetch(encodeURI(`https://46ispa0co1.execute-api.ap-east-1.amazonaws.com/citi-dev/api3/rest.svc/timeseries_cumulativereturn/6s0xwtu5t6?currencyId=BAS&idtype=Morningstar&frequency=daily&startDate=${endDate}&performanceType=&outputType=COMPACTJSON&id=${SecId}]2]1]${Universe}&decPlaces=8&applyTrackRecordExtension=true`));
            const res = await response.json();

            if (!isEmpty(res)) {
                setChartEndDate(endDate);
            }

            if (isEmpty(chartData) || isAdd) {
                setChartData([].concat(chartData).concat({
                    data: res,
                    SecId
                }));
            }else {
                const newData = [].concat(chartData);
                newData[0] = {
                    data: res,
                    SecId
                };
                setChartData(newData);
            }
            setChartLoading(false);

        }catch (err) {
            console.log(err)
            setChartLoading(false);
        }
    }

    const debouncedEventFetch = useMemo(() => {
        return debounce(fetchSelectData, 500)
    }, []); 

    const onSelectItem = (item) => {
        if (isEmpty(ids)) {
            setIds([item.SecId])
        }else {
            const newIds = [].concat(ids);
            newIds[0] = item.SecId;
            setIds(newIds);
        }
        setListData([]);
        setInputValue('');

        fetchTableData(item.SecId);
        fetchChartData(item);
    };

    const onChange = (e) => {
        const { value } = e.target;

        if (value) {
            setInputValue(value);
        }else {
            setInputValue('');
        }
    };

    const onCompare = () => {
        if (isEmpty(tableData)) return;

        setCollapseStatus(true);
    };

    useEffect(() => {
        if (inputValue) {
            debouncedEventFetch(inputValue);
        }
    }, [inputValue]);

    return (
        <Layout
            // loading={loading}
            activePageKey="interactiveChart"
            activePageChildrenKey="fundChart"
            setLocale={setLocale}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles['input-container']}>
                        <input 
                            className={styles.input}
                            placeholder='New Search–Enter fundcode, fundname or ISIN'
                            autoFocus={true}
                            required={true}
                            value={inputValue}
                            onChange={onChange}
                        />
                        <div className={styles['input-loading']} style={{display: loading && 'block'}}/>
                        {
                            inputValue &&(
                                <SelectList
                                    data={listData}
                                    onSelect={onSelectItem}
                                    loading={loading}
                                />
                            )
                        }
                    </div>
                    <div
                        className={styles['action-compare']}
                        onClick={onCompare}
                    >
                        <img src={comparingIcon} alt='compare'/>
                        <span>Compare</span>
                    </div>
                </div>
                <Collapse
                    isOpen={collapseStatus}
                    setIsOpen={setCollapseStatus}
                    fetchTableData={fetchTableData}
                    fetchChartData={fetchChartData}
                    ids={ids}
                    setIds={setIds}
                />
                <div className={styles.body}>
                    <Chart
                        dataIds={ids}
                        setDataIds={setIds}
                        tableData={tableData}
                        setTableData={setTableData}
                        chartData={chartData}
                        setChartData={setChartData}
                        tableLoading={tableLoading}
                        chartLoading={chartLoading}
                    />
                </div>
            </div>
        </Layout>
    )
};

const Collapse = ({
    isOpen,
    setIsOpen,
    fetchTableData,
    fetchChartData,
    ids,
    setIds
}) => {
    const elementRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [listData, setListData] = useState([]);
    const [benchmarkData, setBenchmarkData] = useState([]);

    const fetchSelectData = async(value) => {
        setLoading(true);
        setInputValue(value);
        try {
            // const response = await fetch(`api2/v1/agent/security/screener?page=1&pageSize=10&sortOrder=LegalName%20asc&outputType=json&version=1&languageId=en-GB&currencyId=BAS&securityDataPoints=InceptionDate|SecId|Universe|LegalName&universeIds=FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198&term=${value}`)
            const response = await fetch(encodeURI(`api2/v1/agent/security/screener?page=1&pageSize=10&sortOrder=LegalName%20asc&outputType=json&version=1&languageId=en-GB&currencyId=BAS&securityDataPoints=InceptionDate|SecId|Universe|LegalName&universeIds=FOALL$$ALL_1770|FOALL$$ALL_5890|FOALL$$ALL_3198&term=${value}`));
            const res = await response.json();
            const rows = get(res, 'rows') || [];
            setListData(rows);
            setLoading(false);
        }catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const fetchBenchmarkData = async() => {
        try {
            const response = await fetch(encodeURI('api2/v1/agent/security/screener?pageSize=999&outputType=json&universeIds=CAGBR$$ALL&securityDataPoints=Universe|SecId|Name&languageId=en-GB'));
            // const response = await fetch('api2/v1/agent/security/screener?pageSize=999&outputType=json&universeIds=CAGBR$$ALL&securityDataPoints=Universe|SecId|Name&languageId=en-GB')
            const res = await response.json();
            const rows = get(res, 'rows') || [];
            setBenchmarkData(rows);
        }catch (err){
            console.log(err);
        }
    };

    const debouncedEventFetch = useMemo(() => {
        return debounce(fetchSelectData, 500)
    }, []);

    const benchmarkOptions = useMemo(() => {
        return benchmarkData.map(({SecId, Name, Universe}) => {
            return {
                key: SecId,
                label: Name,
                universeId: Universe
            }
        })
    }, [benchmarkData]);

    const onStartAnimation = () => {
        anime({
            targets: elementRef.current,
            keyframes: [
                {
                    // minHeight: isOpen ? 'auto' : 0,
                    height: isOpen ? '100%' : '0px',
                    duration: 300,
                    overflow: isOpen ? 'auto' : 'hidden',
                    easing: 'linear'
                }
            ]
        });
    };

    const onChange = (e) => {
        const { value } = e.target;

        if (value) {
            setInputValue(value);
        }else {
            setInputValue('');
        }
    };

    const onSelectItem = (value) => {
        const { SecId } = value;
        if (ids.includes(SecId)) {
            return
        }
        setListData([]);
        setInputValue('');
        setIds([].concat(ids).concat([SecId]));

        fetchTableData(SecId, true);
        fetchChartData(value, true);
    };

    const onSelectBenchmark = ({key, universeId}) => {
        if (ids.includes(key)) {
            return;
        }
        setIds([].concat(ids).concat([key]));

        fetchTableData(key, true);
        fetchChartData({SecId: key, Universe: universeId}, true)
    };

    useEffect(() => {
        if (inputValue) {
            debouncedEventFetch(inputValue)
        }
    }, [inputValue]);

    useEffect(() => {
        onStartAnimation();
    }, [isOpen]);

    useEffect(() => {
        fetchBenchmarkData();
    }, []);

    return (
        <div className={styles['panel-collapse']} ref={elementRef} style={{overflow: isOpen ? 'visible' : 'hidden'}}>
            <div 
                className={styles['panel-button']}
                role="button"
                onClick={() => setIsOpen(false)}
            />
            <div className={styles['panel-item']}>
                <span className={styles['panel-item-title']}>
                    Compare
                </span>
                <div className={`${styles['input-container']} ${styles['panel-action']}`}>
                    <input
                        className={styles['input']}
                        placeholder="Compare–Enter fundcode, fundname or ISIN"
                        required={true}
                        value={inputValue}
                        onChange={onChange}
                    />
                    <div className={styles['input-loading']} style={{display: loading && 'block'}}/>
                    {
                        inputValue &&(
                            <SelectList
                                data={listData}
                                onSelect={onSelectItem}
                                loading={loading}
                            />
                        )
                    }
                </div>
            </div>
            <div className={`${styles['panel-item']} ${styles['panel-item-benchmarks']}`}>
                <span className={styles['panel-item-title']}>
                    Benchmarks
                </span>
                <div className={styles['panel-select']}>
                    <Select
                        options={[{
                            key: 'MCA',
                            label: 'Morningstar Category Averages'
                        }]}
                        disabled={true}
                        initKey='MCA'
                    />
                    <Select
                        options={benchmarkOptions}
                        onChange={onSelectBenchmark}
                    />
                </div>
            </div>
        </div>
    )
}

const SelectList = ({
    data,
    onSelect,
    loading
}) => {

    const isEmptyData = useMemo(() => {
        return isEmpty(data);
    }, [isEmpty, data]);

    return (
        <div
            className={styles['select-list']}
            role="button"
            tabIndex="-1"
            // ref={elementRef}
            style={{height: loading ? '200px' : 'auto'}}
        >
            {
                !loading ? (
                    <>
                    <span 
                        className={`${styles['select-list-title']} ${isEmptyData && styles['select-list-title-empty']}`}
                        style={{display: isEmptyData ? 'auto' : 'none'}}
                    >
                        {isEmptyData ? 'No Results Found' : ''}
                    </span>
                    {
                        data.map((item) => (
                            <div
                                className={styles['select-list-item']}
                                key={`${item.SecId}-select`}
                                onClick={() => onSelect(item)}
                                key={item.LegalName}
                            >
                                {item.LegalName}
                            </div>
                        ))
                    }
                    </>
                ) : (
                    <div className={styles['select-list-loader']}/>
                )
            }

        </div>
    )
};



export default InteractiveChart;