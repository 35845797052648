import React from 'react';
import { get, isBoolean, cloneDeep, round, isNumber } from 'lodash';
import { useTable, usePagination, useSortBy } from 'react-table';
import { sectorEnum, getCountry, returnFloat } from '../../../utils/details';
import { PaginationActions } from '../../Landing/Table';
import starIcon from '../../../assets/select/star.svg';
import styles from './portfolio-holdings.module.css';

const { useMemo, useState }  = React;

const PortfolioHoldings = ({
    data,
    isShowHoldings
}) => {

    const [activeTabKey, setActiveTabKey] = useState('holdings')

    const tabsOptions = useMemo(() => {
        return [
            {
                key: 'holdings',
                label: 'Funds',
                tableBaseColumns: [
                    {Header: 'Fund Name', accessor: 'name'},
                    {Header: 'Currency', accessor: 'currencyId'},
                    {Header: 'Weight%', accessor: 'weight'},
                    {Header: 'Contribution% (Since Inception)', accessor: 'contributionReturn', isNoWrap: false},
                    {Header: 'Morningstar Rating', accessor: 'starRatingM255'}
                ]
            },
            {
                key: 'underlyHoldings',
                label: 'Top Underlying Holdings',
                tableBaseColumns: [
                    {Header: 'Security Name', accessor: 'name'},
                    {Header: 'Sector', accessor: 'globalSectorId'},
                    {Header: 'Country/Region', accessor: 'countryId'},
                    {Header: 'Weight%', accessor: 'weight'}
                ]
            },
            {
                key: 'holdingOverlap',
                label: 'Stock Overlap',
                tableBaseColumns: [
                    {Header: 'Security Name', accessor: 'name'},
                    {Header: 'Sector', accessor: 'globalSectorId'},
                    {Header: 'Holdings Portfolio Date', accessor: 'sector'},
                    {Header: '% of Investments', accessor: 'weight'}
                ]
            }
        ]
    }, []);

    const activeOptions = useMemo(() => {

        for (let i = 0; i < tabsOptions.length; i++) {
            const item = tabsOptions[i];

            if (item.key === activeTabKey) return item;
        }

    }, [tabsOptions, activeTabKey]);

    const tabData = useMemo(() => {
        return get(data, `${activeTabKey}`) || [];
    }, [data, activeTabKey]);

    return (
        <div className={`${styles.container} ${isShowHoldings && styles['container-full']}`}>
            <div className={styles.header}>
                <span className={styles['header-title']}>
                    Portfolio Holdings
                </span>
                <div className={styles['header-action']}>
                    {
                        tabsOptions.map(({label, key}) => (
                            <button
                                className={styles['header-action-button']}
                                key={key}
                                style={{background: activeTabKey === key && '#056dae'}}
                                onClick={() => setActiveTabKey(key)}
                            >
                                {label}
                            </button>
                        ))
                    }
                </div>
            </div>
            <Tab 
                options={activeOptions}
                baseData={tabData}
                tabKey={activeTabKey}
            />
        </div>
    )
};

const Tab = ({
    options,
    baseData,
    tabKey
}) => {

    const tableColumns = useMemo(() => {
        return options.tableBaseColumns.map(item => {
            const { accessor } = item;

            if (accessor === 'globalSectorId') {
                return {
                    ...item,
                    Cell: ({cell}) => (
                        <span>{get(sectorEnum, `${cell.value}`) || '-'}</span>
                    )
                }
            }

            if (accessor === 'countryId') {
                return {
                    ...item,
                    Cell: ({cell}) => (
                        <span>{getCountry(cell.value) || '-'}</span>
                    )
                }
            }

            if (accessor === 'weight' || accessor === 'contributionReturn') {
                return {
                    ...item,
                    sortType: 'basic',
                    Cell: ({cell: {value}}) => (
                        <div style={{textAlign: 'center'}}>{isNumber(value) ? returnFloat(round(value, 2)) : '-'}</div>
                    )
                }
            }

            if (accessor === 'starRatingM255') {
                return {
                    ...item,
                    Cell: ({ cell }) => {
                        if (!isNumber(cell.value)) {
                            return <span>-</span>
                        }
                        const arr = Array.from(Array(cell.value), (e, index) => index + 1);
                        return (
                            <div>
                                {
                                    arr.map(item => (
                                        <img 
                                            src={starIcon}
                                            key={`${item}-starRatingM255-table`}
                                        />
                                    ))
                                }
                            </div>
                        )
                    }
                }
            }

            return item
        })
    }, [options, sectorEnum, get]);

    const data = useMemo(() => {

        if (tabKey === 'underlyHoldings') {
            const newData = cloneDeep(baseData);

            if (newData.length > 10) {
                newData.length = 10
            }

            return newData;
        }

        return baseData;
    }, [tabKey, baseData]);

    return (
        <div className={styles['tab-container']}>
            <Table 
                columns={tableColumns} 
                data={data}
                sortById={tabKey === 'holdings' ? 'name' : 'weight'}
            />
        </div>
    )
}

const Table = ({ columns, data, sortById }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: sortById,
                        desc: true
                    }
                ]
            }
        },
        useSortBy,
        usePagination
    )
    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                {
                    headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                            headerGroup.headers.map((column, index) => {
                                return (
                                    (
                                        <th 
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={styles['thead-th']}
                                            nowrap={isBoolean(column.isNoWrap) ? 'none' : 'nowrap'}
                                        >
                                            {column.render('Header')}
                                            <div 
                                                className={styles['table-sort']}
                                                style={{
                                                    borderTop: !column.isSorted && 'none',
                                                    marginLeft: index === 0 ? 16 : 0
                                                }}
                                            >
                                                <div style={{position: 'relative'}}>
                                                    {
                                                        column.isSorted ? column.isSortedDesc ? (
                                                            <div className={styles['table-sort-down']}/>
                                                        ) : (
                                                            <div className={styles['table-sort-up']}/>
                                                        ) : ''}
                                                </div>
                                                
                                            </div>
                                        </th>
                                    )
                                )
                            })
                        }
                        </tr>
                    ))
                }
                </thead>
                <tbody {...getTableBodyProps()}>
                {
                    page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr 
                                {...row.getRowProps()}
                                className={styles['tbody-tr']}
                            >
                                {
                                    row.cells.map((cell) => {
                                        return (
                                            <td 
                                                {...cell.getCellProps()}
                                                className={styles['tbody-td']}
                                            >
                                                <span className={styles['tbody-td-title']}>
                                                    {cell.column.Header}
                                                </span>
                                                <div className={styles['tbody-td-value']}>
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            {
                data.length > 10 && (
                    <PaginationActions
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        setPageSize={setPageSize}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        totalData={data.length}
                    />
                )
            }
        </>
    )
} 

export default PortfolioHoldings;