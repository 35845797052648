import React from 'react';
import anime from 'animejs';
import { get, round } from 'lodash';
import { returnFloat } from '../../utils/details';
import { getStockSectorsItemValue } from '../../utils/xray';
import cyclicalIcon from '../../assets/xray/stockSectors/cyclical.svg';
import cyclical01Icon from '../../assets/xray/stockSectors/cyclical-101.svg';
import cyclical02Icon from '../../assets/xray/stockSectors/cyclical-102.svg';
import cyclical03Icon from '../../assets/xray/stockSectors/cyclical-103.svg';
import cyclical04Icon from '../../assets/xray/stockSectors/cyclical-104.svg';
import defensiveIcon from '../../assets/xray/stockSectors/defensive.svg';
import defensive05Icon from '../../assets/xray/stockSectors/defensive-205.svg';
import defensive06Icon from '../../assets/xray/stockSectors/defensive-206.svg';
import defensive07Icon from '../../assets/xray/stockSectors/defensive-207.svg';
import sensitiveIcon from '../../assets/xray/stockSectors/sensitive.svg';
import sensitive08Icon from '../../assets/xray/stockSectors/sensitive-308.svg';
import sensitive09Icon from '../../assets/xray/stockSectors/sensitive-309.svg';
import sensitive10Icon from '../../assets/xray/stockSectors/sensitive-310.svg';
import sensitive11Icon from '../../assets/xray/stockSectors/sensitive-311.svg';
import styles from './stock.module.css';

const { useMemo, useRef, useEffect } = React;

const StockSectors = ({
    value,
    isShowHoldings
}) => {
    
    const options = useMemo(() => {

        const cyclicalItem = [
            {key: 'cyclical-101', label: 'Basic Materials', icon: cyclical01Icon, value: get(value, '101') || '-' },
            {key: 'cyclical-102', label: 'Consumer Cyclical', icon: cyclical02Icon, value: get(value, '102') || '-' },
            {key: 'cyclical-103', label: 'Financial Services', icon: cyclical03Icon, value: get(value, '103') || '-' },
            {key: 'cyclical-104', label: 'Real Estate', icon: cyclical04Icon, value: get(value, '104') || '-' },
        ];

        const sensitiveItem = [
            {key: 'sensitive-308', label: 'Communication Services', icon: sensitive08Icon, value: get(value, '308') || '-' },
            {key: 'sensitive-309', label: 'Energy', icon: sensitive09Icon, value: get(value, '309') || '-' },
            {key: 'sensitive-310', label: 'Industrials', icon: sensitive10Icon, value: get(value, '310') || '-' },
            {key: 'sensitive-311', label: 'Technology', icon: sensitive11Icon, value: get(value, '311') || '-' },
        ];

        const defensiveItem = [
            {key: 'defensive-205', label: 'Consumer Defensive', icon: defensive05Icon, value: get(value, '205') || '-' },
            {key: 'defensive-206', label: 'Healthcare', icon: defensive06Icon, value: get(value, '206') || '-' },
            {key: 'defensive-207', label: 'Utilities', icon: defensive07Icon, value: get(value, '207') || '-' },
        ];

        return [
            {
                key: 'cyclical',
                label: 'Cyclical',
                icon: cyclicalIcon,
                iconWidth: 16,
                color: '#9bb83d',
                item: cyclicalItem,
                value: getStockSectorsItemValue(cyclicalItem)
            },
            {
                key: 'sensitive',
                label: 'Sensitive',
                icon: sensitiveIcon,
                color: '#8499c4',
                iconWidth: 20,
                item: sensitiveItem,
                value: getStockSectorsItemValue(sensitiveItem)
            },
            {
                key: 'defensive',
                label: 'Defensive',
                icon: defensiveIcon,
                color: '#d17d20',
                iconWidth: 12,
                item: defensiveItem,
                value: getStockSectorsItemValue(defensiveItem)
            }
        ];
    }, [value]);

    return (
        <div className={styles.container}>
            <span className={styles.title}>
                Stock Sectors
            </span>
            <div className={`${styles.info} ${!isShowHoldings && styles['info-full']}`}>
                {
                    options.map(({key, label, icon, iconWidth, color, value, item}) => (
                        <div 
                            className={styles['info-item']}
                            key={key}
                        >
                            <div className={styles['info-item-header']}>
                                <div className={styles['info-header-img-container']} style={{background: color}}>
                                    <img src={icon} style={{width: iconWidth}}/>
                                </div>
                                <div className={styles['info-item-tips-container']}>
                                    <span className={styles['tips-label']}>{label}</span>
                                    <span className={styles['tips-value']}>{value}%</span>
                                </div>
                                <Indicator color={color} value={value}/>
                            </div>
                            <div className={styles['info-item-body']}>
                                <div className={`${styles['body-header']} ${isShowHoldings && styles['body-header-full']}`}>
                                    <span/>
                                    <span style={{color}}>Weight,%</span>
                                </div>
                                {
                                    item.map(({key, value, label, icon}) => (
                                        <div 
                                            className={styles['body-item']}
                                            key={key}
                                        >
                                            <div className={styles['body-item-left']}>
                                                <img src={icon}/>
                                                <span>{label}</span>
                                            </div>
                                            <span className={styles['body-item-value']}>{value === '-' ? '-' : returnFloat(round(value, 2))}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export const Indicator = ({
    value,
    color
}) => {

    const progressRef = useRef(null);
    const tipsRef = useRef(null);

    useEffect(() => {
        if (progressRef.current && tipsRef.current) {
            anime({
                targets: progressRef.current, 
                keyframes: [{
                    width: `${value}%`,
                    duration: 600,
                    easing: 'easeInQuart',
                }],
            })
            anime({
                targets: tipsRef.current, 
                keyframes: [{
                    left: `${value}%`,
                    duration: 600,
                    easing: 'easeInQuart',
                }],
            })
        }
    }, [value]);

    return (
        <div className={styles['indicator']}>
            <div className={styles['indicator-background']}/>
            <div 
                className={styles['indicator-progress']}
                ref={progressRef}
                style={{background: color}}
            />
            <div 
                className={styles['indicator-tips']}
                style={{background: color}}
                ref={tipsRef}
            >
                <div style={{position: 'relative', height: '100%'}}>
                    <div className={styles['indicator-tips-arrows']} style={{background: color}}/>
                    <div className={styles['indicator-tips-circle']}/>
                </div>
            </div>
        </div>
    )
};
export default StockSectors;