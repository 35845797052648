import React from 'react';
import Select from '../Select/Select';
import styles from './index.module.css';

const SelectSearch = ({
    options,
    onSelectChange ,
    selectKey
}) => {
    return (
        <div>
            <Select 
                options={options}
                onSelectChange={onSelectChange}
                selectKey={selectKey}
            />
        </div>
    )
};

export default SelectSearch;