import React from 'react';
import { useAutoFocus } from '../../hooks/useAutoFocus';
import { getGuid } from '../../utils/utils';
import { validateHoldingsOptions } from '../../utils/xray';
import { isBoolean, isEmpty, isNumber, debounce, get } from 'lodash';
import SelectSearch from '../../component/SelectSearch/SelectSearch';
import closeIcon from '../../assets/xray/close.svg';
import addIcon from '../../assets/xray/add.svg';
import errorIcon from '../../assets/xray/error.png';
import styles from './holdings.module.css';

const { useEffect, useMemo, useState } = React;

const Holdings = ({
    options = [],
    isShow,
    setOptions,
    onChangeRequest,
    benchmarkData,
    onSelectBenchmarkChange,
    selectBenchmarkId,
}) => {
    
    const [currentLoadingKey, setCurrentLoadingKey] = useState(null);
    const [focusList, setFocusList] = useState({});

    const totalInfo = useMemo (() => {
        return validateHoldingsOptions(options);
    }, [options, validateHoldingsOptions]);

    const fetchSelectData = async (value, key) => {
        setCurrentLoadingKey(key);
        try {
            const response = await fetch(`api2/v1/agent/security/screener?page=1&pageSize=100&sortOrder=name%20asc&outputType=json&version=1&languageId=en-GB&currencyId=GBP&universeIds=FOALL%24%24ALL_3686&securityDataPoints=holdingTypeId%2CsecId%2Cname%2CongoingCharge%2CmaxFrontEndLoad%2CperformanceFee%2CtransactionFee&filters=&term=${value}&subUniverseId=`)
            const res = await response.json();
            const rows = get(res, 'rows') || [];

            setFocusList({
                [key]: rows
            });

            setCurrentLoadingKey(null);
        }catch (err) {
            console.log(err);
            setCurrentLoadingKey(null);
        }
    };

    const debouncedEventFetch = useMemo(() => {
        return debounce(fetchSelectData, 500)
    }, []);

    const onDeleteItem = (index) => {
        const newOptions = [].concat(options);
        newOptions.splice(index, 1)

        setOptions(newOptions);

        if (!(validateHoldingsOptions(newOptions).isError)) {
            onChangeRequest(newOptions, newOptions);
        }
    };
    
    const onAddItem = () => {
        const newOptions = [].concat(options).concat([{}]);

        setOptions(newOptions);
    };

    const onNameChange = (value, index, key) => {
        const newOptions = [].concat(options);
        newOptions[index].name = value;
        newOptions[index].isSelect = false;
        setOptions(newOptions);

        debouncedEventFetch(value, key);
    };

    const onWeightChange = (value, index) => {
        const newOptions = [].concat(options);
        newOptions[index].weight = value === '' ? value : Number(value);

        setOptions(newOptions);

        const { isSelect } = newOptions[index] || {};
        const selectStatus = isBoolean(isSelect) ? isSelect : true;
        if (!(validateHoldingsOptions(newOptions).isError) && selectStatus) {
            onChangeRequest(newOptions);
        }
    };

    const onSelectData = (value, index) => {
        const newOptions = [].concat(options);
        const item = newOptions[index];
        newOptions[index] = {
            ...item,
            ...value,
            weight: isNumber(item.weight) ? item.weight : 0,
            isSelect: true
        }
        
        if (index === newOptions.length - 1) {
            newOptions.push({
                isSelect: false, 
                guid: getGuid()
            });
        }

        setOptions(newOptions);
        setFocusList({});

        if (!(validateHoldingsOptions(newOptions).isError)) {
            onChangeRequest(newOptions, newOptions);
        }
    };

    return (
        <div className={`${styles.container} ${!isShow && styles['container-hide']}`}>
            <span className={styles.title}>
                Holdings
            </span>
            <div className={styles.header}>
                <span>Weight %</span>
            </div>
            <div className={styles.info}>
                {
                    options.map((item, index) => {
                        const { iSIN, weight, name, guid } = item;
                        const isLast = index === options.length - 1;
                        const key = iSIN ? iSIN : guid;
                        return (
                            <div 
                                className={styles['info-item']} 
                                key={key}
                            >
                                <div className={styles['info-input-group']}>
                                    <span>{index + 1}. </span>
                                    <input 
                                        className={styles['info-input']}
                                        value={name ? name : undefined }
                                        placeholder="Enter name, ISIN"
                                        onChange={(e) => onNameChange(e.target.value, index, key)}
                                        readOnly={currentLoadingKey !== null}
                                        required={true}
                                    />
                                    <div className={styles['info-input-group-loading']} style={{display: currentLoadingKey === key && 'block'}}/>
                                </div>
                                <div className={styles['info-item-action']}>
                                    <input 
                                        className={styles['info-input']}
                                        value={weight}
                                        onChange={(e) => onWeightChange(e.target.value, index)}
                                        type="number"
                                        // max={100}
                                        style={{paddingRight: 0}}
                                    />
                                    <img 
                                        role='button'
                                        tabIndex="0"
                                        src={isLast ? addIcon : closeIcon}
                                        alt={isLast ? 'delete' : 'add'}
                                        onClick={() => isLast ? onAddItem() : onDeleteItem(index)}
                                    />
                                </div>
                                {
                                    key in focusList && (
                                        <SelectList 
                                            setFocusList={setFocusList}
                                            data={focusList[key]}
                                            currentIndex={index}
                                            onSelect={onSelectData}
                                        />
                                    )
                                }
                            </div>
                        )
                    })
                }
                {
                    totalInfo && (
                        <div className={styles['info-item']}>
                            <span className={styles['info-item-tips']}>Total Weight</span>
                            <span className={styles['info-item-weight']}>{totalInfo.weight}</span>
                        </div>
                    )
                }
                {
                    (totalInfo && totalInfo.isError) && (
                        <div className={styles['info-item-error']}>
                            <img src={errorIcon} alt="error"/>
                            <span>
                                {'The sum of the weights must be 100%, and one weight cannot be > 100% and <= 0%'}
                            </span>
                        </div>
                    )
                }
            </div>
            <div className={styles.footer}>
                <span className={`${styles.title} ${styles['title-benchmark']}`}>Benchmark</span>
                <SelectSearch
                    options={benchmarkData}
                    selectKey={selectBenchmarkId}
                    onSelectChange={onSelectBenchmarkChange}
                />
            </div>
        </div>
    )
};

const SelectList = ({
    setFocusList = () => {},
    data = [],
    currentIndex,
    onSelect
}) => {

    const elementRef = useAutoFocus();

    const isEmptyData = useMemo(() => {
        return isEmpty(data);
    }, [isEmpty, data]);

    return (
        <div 
            onBlur={() => setFocusList({})}
            className={styles['select-list']}
            role="button"
            tabIndex="-1"
            ref={elementRef}
        >
            <span className={`${styles['select-list-title']} ${isEmptyData && styles['select-list-title-empty']}`}>
                {isEmptyData ? 'No Results Found' : 'Open End Funds'}
            </span>
            {
                data.map((item) => (
                    <div
                        className={styles['select-list-item']}
                        key={`${item.secId}-select`}
                        onClick={() => onSelect(item, currentIndex)}
                    >
                        {item.name}
                    </div>
                ))
            }
        </div>
    )
};

export default Holdings;