import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.HONGKONG]: {
        // layoutFundInformation: '基金資訊',
        layoutFundInformation: '基金搜索',
        layoutFundSearch: '基金搜索',
        layoutFundComparison: '基金比較',
        layoutIdeas: '基金構思',
        layoutPerformanceRanking: '表現排名',
        layoutTopSellingFunds: '最佳銷售基金',
        layoutHighDividendPayingFund: '最高派息基金',
        // layoutAllocation: '資產配置',
        layoutAllocation: "基金比較",
        layoutPortfolioXray: '投資組合透視',
        layoutPortfolioComparison: '投資組合比較',
        layoutInteractiveChart: '互動圖表',
        layoutNoteTitle: '註',
        // layoutNotContent: '風險級別由本銀行制定及發放，分為1至5。風險級別1為最保守而風險級別5為最進取',
        layoutNotContent: '基金風險由本銀行制定及發放，分為1至6。基金風險1為最保守而基金風險6為最進取',
        layoutDisclaimerTitle: '免責聲明',
        layoutDisclaimerContent1: '本網站所載有關基金的資訊由晨星（亞洲）有限公司（「資訊供應商」）向FABC Bank (The "Bank") 提供。由於技術及/或操作之理由，部份資訊可能與相關基金公司所提供的有別，本銀行及任何關聯人均沒有修改、混合或控制任何由資訊供應商所提供的任何資料。',
        layoutDisclaimerContent2: '本銀行和資訊供應商盡力確保所提供資料乃屬準確及可靠，但本銀行和資料供應商並不保證該等資料是準確、可靠、完整及最新的。',
        layoutDisclaimerContent3: '倘在任何地區向任何人士作出投資的要約、遊說或建議乃屬違法，本網頁的資料不應視為向該等地區或人士進行對投資決策構成任何要約、遊說或建議。投資者不應單憑本網站之內容而作出投資決定。',
        layoutRiskTitle: '風險披露',
        layoutRiskContent1: '投資涉及風險，基金價格有時可能會非常波動。基金價格可升亦可跌，甚至可能變成毫無價值。買賣基金未必一定能夠賺取利潤，反而可能會招致虧損。投資於非本土貨幣結算的基金或會受到匯率波動的影響，可能會導致本金出現虧損。任何基金的過去表現並不一定可作為將來表現的指引。',
        layoutRiskContent2: '以上免責聲明不能披露所有涉及的風險。投資前應先閱讀有關產品發售文件、財務報表及相關的風險聲明，並應就本身的財務狀況及需要、投資目標及經驗，詳細考慮並決定該投資是否切合本身特定的投資需要及承受風險的能力。您應於進行交易或投資前尋求獨立的財務及專業意見，方可作出有關投資決定。本網頁之內容並未經香港證券及期貨事務監察委員會或任何香港的監管機構審閱。',
        layoutRiskContent3: '本銀行是為基金公司分銷基金產品，而有關基金產品是基金公司而非本銀行的產品。對於本銀行與客戶之間因銷售過程或處理有關交易產生的合資格爭議（定義見金融糾紛調解計劃的金融糾紛調解的中心職權範圍），本銀行須與客戶進行金融糾紛調解計劃程序；然而，對於有關產品的合約條款的任何爭議應由基金公司與客戶直接解決。',
        layoutCopyright: '©2023 Morningstar。版權所有。',
        layoutCopyrightTips: '在此提供的資料、數據、分析及意見（資料）(1) 包含Morningstar及其取得特許權之第三方的機密及專營資料；(2) 在未經許可下不可複製或轉載；(3) 並不構成任何投資建議；(4) 僅提供作參考用途；(5) 並未就所載資料的完整性、準確性及時間性作出任何保證；(6) 基金數據有機會來自不同的截數日。Morningstar對於閣下使用任何相關資料而作出的任何有關交易決定、傷害及其他損失均不承擔任何責任。請在使用所有資料前作出核實，並且在諮詢專業投資顧問意見前勿做任何投資決定。過往紀錄不代表將來表現，任何投資項目的價值及所得收入皆可升可跌。(如中文譯本與英文原文出現歧義，概以英文原文為準。）',
        
        landingInputPlaceholder: '輸入名稱或代碼查詢',
        landingInputTitle: '搜索或篩選投資',

        landingSelectFundUniverse: '基金範圍',
        // landingSelectCustomCategoryId3: '基金公司',
        landingSelectCustomCategoryId3: '基金種類',
        landingSelectCurrencyId: '貨幣',
        // landingSelectCustomCategoryId: '風險評級',
        landingSelectCustomCategoryId: '基金風險',
        landingSelectDistribution: '派息類別',
        landingSelectGlobalAssetClassId: '基金資產類別',
        landingSelectHKSFCESGFund: 'ESG 基金',
        landingSelectGlobalCategoryID: '基金組別',

        landingSelectAllLabel: '全部',
        landingSelectYesLabel: '是',
        landingSelectNoLabel: '否',
        landingSelectAccLabel: '累積',
        landingSelectIncLabel: '分紅',

        landingSelectAllLabel_1: '基金',
        landingSelectAllLabel_2: '基金 (適用於跨境理財通客戶)',
        landingSelectAllLabel_3: '另類投資基金',

        landingCheckBoxCustomAttributes5: '適用於理財通',
        landingCheckBoxCustomAttributes6: '可供港幣100元認購的基金',

        landingButtonReset: '重置條件',
        landingButtonShowLess: '顯示更少',
        landingButtonShowMore: '顯示更多',

        landingTabsOverview: '概覽',
        landingTabsCumulativePerformance: '累積回報',
        landingTabsCalendarYearPerformance: '年度回報',
        landingTabsRiskAndReturn: '風險及回報',
        landingTabsDocuments: '銷售文件',

        landingStarRatingM255: '晨星評級™',
        landingSustainabilityRank: '晨星可持續性評級',

        landingFixedIncomeTitle: '固定收入投資風格',
        landingFixedIncomeHigh: '高',
        landingFixedIncomeMed: '中',
        landingFixedIncomeLow: '低',
        landingFixedIncomeHigh2: '長',
        landingFixedIncomeMed2: '中',
        landingFixedIncomeLow2: '短',
        landingFixedIncomeRight: '信用品質',
        landingFixedIncomeBottom: '利率敏感性',

        landingEquityStyleTitle: '股票投資風格',
        landingEquityStyleLarge: '大',
        landingEquityStyleMid: '中',
        landingEquityStyleSmall: '小',
        landingEquityStyleValue: '價值',
        landingEquityStyleBlend: '均衡',
        landingEquityStyleGrowth: '增長',
        landingEquityStyleRight: '规模',
        landingEquityStyleBottom: '風格',

        tableHeaderFundCode: '基金代碼',
        tableHeaderFundName: '基金名稱',
        // tableHeaderFundCategory: '基金組別',
        tableHeaderFundCategory: 'Morningstar組別',
        // tableHeaderRiskRating: '風險評級',
        tableHeaderRiskRating: '基金風險',
        tableHeaderMorningstarRating: '晨星評級™',
        tableHeaderMorningstarSustainability: '晨星可持續性評級',
        tableHeaderFundCurrency: '基金貨幣',
        tableHeaderYTDReturn: '年初至今回報(%)',
        tableHeaderLastFund: '最新價格',
        tableHeaderYieldM12: '12個月息率(%)',
        tableHeaderLastPrice: '價格日期',
        tableHeaderReturnM1: '一个月(%)',
        tableHeaderReturnM3: '三个月(%)',
        tableHeaderReturnM6: '六个月(%)',
        tableHeaderReturnM12: '一年(%)',
        tableHeaderReturnM36UA: '三年(%)',
        tableHeaderReturnM60UA: '五年(%)',
        tableHeaderYRReturnM12_5: '2018(%)',
        tableHeaderYRReturnM12_4: '2019(%)',
        tableHeaderYRReturnM12_3: '2020(%)',
        tableHeaderYRReturnM12_2: '2021(%)',
        tableHeaderYRReturnM12_1: '2022(%)',
        tableHeaderStandardDeviationM12: '一年波幅',
        tableHeaderStandardDeviationM36: '三年波幅',
        tableHeaderStandardDeviationM60: '五年波幅',
        tableHeaderSharpeM12: '一年夏普比率',
        tableHeaderSharpeM36: '三年夏普比率',
        tableHeaderSharpeM60: '五年夏普比率',
        tableHeaderFS: '資料單張',
        tableHeaderPR: '基金說明書',
        tableHeaderKF: '產品資料概要',
        tableHeaderAR: '年報',
        tableHeaderIR: '中期報告',

        tableRowsClose: '(暫停接受認購)',

        paginationTips: '正在顯示 {start} - {end} 之 {totalData} 的結果',
        paginationShow: '顯示',
        paginationResult: '結果',
        paginationFirst: '第一頁',
        paginationLast: '最後一頁',
        paginationPrevious: '上一頁',
        paginationNext: '下一頁',
    },
    [LOCALES.CHINESE]: {
        // layoutFundInformation: '基金资讯',,
        layoutFundInformation: '基金搜索',
        layoutFundSearch: '基金搜索',
        layoutFundComparison: '基金比较',
        layoutIdeas: '基金构思',
        layoutPerformanceRanking: '表现排名',
        layoutTopSellingFunds: '最佳销售基金',
        layoutHighDividendPayingFund: '最高派息基金',
        // layoutAllocation: '资产配置',
        layoutAllocation: '基金比较',
        layoutPortfolioXray: '投资组合透视',
        layoutPortfolioComparison: '投资组合比较',
        layoutInteractiveChart: '互动图表',
        layoutNoteTitle: '注',
        // layoutNotContent: '风险级别由本银行制定及发放，分为1至5。风险级别1为最保守而风险级别5为最进取',
        layoutNotContent: '基金风险由本银行制定及发放，分为1至6。基金风险1为最保守而基金风险6为最进取',
        layoutDisclaimerTitle: '免责声明',
        layoutDisclaimerContent1: '本网站所载有关基金的资讯由晨星（亚洲）有限公司（「资讯供应商」）向FABC Bank (The "Bank") 提供。由于技术及/或操作之理由，部份资讯可能与相关基金公司所提供的有别，本银行及任何关联人均没有修改、混合或控制任何由资讯供应商所提供的任何资料。',
        layoutDisclaimerContent2: '本银行和资讯供应商尽力确保所提供资料乃属准确及可靠，但本银行和资料供应商并不保证该等资料是准确、可靠、完整及最新的。',
        layoutDisclaimerContent3: '倘在任何地区向任何人士作出投资的要约、游说或建议乃属违法，本网页的资料不应视为向该等地区或人士进行对投资决策构成任何要约、游说或建议。投资者不应单凭本网站之内容而作出投资决定。',
        layoutRiskTitle: '风险披露',
        layoutRiskContent1: '投资涉及风险，基金价格有时可能会非常波动。基金价格可升亦可跌，甚至可能变成毫无价值。买卖基金未必一定能够赚取利润，反而可能会招致亏损。投资于非本土货币结算的基金或会受到汇率波动的影响，可能会导致本金出现亏损。任何基金的过去表现并不一定可作为将来表现的指引。',
        layoutRiskContent2: '以上免责声明不能披露所有涉及的风险。投资前应先阅读有关产品发售文件、财务报表及相关的风险声明，并应就本身的财务状况及需要、投资目标及经验，详细考虑并决定该投资是否切合本身特定的投资需要及承受风险的能力。您应于进行交易或投资前寻求独立的财务及专业意见，方可作出有关投资决定。本网页之内容并未经香港证券及期货事务监察委员会或任何香港的监管机构审阅。',
        layoutRiskContent3: '本银行是为基金公司分销基金产品，而有关基金产品是基金公司而非本银行的产品。对于本银行与客户之间因销售过程或处理有关交易产生的合资格争议（定义见金融纠纷调解计划的金融纠纷调解的中心职权范围），本银行须与客户进行金融纠纷调解计划程序；然而，对于有关产品的合约条款的任何争议应由基金公司与客户直接解决。',
        layoutCopyright: '©2023 Morningstar。版权所有。',
        layoutCopyrightTips: '在此提供的资料、数据、分析及意见（资料）(1) 包含Morningstar及其取得特许权之第三方的机密及专营资料；(2) 在未经许可下不可複製或转载；(3) 并不构成任何投资建议；(4) 仅提供作参考用途；(5) 并未就所载资料的完整性、准确性及时间性作出任何保证；(6) 基金数据有机会来自不同的截数日。Morningstar对于阁下使用任何相关资料而作出的任何有关交易决定、伤害及其他损失均不承担任何责任。请在使用所有资料前作出核实，并且在谘询专业投资顾问意见前勿做任何投资决定。过往纪录不代表将来表现，任何投资项目的价值及所得收入皆可升可跌。(如中文译本与英文原文出现歧义，概以英文原文为准。）',
        
        landingInputPlaceholder: '输入名称或代码查询',
        landingInputTitle: '搜索或筛选投资',

        landingSelectFundUniverse: '基金范围',
        // landingSelectCustomCategoryId3: '基金公司',
        landingSelectCustomCategoryId3: '基金种类',
        landingSelectCurrencyId: '货币',
        // landingSelectCustomCategoryId: '风险评级',
        landingSelectCustomCategoryId: '基金风险',
        landingSelectDistribution: '派息类别',
        landingSelectGlobalAssetClassId: '基金资产类别',
        landingSelectHKSFCESGFund: 'ESG 基金',
        landingSelectGlobalCategoryID: '基金组别',

        landingSelectAllLabel: '全部',
        landingSelectYesLabel: '是',
        landingSelectNoLabel: '否',
        landingSelectAccLabel: '累积',
        landingSelectIncLabel: '分红',

        landingSelectAllLabel_1: '基金',
        landingSelectAllLabel_2: '基金 (适用于跨境理财通客户)',
        landingSelectAllLabel_3: '另类投资基金',

        landingCheckBoxCustomAttributes5: '适用于理财通',
        landingCheckBoxCustomAttributes6: '可供港币100元认购的基金',

        landingButtonReset: '重置条件',
        landingButtonShowLess: '显示更少',
        landingButtonShowMore: '显示更多',

        landingTabsOverview: '概览',
        landingTabsCumulativePerformance: '累积回报',
        landingTabsCalendarYearPerformance: '年度回报',
        landingTabsRiskAndReturn: '风险及回报',
        landingTabsDocuments: '销售文件',

        landingStarRatingM255: '晨星评级™',
        landingSustainabilityRank: '晨星可持续性评级',

        landingFixedIncomeTitle: '固定收入投资风格',
        landingFixedIncomeHigh: '高',
        landingFixedIncomeMed: '中',
        landingFixedIncomeLow: '低',
        landingFixedIncomeHigh2: '长',
        landingFixedIncomeMed2: '中',
        landingFixedIncomeLow2: '短',
        landingFixedIncomeRight: '信用品质',
        landingFixedIncomeBottom: '利率敏感性',

        landingEquityStyleTitle: '股票投资风格',
        landingEquityStyleLarge: '大',
        landingEquityStyleMid: '中',
        landingEquityStyleSmall: '小',
        landingEquityStyleValue: '价值',
        landingEquityStyleBlend: '均衡',
        landingEquityStyleGrowth: '增长',
        landingEquityStyleRight: '规模',
        landingEquityStyleBottom: '风格',

        tableHeaderFundCode: '基金代码',
        tableHeaderFundName: '基金名称',
        // tableHeaderFundCategory: '基金组别',
        tableHeaderFundCategory: 'Morningstar组别',
        // tableHeaderRiskRating: '风险评级',
        tableHeaderRiskRating: '基金风险',
        tableHeaderMorningstarRating: '晨星评级™',
        tableHeaderMorningstarSustainability: '晨星可持续性评级',
        tableHeaderFundCurrency: '基金货币',
        tableHeaderYTDReturn: '年初至今回报(%)',
        tableHeaderLastFund: '最新价格',
        tableHeaderYieldM12: '12个月息率(%)',
        tableHeaderLastPrice: '价格日期',
        tableHeaderReturnM1: '一个月(%)',
        tableHeaderReturnM3: '三个月(%)',
        tableHeaderReturnM6: '六个月(%)',
        tableHeaderReturnM12: '一年(%)',
        tableHeaderReturnM36UA: '三年(%)',
        tableHeaderReturnM60UA: '五年(%)',
        tableHeaderYRReturnM12_5: '2018(%)',
        tableHeaderYRReturnM12_4: '2019(%)',
        tableHeaderYRReturnM12_3: '2020(%)',
        tableHeaderYRReturnM12_2: '2021(%)',
        tableHeaderYRReturnM12_1: '2022(%)',
        tableHeaderStandardDeviationM12: '一年波幅',
        tableHeaderStandardDeviationM36: '三年波幅',
        tableHeaderStandardDeviationM60: '五年波幅',
        tableHeaderSharpeM12: '一年夏普比率',
        tableHeaderSharpeM36: '三年夏普比率',
        tableHeaderSharpeM60: '五年夏普比率',
        tableHeaderFS: '资料单张',
        tableHeaderPR: '基金说明书',
        tableHeaderKF: '产品资料概要',
        tableHeaderAR: '年报',
        tableHeaderIR: '中期报告',

        tableRowsClose: '(暂停接受认购)',

        paginationTips: '正在显示 {start} - {end} 之 {totalData} 的结果',
        paginationShow: '显示',
        paginationResult: '结果',
        paginationFirst: '第一页',
        paginationLast: '最后一页',
        paginationPrevious: '上一页',
        paginationNext: '下一页',
    },
    [LOCALES.ENGLISH]: {
        // layoutFundInformation: 'Fund Information',
        layoutFundInformation: "Fund Search",
        layoutFundSearch: 'Fund Search',
        layoutFundComparison: 'Fund Comparison',
        layoutIdeas: 'Fund Ideas',
        layoutPerformanceRanking: 'Performance Ranking',
        layoutTopSellingFunds: 'Top Selling Funds',
        layoutHighDividendPayingFund: 'High Dividend Paying Fund',
        // layoutAllocation: 'Asset Allocation',
        layoutAllocation: "Fund Comparison",
        layoutPortfolioXray: 'Portfolio X-ray',
        layoutPortfolioComparison: 'Portfolio Comparison',
        layoutInteractiveChart: 'Interactive Chart',
        layoutNoteTitle: 'Note',
        // layoutNotContent: 'Risk Rating, which is assigned and provided by our Bank, is classified from 1 to 5. Risk Rating 1 is the most conservative while Risk Rating 5 is the most aggressive.',
        layoutNotContent: 'Product Risk, which is assigned and provided by our Bank, is classified from 1 to 6. Product Risk 1 is the most conservative while Product Risk 6 is the most aggressive.',
        layoutDisclaimerTitle: 'Disclaimer',
        layoutDisclaimerContent1: 'All the information relating to investment funds in this website are provided by Morningstar Asia Limited (the “Data Provider”) to FABC Bank (The "Bank") . For technical and/or operational reasons, certain information may be different from that supplied by the respective fund house(s).',
        layoutDisclaimerContent2: 'The Bank and the Data Provider endeavour to ensure the accuracy and reliability of the information provided but the Bank and the Data Provider do not guarantee its accuracy, reliability, completeness of the information, or that it is up to date.',
        layoutDisclaimerContent3: 'The information provided herein does not constitute any offer or solicitation or advice regarding the investment in any jurisdiction to any person to whom it is unlawful to make such an invitation or solicitation or advice in such jurisdiction. Investor should not make investment decisions based solely on the information contained on this website.',
        layoutRiskTitle: 'Risk Disclosure',
        layoutRiskContent1: 'Investment involves risks. The prices of the investment funds fluctuate, sometimes dramatically. The price of an investment fund may move up or down and may become valueless. It is as likely that losses will be incurred rather than profit made as a result of buying and selling investment funds. When invest in investment funds denominated in non-local currencies, please be aware of the risk of exchange rate fluctuations that may cause a loss of principal. Past performance of any investment fund is no guide to its future performance.',
        layoutRiskContent2: 'The above disclaimer cannot disclose all the risks involved. Before making investment decision, you should thoroughly study the offering documents, financial reports and relevant risk disclosure statements issued by the issuer of the investment product(s). Further you should consider your own circumstances including financial position, investment experience and objective to ensure the investment is suitable for your particular investment needs and risk tolerance capacity. You should seek independent financial and professional advice before trading or investment. This website has not been reviewed by the Securities and Futures Commission or any regulatory authority in Hong Kong.',
        layoutRiskContent3: 'The Bank distributes the fund product for the fund houses and the fund product is a product of fund houses but not that of the Bank. In respect of an eligible dispute (as defined in the Terms of Reference for the Financial Dispute Resolution Centre in relation to the Financial Dispute Resolution Scheme) arising between the Bank and the customer out of the selling process or processing of the related transaction, the Bank is required to enter into a Financial Dispute Resolution Scheme process with the customer; however any dispute over the contractual terms of the product should be resolved directly between the fund houses and the customer.',
        layoutCopyright: '©2023 Morningstar. All Rights Reserved.',
        layoutCopyrightTips: 'The information, data, analyses and opinions (“Information”) contained herein: (1) include the proprietary information of Morningstar and Morningstar’s third party licensors; (2) may not be copied or redistributed except as specifically authorised;(3) do not constitute investment advice;(4) are provided solely for informational purposes; (5) are not warranted to be complete, accurate or timely; and (6) may be drawn from fund data published on various dates.  Morningstar is not responsible for any trading decisions, damages or other losses related to the Information or its use. Please verify all of the Information before using it and don’t make any investment decision except upon the advice of a professional financial adviser. Past performance is no guarantee of future results. The value and income derived from investments may go down as well as up.',

        landingInputPlaceholder: 'Enter Name of Fund Code',
        landingInputTitle: 'Search or filter investments',

        landingSelectFundUniverse: 'Fund Universe',
        // landingSelectCustomCategoryId3: 'Fund House',
        landingSelectCustomCategoryId3: 'Fund Types',
        landingSelectCurrencyId: 'Currency',
        // landingSelectCustomCategoryId: 'Risk Rating',
        landingSelectCustomCategoryId: 'Product Risk',
        landingSelectDistribution: 'Distribution Class',
        landingSelectGlobalAssetClassId: 'Fund Asset Class',
        landingSelectHKSFCESGFund: 'ESG Fund',
        landingSelectGlobalCategoryID: 'Fund Category',

        landingSelectAllLabel: 'All',
        landingSelectYesLabel: 'Yes',
        landingSelectNoLabel: 'No',
        landingSelectAccLabel: 'Accumulation',
        landingSelectIncLabel: 'Income',

        landingSelectAllLabel_1: 'Mutual Fund',
        landingSelectAllLabel_2: 'Mutual Fund for Wealth Management Connect Customers',
        landingSelectAllLabel_3: 'Alternative Mutual Fund',

        landingCheckBoxCustomAttributes5: 'Applicable to Wealth Management Connect',
        landingCheckBoxCustomAttributes6: 'Available for subscription at HKD 100',

        landingButtonReset: 'Reset filters',
        landingButtonShowLess: 'Show less',
        landingButtonShowMore: 'Show more',

        landingTabsOverview: 'Overview',
        landingTabsCumulativePerformance: 'Cumulative Performance',
        landingTabsCalendarYearPerformance: 'Calendar Year Performance',
        landingTabsRiskAndReturn: 'Risk And Return',
        landingTabsDocuments: 'Documents',

        landingStarRatingM255: 'Morningstar Rating™',
        landingSustainabilityRank: 'Morningstar Sustainability Rating',

        landingFixedIncomeTitle: 'Fixed Income Style',
        landingFixedIncomeHigh: 'High',
        landingFixedIncomeMed: 'Med',
        landingFixedIncomeLow: 'Low',
        landingFixedIncomeHigh2: 'High',
        landingFixedIncomeMed2: 'Med',
        landingFixedIncomeLow2: 'Low',
        landingFixedIncomeRight: 'Credit Quality',
        landingFixedIncomeBottom: 'Interest Rate Sensitivity',

        landingEquityStyleTitle: 'Equity Style',
        landingEquityStyleLarge: 'Large',
        landingEquityStyleMid: 'Mid',
        landingEquityStyleSmall: 'Small',
        landingEquityStyleValue: 'Value',
        landingEquityStyleBlend: 'Blend',
        landingEquityStyleGrowth: 'Growth',
        landingEquityStyleRight: 'Size',
        landingEquityStyleBottom: 'Style',

        tableHeaderFundCode: 'Fund Code',
        tableHeaderFundName: 'Fund Name',
        // tableHeaderFundCategory: 'Fund Category',
        tableHeaderFundCategory: 'Morningstar® Category',
        tableHeaderRiskRating: 'Product Risk',
        tableHeaderMorningstarRating: 'Morningstar Rating™',
        tableHeaderMorningstarSustainability: 'Morningstar Sustainability Rating',
        tableHeaderFundCurrency: 'Fund Currency',
        tableHeaderYTDReturn: 'YTD Return(%)',
        tableHeaderLastFund: 'Last Fund Price',
        tableHeaderYieldM12: '12 Month Yield(%)',
        tableHeaderLastPrice: 'Last Price Date',
        tableHeaderReturnM1: '1 month(%)',
        tableHeaderReturnM3: '3 month(%)',
        tableHeaderReturnM6: '6 month(%)',
        tableHeaderReturnM12: '1 year(%)',
        tableHeaderReturnM36UA: '3 year(%)',
        tableHeaderReturnM60UA: '5 year(%)',
        tableHeaderYRReturnM12_5: '2018(%)',
        tableHeaderYRReturnM12_4: '2019(%)',
        tableHeaderYRReturnM12_3: '2020(%)',
        tableHeaderYRReturnM12_2: '2021(%)',
        tableHeaderYRReturnM12_1: '2022(%)',
        tableHeaderStandardDeviationM12: '1 year Volatility',
        tableHeaderStandardDeviationM36: '3 year Volatility',
        tableHeaderStandardDeviationM60: '5 year Volatility',
        tableHeaderSharpeM12: '1 year Sharpe Ratio',
        tableHeaderSharpeM36: '3 year Sharpe Ratio',
        tableHeaderSharpeM60: '5 year Sharpe Ratio',
        tableHeaderFS: 'Factsheet',
        tableHeaderPR: 'Prospectus',
        tableHeaderKF: 'Key Fact Statement',
        tableHeaderAR: 'Annual Report',
        tableHeaderIR: 'Interim Report',

        tableRowsClose: '(closed for subscription)',

        paginationTips: 'Displaying {start} - {end} of {totalData} results',
        paginationShow: 'show',
        paginationResult: 'results',
        paginationFirst: 'First',
        paginationLast: 'Last',
        paginationPrevious: 'Previous',
        paginationNext: 'Next',

    },
}