import React from 'react';
import styles from './select.module.css'
import arrowIcon from '../../assets/select/arrow.png';
import doneIcon from '../../assets/select/done.png';

const { useState, useMemo } = React;

const Select = ({
    options = [],
    onSelectChange = () => {},
    selectKey = ''
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const selectLabel = useMemo(() => {
        let index = 0;
        for (let i = 0; i < options.length; i++) {
            const item = options[i];
            if (item.id === selectKey) {
                index = i;
                break;
            }
        }

        return options[index].name;
    }, [selectKey, options]);

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const onBlur = () => {
        setIsOpen(false);
    };

    const onSelect = (item) => {
        if (item.id !== selectKey) {
            onSelectChange(item);
        }
    };
 
    return (
        <div 
            className={styles.select} 
            onClick={onClick}
            onBlur={onBlur}
            role="button"
            tabIndex="-1"
        >
            <span className={styles['select-value']} title={selectLabel}>
                {selectLabel}
            </span>
            <img 
                className={styles['select-img']} 
                src={arrowIcon}
                style={{transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)'}}
                alt={selectLabel}
            />
            <div className={styles.list} style={{display: isOpen ? 'block' : 'none'}}>
                {
                    options.map((item) => {
                        const {id, name} = item;
                        const isActive = id === selectKey;
                        return (
                            <div 
                                key={id}
                                className={styles['list-item']}
                                onClick={() => onSelect(item)}
                                style={{color: isActive && '#999999'}}
                            >
                                <span>{name}</span>
                                {
                                    isActive && (<img className={styles['list-item-img']} src={doneIcon} alt={name}/>)
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};

export default Select;