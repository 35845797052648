import { get, round, isNumber, isEmpty, includes } from 'lodash';
import { Decimal } from 'decimal.js';
import { returnFloat } from './details'
import dayjs from 'dayjs';
import cyclicalIcon from '../assets/fundComparison/cyclical.svg';
import cyclical01Icon from '../assets/fundComparison/cyclical-101.svg';
import cyclical02Icon from '../assets/fundComparison/cyclical-102.svg';
import cyclical03Icon from '../assets/fundComparison/cyclical-103.svg';
import cyclical04Icon from '../assets/fundComparison/cyclical-104.svg';
import defensiveIcon from '../assets/fundComparison/defensive.svg';
import defensive05Icon from '../assets/fundComparison/defensive-205.svg';
import defensive06Icon from '../assets/fundComparison/defensive-206.svg';
import defensive07Icon from '../assets/fundComparison/defensive-207.svg';
import sensitiveIcon from '../assets/fundComparison/sensitive.svg';
import sensitive08Icon from '../assets/fundComparison/sensitive-308.svg';
import sensitive09Icon from '../assets/fundComparison/sensitive-309.svg';
import sensitive10Icon from '../assets/fundComparison/sensitive-310.svg';
import sensitive11Icon from '../assets/fundComparison/sensitive-311.svg';

const monthAbbreviation = [
    'Jan', 'Feb', 'Mar', 'Apr', 
    'May', 'Jun', 'Jul', 'Aug', 
    'Sep', 'Oct', 'Nov', 'Dec'
];

const getDateStr = (date) => {
    const baseDate = dayjs(date);

    return `${baseDate.date()} ${monthAbbreviation[baseDate.month()]} ${baseDate.year()}`
};

const getReturnsValue = (data = [], key, isPercent = true) => {
    for (let i = 0; i < data.length; i++) {
        const item = data[i] || {};
        if (item['TimePeriod'] === key) {
            const value = item['Value'];
            return isNumber(value) ? `${returnFloat(round(value, 2))}${isPercent ? '%' : ''}` : '-'
        }
    }
    return '-'
};

const getAssetValue = (data = [], key) => {
    const baseData = data.filter(item => item.SalePosition === 'N');

    if (isEmpty(baseData)) return '-';
    const valueObj = get(baseData, '0.BreakdownValues') || [];

    for (let i = 0; i < valueObj.length; i++) {
        const item = valueObj[i];
        if (item.Type === key) {
            return isNumber(item.Value) ? `${returnFloat(round(item.Value, 2))}%` : '-';
        }
    }

    return '-'
};

const getPortfolioValue = (data = [], ...args) => {
    const baseData = data.filter(item => {
        return includes(args, item.Type) && item.Value > 0;
    });

    if (baseData.length === 0) return '-';

    let total = new Decimal(0);

    baseData.map(item => {
        total = total.plus(new Decimal(item.Value));
    });

    return `${total.toFixed(2, Decimal.ROUND_HALF_DOWN)}%`
};

const getCreditQuality = (data = [], id) => {
    const baseData = data.filter(item => item.SalePosition === 'L');

    if (isEmpty(baseData)) return '-';

    return getPortfolioValue(baseData[0].BreakdownValues, id);
};

export const getKeyInformationData = (baseData) => {
    const baseValue = [
        {
            key: 'Morningstar Rating',
            value: [],
            type: 'rating'
        },
        {
            key: 'Inception Date',
            value: [],
        },
        {
            key: 'Morningstar® Category',
            value: [],
        },
        {
            key: 'Total Assets',
            value: [],
        },
        {
            key: 'Ongoing Charge',
            value: [],
        },
        {
            key: 'Currency',
            value: [],
        },
        {
            key: 'Benchmark',
            value: [],
        },
        {
            key: 'ISIN',
            value: [],
        },
        {
            key: 'Last Close Price',
            value: [],
        },
        {
            key: 'Management Fee',
            value: [],
        },
        {
            key: 'Style',
            value: [],
            type: 'style'
        },
        {
            key: 'Prospectus Objective',
            value: [],
        },
    ]
    baseData.map(data => {
        baseValue[0].value = baseValue[0].value.concat(get(data, 'RiskAndRating.0.RatingValue'));
        baseValue[1].value = baseValue[1].value.concat(getDateStr(get(data, 'InceptionDate')));
        baseValue[2].value = baseValue[2].value.concat(get(data, 'Benchmark.0.Name'));
        baseValue[3].value = baseValue[3].value.concat(get(data, 'FundNetAssetValues.0.MonthEndValue'));
        baseValue[4].value = baseValue[4].value.concat(get(data, 'OngoingCharge'));
        baseValue[5].value = baseValue[5].value.concat(get(data, 'Currency.Id'));
        baseValue[6].value = baseValue[6].value.concat(get(data, 'FundBenchmark.0.Name'));
        baseValue[7].value = baseValue[7].value.concat(get(data, 'Isin'));
        baseValue[8].value = baseValue[8].value.concat(isNumber(get(data, 'PreviousClose')) ? returnFloat(round(get(data, 'PreviousClose'), 2)) : '-');
        baseValue[9].value = baseValue[9].value.concat(['-']);
        baseValue[10].value = baseValue[10].value.concat(get(data, 'Benchmark.0.Portfolios.0.EquityStatistics.StyleBox'));
        baseValue[11].value = baseValue[11].value.concat(get(data, 'InvestmentStrategy'))
    })

    return baseValue
};

export const getPerformanceData = (baseData) => {
    const baseValue = [
        {
            key: 'Performance (as of date)',
            value: [],
        },
        {
            key: 'Calendar Year Returns',
            value: [],
            isBold: true
        },
        {
            key: 'Year to Date',
            value: []
        },
        {
            key: dayjs().year() - 1,
            value: []
        },
        {
            key: dayjs().year() - 2,
            value: []
        },
        {
            key: dayjs().year() - 3,
            value: []
        },
        {
            key: dayjs().year() - 4,
            value: []
        },
        {
            key: dayjs().year() - 5,
            value: []
        },
        {
            key: 'Trailing Performance',
            value: [],
            isBold: true
        },
        {
            key: '1 Month',
            value: []
        },
        {
            key: '3 Month',
            value: []
        },
        {
            key: '6 Month',
            value: []
        },
        {
            key: 'Year to Date',
            value: []
        },
        {
            key: '1 Year (annualized)',
            value: []
        },
        {
            key: '3 Year (annualized)',
            value: []
        },
        {
            key: '5 Year (annualized)',
            value: []
        },
        {
            key: '10 Year (annualized)',
            value: []
        }
    ];

    baseData.map(data => {
        baseValue[0].value = baseValue[0].value.concat(getDateStr(get(data, 'TrailingPerformance.0.Date')));
        baseValue[2].value = baseValue[2].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M0'));
        baseValue[3].value = baseValue[3].value.concat(['-']);
        baseValue[4].value = baseValue[4].value.concat(['-']);
        baseValue[5].value = baseValue[5].value.concat(['-']);
        baseValue[6].value = baseValue[6].value.concat(['-']);
        baseValue[7].value = baseValue[7].value.concat(['-']);
        baseValue[9].value = baseValue[9].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M1'));
        baseValue[10].value = baseValue[10].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M3'));
        baseValue[11].value = baseValue[11].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M6'));
        baseValue[12].value = baseValue[12].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M0'));
        baseValue[13].value = baseValue[13].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M12'));
        baseValue[14].value = baseValue[14].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M36'));
        baseValue[15].value = baseValue[15].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M60'));
        baseValue[16].value = baseValue[16].value.concat(getReturnsValue(get(data, 'TrailingPerformance.0.Return'), 'M120'));
    });

    return baseValue;
};

export const getVolatilityData = (baseData) => {
    const baseValue = [
        {
            key: 'Risk Statistics',
            value: [],
            isBold: true
        },
        {
            key: 'Risk Measurements (as of date)',
            value: []
        },
        {
            key: 'Alpha (3yr)',
            value: []
        },
        {
            key: 'Beta (3yr)',
            value: []
        },
        {
            key: 'Information ratio (3yr)',
            value: []
        },
        {
            key: 'R-Squared (3yr)',
            value: []
        },
        {
            key: 'Sharpe Ratio (3yr)',
            value: []
        },
        {
            key: 'Standard Deviation (3yr)',
            value: []
        },
        {
            key: 'Tracking error (3yr)',
            value: []
        },
        {
            key: 'Modern Portfolio Statistics',
            value: [],
            isBold: true
        }
    ];

    baseData.map(data => {
        baseValue[1].value = baseValue[1].value.concat(getDateStr(get(data, 'RiskStatistics.0.Date')));
        baseValue[2].value = baseValue[2].value.concat(getReturnsValue(get(data, 'RiskStatistics.0.Alphas'), 'M36', false));
        baseValue[3].value = baseValue[3].value.concat(getReturnsValue(get(data, 'RiskStatistics.0.Betas'), 'M36', false));
        baseValue[4].value = baseValue[4].value.concat(getReturnsValue(get(data, 'RiskStatistics.0.InformationRatios'), 'M36', false));
        baseValue[5].value = baseValue[5].value.concat(getReturnsValue(get(data, 'RiskStatistics.0.RSquareds'), 'M36'));
        baseValue[6].value = baseValue[6].value.concat(getReturnsValue(get(data, 'RiskStatistics.0.SharpeRatios'), 'M36', false));
        baseValue[7].value = baseValue[7].value.concat(getReturnsValue(get(data, 'RiskStatistics.0.StandardDeviations'), 'M36'));
        baseValue[8].value = baseValue[8].value.concat(getReturnsValue(get(data, 'RiskStatistics.0.TrackingErrors'), 'M36', false));
    });

    return baseValue;
}

export const getDetailsData = (baseData) => {
    const baseValue = [
        {
            key: 'Management',
            value: [],
            isBold: true
        },
        {
            key: 'Manager Name',
            value: []
        },
        {
            key: 'Company Name',
            value: []
        }
    ];

    baseData.map(data => {
        baseValue[1].value = baseValue[1].value.concat(`${get(data, 'ManagerList.0.GivenName')} ${get(data, 'ManagerList.0.FamilyName')}`);
        baseValue[2].value = baseValue[2].value.concat(get(data, 'BrandingCompanyName'))
    });

    return baseValue;
}

export const getPortfolioData = (baseData) => {
    const baseValue = [
        {
            key: 'Portfolio (as of date)',
            value: []
        },
        {
            key: 'Asset Allocation',
            value: [],
            type: 'allocation'
        },
        {
            key: 'Stocks',
            value: [],
            color: '#1f55a5'
        },
        {
            key: 'Bonds',
            value: [],
            color: '#ef7622'
        },
        {
            key: 'Cash',
            value: [],
            color: '#518428'
        },
        {
            key: 'Other',
            value: [],
            color: '#f5c400'
        },
        {
            key: 'Not Classified',
            value: [],
            color: '#e5e5e5'
        },
        {
            key: 'Geographical Breakdown',
            value: [],
            isBold: true
        },
        {
            key: 'Americas',
            value: []
        },
        {
            key: 'North America',
            value: []
        },
        {
            key: 'Latin America',
            value: []
        },
        {
            key: 'Europe',
            value: []
        },
        {
            key: 'United Kingdom',
            value: []
        },
        {
            key: 'Europe Developed',
            value: []
        },
        {
            key: 'Europe Emerging',
            value: []
        },
        {
            key: 'Africa/Middle East',
            value: []
        },
        {
            key: 'Asia',
            value: []
        },
        {
            key: 'Japan',
            value: []
        },
        {
            key: 'Australasia',
            value: []
        },
        {
            key: 'Asia Developed',
            value: []
        },
        {
            key: 'Asia Emerging',
            value: []
        },
        {
            key: 'Not Classified',
            value: []
        },
        {
            key: 'Stock Sectors',
            value: [],
            isBold: true
        },
        {
            key: 'Cyclical',
            value: [],
            headerIcon: {
                icon: cyclicalIcon,
                iconStyle: {marginLeft: 0, marginRight: 6, backgroundColor: '#9bb83d'},
                width: 16, 
            }
        },
        {
            key: 'Basic Materials',
            value: [],
            icon: cyclical01Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Consumer Cyclical',
            value: [],
            icon: cyclical02Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Financial Services',
            value: [],
            icon: cyclical03Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Real Estate',
            value: [],
            icon: cyclical04Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Sensitive',
            value: [],
            headerIcon: {
                icon: sensitiveIcon,
                iconStyle: {marginLeft: 0, marginRight: 6, backgroundColor: '#8499c4'},
                width: 20
            }
        },
        {
            key: 'Communication Services',
            value: [],
            icon: sensitive08Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Energy',
            value: [],
            icon: sensitive09Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Industrials',
            value: [],
            icon: sensitive10Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Technology',
            value: [],
            icon: sensitive11Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Defensive',
            value: [],
            headerIcon: {
                icon: defensiveIcon,
                iconStyle: {marginLeft: 0, marginRight: 6, backgroundColor: '#d17d20'},
                width: 12,
            }
        },
        {
            key: 'Consumer Defensive',
            value: [],
            icon: defensive05Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Healthcare',
            value: [],
            icon: defensive06Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Utilities',
            value: [],
            icon: defensive07Icon,
            iconStyle: {marginLeft: 12, marginRight: 6}
        },
        {
            key: 'Credit Quality Breakdown',
            value: [],
            isBold: true
        },
        {
            key: 'AAA',
            value: []
        },
        {
            key: 'AA',
            value: []
        },
        {
            key: 'A',
            value: []
        },
        {
            key: 'BBB',
            value: []
        },
        {
            key: 'BB',
            value: []
        },
        {
            key: 'B',
            value: []
        },
        {
            key: 'Below B',
            value: []
        },
        {
            key: 'NR',
            value: []
        },
        {
            key: 'Fixed Income Maturity Breakdown',
            value: [],
            isBold: true
        },
        {
            key: '1 to 3 Years',
            value: []
        },
        {
            key: '3 to 5 Years',
            value: []
        },
        {
            key: '5 to 7 Years',
            value: []
        },
        {
            key: '7 to 10 Years',
            value: []
        },
        {
            key: '10 to 15 Years',
            value: []
        },
        {
            key: '15 to 20 Years',
            value: []
        },
        {
            key: '20 to 30 Years',
            value: []
        },
        {
            key: '>30 Years',
            value: []
        }
    ]

    baseData.map(data => {
        baseValue[0].value = baseValue[0].value.concat(getDateStr(get(data, 'Portfolios.0.Date')));
        baseValue[1].value = baseValue[1].value.concat([get(data, 'Portfolios.0.AssetAllocations')]);
        baseValue[2].value = baseValue[2].value.concat(getAssetValue(get(data, 'Portfolios.0.AssetAllocations'), '1'));
        baseValue[3].value = baseValue[3].value.concat(getAssetValue(get(data, 'Portfolios.0.AssetAllocations'), '2'));
        baseValue[4].value = baseValue[4].value.concat(getAssetValue(get(data, 'Portfolios.0.AssetAllocations'), '3'));
        baseValue[5].value = baseValue[5].value.concat(getAssetValue(get(data, 'Portfolios.0.AssetAllocations'), '4'));
        baseValue[6].value = baseValue[6].value.concat(getAssetValue(get(data, 'Portfolios.0.AssetAllocations'), '99'));

        baseValue[8].value = baseValue[8].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '1', '2', '3'));
        baseValue[9].value = baseValue[9].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '1', '2'));
        baseValue[10].value = baseValue[10].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '3'));
        baseValue[11].value = baseValue[11].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '4', '5', '6', '7', '8', '9'));
        baseValue[12].value = baseValue[12].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '4'));
        baseValue[13].value = baseValue[13].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '5', '6'));
        baseValue[14].value = baseValue[14].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '7'));
        baseValue[15].value = baseValue[15].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '8', '9'));
        baseValue[16].value = baseValue[16].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '10', '11', '12', '13'));
        baseValue[17].value = baseValue[17].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '10'));
        baseValue[18].value = baseValue[18].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '11'));
        baseValue[19].value = baseValue[19].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '12'));
        baseValue[20].value = baseValue[20].value.concat(getPortfolioValue(get(data, 'Portfolios.0.RegionalExposure.0.BreakdownValues'), '13'));
        baseValue[21].value = baseValue[21].value.concat('-');

        baseValue[23].value = baseValue[23].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '101', '102', '103', '104'));
        baseValue[24].value = baseValue[24].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '101'));
        baseValue[25].value = baseValue[25].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '102'));
        baseValue[26].value = baseValue[26].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '103'));
        baseValue[27].value = baseValue[27].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '104'));
        baseValue[28].value = baseValue[28].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '308', '309', '310', '311'));
        baseValue[29].value = baseValue[29].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '308'));
        baseValue[30].value = baseValue[30].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '309'));
        baseValue[31].value = baseValue[31].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '310'));
        baseValue[32].value = baseValue[32].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '311'));

        baseValue[33].value = baseValue[33].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '205', '206', '207'));
        baseValue[34].value = baseValue[34].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '205'));
        baseValue[35].value = baseValue[35].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '206'));
        baseValue[36].value = baseValue[36].value.concat(getPortfolioValue(get(data, 'Portfolios.0.GlobalStockSectorBreakdown.0.BreakdownValues'), '207'));

        baseValue[38].value = baseValue[38].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '2'));
        baseValue[39].value = baseValue[39].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '3'));
        baseValue[40].value = baseValue[40].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '4'));
        baseValue[41].value = baseValue[41].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '5'));
        baseValue[42].value = baseValue[42].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '6'));
        baseValue[43].value = baseValue[43].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '7'));
        baseValue[44].value = baseValue[44].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '8'));
        baseValue[45].value = baseValue[45].value.concat(getCreditQuality(get(data, 'Portfolios.0.CreditQualityBreakdown'), '9'));

        baseValue[47].value = baseValue[47].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '1'));
        baseValue[48].value = baseValue[48].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '2'));
        baseValue[49].value = baseValue[49].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '3'));
        baseValue[50].value = baseValue[50].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '4'));
        baseValue[51].value = baseValue[51].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '5'));
        baseValue[52].value = baseValue[52].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '6'));
        baseValue[53].value = baseValue[53].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '7'));
        baseValue[54].value = baseValue[54].value.concat(getCreditQuality(get(data, 'Portfolios.0.MaturityRange'), '8'));

    })

    return baseValue
}


export const getAssetChartData = (data) => {
    const values = data.filter((item) => item.SalePosition === 'N');

    if (isEmpty(values)) return {}

    let obj = {};
    const arr = values[0].BreakdownValues.map(item => item.Type);

    arr.map((key, index) => {
        const value = (values[0].BreakdownValues || {})[index]['Value'];
        obj[key] = isNumber(value) ? returnFloat(round(value, 2)) : '-';
    })

    return obj
}

export const getAssetBarChartData = (data = [], salePosition = 'S') => {
    const values = data.filter((item) => item.SalePosition === salePosition);

    if (isEmpty(values)) return {}

    let obj = {};
    const arr = values[0].BreakdownValues.map(item => item.Type);

    arr.map((key, index) => {
        const value = (values[0].BreakdownValues || {})[index]['Value'];
        obj[key] = isNumber(value) ? value : 0;
    })
    return obj;
}
